import instance from "../utils/axios";

const dashboard = async () => {
    return await instance.get("admin/dashboard");
}
const allAdmin = async () => {
    return await instance.get("admin/all-admin");
}
const allUsersSubscribedNewsletter = async () => {
    return await instance.get("admin/users-subscribed-newsletter");
}
const getOneAdmin = async (id) => {
    return await instance.get(`admin/one-admin?id=${id}`);
}

const registerAdmin = async (body) => {
    return await instance.post(`admin/register`, body);
}
const updateAdmin = async (body) => {
    return await instance.post(`admin/update`, body);
}
const deleteAdmin = async (id) => {
    return await instance.delete(`admin/delete?id=${id}`);
}
const logout = () => {
    return instance.get("admin/logout", {
        // withCredentials: true,
    });
};

const AdminService = {
    dashboard,
    allAdmin,
    getOneAdmin,
    registerAdmin,
    updateAdmin,
    logout,
    allUsersSubscribedNewsletter,
    deleteAdmin
};

export default AdminService;