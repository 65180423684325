import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button
} from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import EditModal from '../radio/EditEventModal';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


const AppointmentEvent = ({ appointment, radioShows, modifiedData, setRadioProgramsFromServer, radioProgramsFromServer }) => {
    const { title, start, end, radio_show_id, description } = appointment;
    const [isOpen, setIsOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setIsOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const findRadioShow = (id) => {
        const radioShow = radioShows.find((radioShow) => Number(radioShow.id) === Number(id));
        return radioShow ? radioShow.name : "No show";
    }
    const descriptionIsEmpy = description === ' ' ? null : description;

    return (
        <>
            <button style={{ width: "100%", height: "100%" }} onClick={(e) => handleClick(e)}>
                <Box
                    sx={{
                        backgroundColor: "#3f51b5",
                        borderRadius: "8px",
                        borderTop: "1px  solid white",
                        padding: "",
                        color: "white",
                        fontWeight: "bold",
                        height: "100%",
                        width: "100%",


                    }}
                >
                    {/* <div className='absolute right-0 -top-1'>
                        <Button onClick={handleClick}><RemoveRedEyeIcon sx={{ color: "white" }} /></Button>
                    </div> */}

                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: -2, fontSize: 14, backgroundColor: "#4A4A4A" }}>
                        <AccessTime fontSize='5' sx={{ mr: 0.2 }} />
                        <span className='font-semi-bold' >
                            {`${start.getHours().toString().padStart(2, '0')}:${start.getMinutes().toString().padStart(2, '0')}- ${end.getHours().toString().padStart(2, '0')}:${end.getMinutes().toString().padStart(2, '0')}`}
                        </span>

                        <span className='font-bold ml-1' >{title}</span>
                    </Typography>
                    <hr />
                    {descriptionIsEmpy && (
                        <Typography variant="body2"><span className='font-bold'>Description : </span>{description}</Typography>
                    )}
                </Box>
            </button>
            <EditModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                appointment={appointment}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                radioShows={radioShows}
                radioProgramsFromServer={radioProgramsFromServer}
                modifiedData={modifiedData}
                setRadioProgramsFromServer={setRadioProgramsFromServer}
            />
        </>
    );
};

export default AppointmentEvent;
