import React, { useEffect, useState } from "react";
import Menu from "../../components/layout/Menu";
import {
    Alert,
    Box,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Switch,
} from "@mui/material";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";
import instance from "../../utils/axios";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import articlesService from "../../services/articles.service";
import { PublishOutlined } from "@mui/icons-material";
import "dayjs/locale/fr";
import CoverImageSelector from "./components/ConverImageSelector";
import CustomInlineComponent from "./components/CustomInlineComponent";
import { Grid, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import { DatePicker } from "./components/DatePicker";
import { ModalMedia } from "./components/ModalMedia";
import SelectComponent from "./components/SelectComponent";
import SelectMultipleComponent from "./components/MultiselectComponent";
import { useNavigate } from 'react-router-dom';
import BriefForm from "./components/BriefCreator";
import FreeSoloCreateOptionDialog from "../medias/components/AutoCompleteCreateTag";
import { AddImageModal } from "./components/AddImageModal";
import NumberInput from "./components/NumberInput";
import AlertDialog from "../../components/Alert";
import ModalAddVideoYoutubeArticle from "./youtubeComponents/ModalAddVideoYoutbe";
import AddVideoButton from "../../components/buttonAddVideo/AddVideoButton";
import podcastService from "../../services/podcast.services";
import PodcastSelect from "./podcastComponents/PodcastSelect";
import tinymce from 'tinymce';
import mediaService from "../../services/media.service";
import { IMAGE_URL_WYSIWYG } from "../../constants/url";
import { useTheme, useMediaQuery } from "@mui/material";
import compressImageBlob from "../../utils/compressBlobImage";
import { toast } from "react-toastify";


export default function ArticlesCreate() {
    const [infoCreate, setInfoCreate] = useState({
        authors: [],
        city: [],
        tags: [],
        media: [],
        themes: [],
    });
    const [podcasts, setPodcasts] = useState([]);
    const [tagsCreate, setTagCreate] = useState([]);
    const [tagsId, setTagsId] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [value, setValue] = useState([]);
    const [openCroppImage, setOpenCroppImage] = useState(false);

    const [wysiwygAddImageModal, setWygAddImageModal] = useState("");
    const [openConfirmPublication, setOpenConfirmPublication] = useState(false);
    const [CancelConfirmPublication, setCancelConfirmPublication] = useState(false);
    const [publicationFailed, setPublicationFailed] = useState(false);

    ///VIDEO YOTUBE
    const [openModalYoutube, setOpenModalYoutube] = useState(false);
    const handleOpenModalYoutube = () => setOpenModalYoutube(true);
    const handleCloseModalYoutube = () => setOpenModalYoutube(false);
    const [listVideoMediaInArticle, setListVideoMediaInArticle] = useState([]);
    const checkVideoInArticle = listVideoMediaInArticle && listVideoMediaInArticle.length > 0;

    // Responsive 

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // 

    const handleCloseConfirmPublication = () => {

        setOpenConfirmPublication(false)
    };
    const caculateWidth = Math.max(370 - (1920 - windowWidth) * 0.3, 0);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const navigate = useNavigate();
    const [wysiwyg, setWysiwyg] = useState({
        media: [],
    });
    const [dataArticle, setDataArticle] = useState({
        title: "",
        description: "",
        body: "",
        publication_date: Math.round(new Date().getTime() / 1000),
        is_validate: false,
        is_active: false,
        in_spotlight: false,
        special_edition: false,
        podcast_id: "",
        city_id: "",
        author_id: [],
        theme_id: [],
        tag_id: [],
        media_id: [],
        briefs: [],
        notification: false,
        time_to_read: 0,

    });
    const handleOpenConfirmPublication = () => {
        //check if dataArticles all its not empty
        const isValid = validateForm();

        if (
            isValid
        ) {
            setError(false)
            setPublicationFailed(false);
            setOpenConfirmPublication(true);
        } else {

            setPublicationFailed(true);
            setError(true)
        }

    }
    useEffect(() => {

        if (publicationFailed) {
            validateForm();
        }
    }, [dataArticle]);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const openMediaPicker = () => {
        setOpen(true);
    };
    const [briefs, setBriefs] = useState([
    ]);


    const closeMediaPicker = () => {
        setOpen(false);
    };
    const handleOpenCroppImage = () => {
        setOpenCroppImage(true)
    };
    const handleCloseCroppImage = ({ openMediapicker = false }) => {
        if (openMediapicker) {
            setOpenCroppImage(false)
            openMediaPicker();
        } else {
            setOpenCroppImage(false)
        }
    };
    const handleClosewysiwygAddImageModal = () => {
        setWygAddImageModal(false);
    }

    useEffect(() => {
        instance.get("articles/info-create").then((response) => {
            if (response.data) {
                setInfoCreate({
                    authors: response.data.authors,
                    city: response.data.city,
                    tags: response.data.tags,
                    media: response.data.media,
                    themes: response.data.themes,
                });
                setTagCreate(response.data.tags);
                //Selected author if i am this admin and authors
                if (response.data.authors && Array.isArray(response.data.authors) && response.data.authors.length > 0) {
                    let authorId;
                    const arrayAuthor = response.data.authors;
                    arrayAuthor.forEach((author) => {
                        if (author.is_admin) {
                            authorId = author.id;
                        }
                    })
                    setDataArticle({
                        ...dataArticle,
                        author_id: authorId ? [authorId] : [],
                    });
                }
            }
        })
            .catch((error) => console.log(error));

        podcastService.getAllPodcasts()
            .then((res) => {
                if (res.status === 200) {
                    setPodcasts(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 38,
        height: 20,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#03A9F4',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 17,
            height: 16,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#39393D' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
    const handleChangeSwitch = (event, propertyName) => {
        const newValue = event.target.checked;
        setDataArticle({ ...dataArticle, [propertyName]: newValue });
        if (propertyName === "special_edition") {
            setDataArticle({ ...dataArticle, briefs: [], [propertyName]: newValue, notification: false });
            setBriefs([]);
        } else {
            setDataArticle({ ...dataArticle, [propertyName]: newValue });
        }
    };
    const loadData = () => {
        instance.get("articles/info-create")
            .then((res) => {
                if (res && res.status === 200 && res.data && res.data.tags && res.data.themes) {
                    setTagCreate(res.data.tags);
                } else {
                    setError('Erreur récupération info auteur, theme et tag')
                }
            })
            .catch(() => {
                setError('Erreur récupération info auteur, theme et tag')
            })
    }

    const handleSelectTags = (newValue) => {
        let arrayId = [];
        if (newValue && Array.isArray(newValue) && newValue.length > 0) {
            for (const newValueElement of newValue) {
                if (newValueElement) {
                    arrayId.push(newValueElement)
                }
            }
            setTagsId(arrayId)
            setDataArticle({ ...dataArticle, tag_id: arrayId });
        } else {
            setTagsId([]);
            setDataArticle({ ...dataArticle, tag_id: [] });
        }
    }

    const handleClickSpecialEdition = (e) => {
        handleChangeSwitch(e, "special_edition");
        setBriefs([]);
    }

    const isValidated = (isValidated, validateDate, is_active) => {
        if (isValidated === false && is_active === false) {
            return "Brouillon"
        } else if (is_active === true && isValidated === true) {
            return "Publié"
        } else if (isValidated === true && validateDate == null) {
            return "A valider"
        }
        else {
            return "Inconnu"
        }
    }
    const handleChangeGeneric = (event, propertyName) => {
        const newValue = event.target.value;
        setDataArticle({ ...dataArticle, [propertyName]: newValue });
    };

    // create article
    const dataFormError = {
        title: "",
        description: "",
        body: "",
        city_id: "",
        author_id: "",
        theme_id: "",
        tag_id: "",
        media_id: "",
        time_to_read: "",
    };

    const [formErrors, setFormErrors] = useState(dataFormError);
    const validateForm = () => {
        let isValid = true;
        const newFormErrors = {};

        if (dataArticle.title === "") {
            newFormErrors.title = "Le titre est requis.";
            isValid = false;
        }

        if (dataArticle.description.trim() === "") {
            newFormErrors.description = "La description est requise.";
            isValid = false;
        }

        if (dataArticle.body.trim() === "") {
            newFormErrors.body = "Le contenu de l'article est requis.";
            isValid = false;
        }

        if (dataArticle.city_id === "") {
            newFormErrors.city_id = "La ville est requise.";
            isValid = false;
        }

        if (dataArticle.author_id.length === 0) {
            newFormErrors.author_id = "L'auteur est requis.";
            isValid = false;
        }

        if (dataArticle.theme_id.length === 0) {
            newFormErrors.theme_id = "Le thème est requis.";
            isValid = false;
        }

        if (dataArticle.tag_id.length === 0) {
            newFormErrors.tag_id = "Le tag est requis.";
            isValid = false;
        }

        if (dataArticle.media_id.length === 0) {
            newFormErrors.media_id = "Le media est requis.";
            isValid = false;
        }

        if (dataArticle.time_to_read <= 0) {
            newFormErrors.time_to_read = "Le temps de lecture doit être supérieur à zéro.";
            isValid = false;
        }

        setFormErrors(newFormErrors);
        setError(!isValid);
        return isValid;
    };
    const createArticle = ({ toValidate = false, toDraft = false }) => {
        setLoaded(true);
        let dataFinal = {
            ...dataArticle, is_validate: true, is_active: true
        }
        if (toValidate) {
            dataFinal = {
                ...dataArticle, is_validate: true, is_active: false
            }
        }
        if (toDraft) {
            dataFinal = {
                ...dataArticle, is_validate: false, is_active: false
            }
        }
        const isValid = validateForm();
        if (isValid) {

            setPublicationFailed(false);
            articlesService
                .createArticle(dataFinal)
                .then((response) => {
                    if (response.data) {
                        setPublicationFailed(false);
                        setSuccess(true);
                        setLoaded(false);
                        setError(false);
                        setDataArticle({
                            title: "",
                            description: "",
                            body: "",
                            publication_date: Math.round(new Date().getTime() / 1000),
                            is_validate: false,
                            is_active: false,
                            in_spotlight: false,
                            special_edition: false,
                            podcast_id: "",
                            city_id: "",
                            author_id: [],
                            theme_id: [],
                            tag_id: [],
                            media_id: [],
                            briefs: [],
                        });
                        navigate('/articles')
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoaded(false);
                    setError(true);
                });

        } else {
            setPublicationFailed(true);
            setLoaded(false);
            setError(true);
        }
    };

    const handleCancelArticle = () => {
        navigate('/articles')
    }

    const breadcrumbArray = [
        { name: "Articles", to: "/articles" },
        { name: "Créer un article", to: "/articles/creates" },

    ];
    return (
        <Menu category="Articles" breadCrumb={breadcrumbArray} >
            <Grid container spacing={5} className={isSmallScreen ? "m-0 pl-3" : "m-4"} sx={{ display: isSmallScreen ? "block" : "flex", width: "100%" }}>
                <Grid sx={{ flexDirection: "column" }} container xs={isSmallScreen ? 7.9 : 7.8}>
                    <TextField
                        className={"mb-3"}
                        id="title"
                        label="Titre"
                        variant="outlined"
                        error={!!formErrors.title}
                        helperText={formErrors.title}
                        onChange={(e) => {
                            setDataArticle({ ...dataArticle, title: e.target.value })
                        }
                        }
                    />
                    <TextField
                        className={"mb-2"}
                        id="description"
                        label="Texte descriptif / Chapeau"
                        multiline
                        error={!!formErrors.description}
                        helperText={formErrors.description}
                        onChange={(e) => {
                            setDataArticle({ ...dataArticle, description: e.target.value })
                        }
                        }
                        rows={3}
                    />
                    {/* make span error  for the editor */}

                    {!!formErrors.body && <span style={{ color: "#d32f2f" }}>{formErrors.body}</span>}
                    <Editor
                        tinymceScriptSrc="./tinymce/tinymce.min.js"
                        onChange={(e) => {
                            setDataArticle({ ...dataArticle, body: e.target.getContent() });
                        }}
                        // onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                            font_formats:
                                "Raleway=raleway;",
                            height: 500,
                            menubar: true,
                            language: "fr_FR",
                            promotion: false,
                            plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "preview",
                                "help",
                                "wordcount",
                            ],
                            file_picker_callback: (cb, value, meta) => {
                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.setAttribute('accept', 'image/*');
                                input.addEventListener('change', (e) => {
                                    const file = e.target.files[0];
                                    const maxSizeAfterCompression = 5 * 1024 * 1024; // 5 MB en bytes
                                    compressImageBlob(file).then((file) => {

                                        if (file.size > maxSizeAfterCompression) {
                                            toast.error("Votre image est trop lourde meme après compression", {
                                                position: "top-right",
                                                theme: "dark",
                                            });
                                            return;
                                        }

                                        mediaService.uploadMedia(file)
                                            .then((response) => {
                                                const reader = new FileReader();
                                                reader.addEventListener('load', () => {
                                                    const id = 'blobid' + (new Date()).getTime();
                                                    const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                                    const base64 = reader.result.split(',')[1];
                                                    const blobInfo = blobCache.create(id, file, base64);
                                                    blobCache.add(blobInfo);
                                                    cb(IMAGE_URL_WYSIWYG + response.data.url, { title: file.name });
                                                });
                                                reader.readAsDataURL(file);
                                            });
                                    }
                                    );
                                });
                                input.click();
                            },
                            toolbar:
                                "undo redo | bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags",

                            content_style: "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap'); body { font-family: Raleway; font-variant-numeric: lining-nums; }; ",
                        }}
                    />
                    {dataArticle.special_edition && (
                        <BriefForm
                            briefs={briefs}
                            setBriefs={setBriefs}
                            setDataArticle={setDataArticle}
                            dataArticle={dataArticle}
                            handleChangeSwitch={handleChangeSwitch} />
                    )}
                </Grid>
                <Grid container xs={8.8} md={4}>
                    <div style={
                        {
                            marginTop: isSmallScreen ? "14px" : "0px",
                            marginLeft: isSmallScreen ? "" : "25px",
                            width: "88%",
                        }
                    } >
                        <CustomInlineComponent
                            textLeft={"Statut"}
                            textRight={isValidated(dataArticle.is_validate, dataArticle.validate_date, dataArticle.is_active)}
                        />

                        <AddVideoButton handleOpenVideo={handleOpenModalYoutube} text={checkVideoInArticle ? "Modifier la video" : "Ajouter une video"} />
                        {!!formErrors.media_id && <span style={{ color: "#d32f2f" }}>{formErrors.media_id}</span>}
                        <CoverImageSelector
                            dataArticle={dataArticle}
                            setDataArticle={setDataArticle}
                            infoCreate={infoCreate}
                            setInfoCreate={setInfoCreate}
                            openMediaPicker={openMediaPicker}
                            setOpen={setOpen}
                            handleOpenCroppImage={handleOpenCroppImage}
                        />

                        <div className='mt-10'>
                            <Paper variant="outlined" style={{
                                boxShadow: "none",
                                padding: '8px',
                                paddingLeft: '15px',
                                height: '21rem',
                                overflow: isSmallScreen ? "none" : "auto",
                                overflowY: isSmallScreen ? "none" : "scroll",

                            }}>
                                <Box sx={{ marginTop: 1 }}>
                                    <FormGroup>

                                        <FormControlLabel setDataArticle={setDataArticle} control={<IOSSwitch checked={dataArticle.special_edition}
                                            onChange={((e) => {
                                                handleClickSpecialEdition(e);
                                            })}
                                            sx={{ marginRight: 5, marginLeft: 1 }} />} label="Edition spéciale" />
                                        {/* <FormControlLabel setDataArticle={setDataArticle} control={<IOSSwitch checked={dataArticle.is_active} onChange={((e) => handleChangeSwitch(e, "is_active"))} sx={{ marginRight: 5, marginLeft: 1 }} />} label="Afficher" /> */}
                                        {/* afficher en commentaire car peut etre il faut le remetre dans le futur */}
                                        <FormControlLabel control={<IOSSwitch checked={dataArticle.in_spotlight} onChange={((e) => handleChangeSwitch(e, "in_spotlight"))} sx={{ marginRight: 5, marginLeft: 1 }} />} label="A la Une" />
                                        <NumberInput label="Temps de lecture" value={dataArticle.time_to_read} onChange={setDataArticle} dataArticle={dataArticle} />
                                        {!!formErrors.time_to_read && <span style={{ color: "#d32f2f" }}>{formErrors.time_to_read}</span>}

                                        <SelectComponent
                                            dataArticle={dataArticle}
                                            mLeftSelect={isSmallScreen ? 0 : 2}
                                            setDataArticle={setDataArticle} propertyName={"author_id"}
                                            isAuteur={true} options={infoCreate.authors}
                                            label="Auteur" />
                                        {!!formErrors.author_id && <span style={{ color: "#d32f2f" }}>{formErrors.author_id}</span>}

                                        <SelectMultipleComponent
                                            dataArticle={dataArticle}
                                            setDataArticle={setDataArticle}
                                            propertyName="theme_id"
                                            options={infoCreate.themes}
                                            label="Thème"
                                        />
                                        {!!formErrors.theme_id && <span style={{ color: "#d32f2f" }}>{formErrors.theme_id}</span>}
                                        <SelectComponent dataArticle={dataArticle} setDataArticle={setDataArticle} propertyName={"city_id"} mLeftSelect={4} options={infoCreate.city} label="Ville" />
                                        {!!formErrors.city_id && <span style={{ color: "#d32f2f" }}>{formErrors.city_id}</span>}
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 4, paddingLeft: 0, paddingBottom: 0, paddingTop: 12 }}>
                                            <label style={{ minWidth: "70px", fontWeight: "bold" }} htmlFor="number-input-height">Tags</label>
                                            <div style={{ width: isSmallScreen ? "180px" : `${caculateWidth}px`, maxWidth: '100%' }}>
                                                <FreeSoloCreateOptionDialog
                                                    listTag={tagsCreate}
                                                    getInputValue={handleSelectTags}
                                                    reloadPage={loadData}
                                                    value={value}
                                                    setValue={setValue}
                                                    setDataArticle={setDataArticle}
                                                    setTagCreate={setTagCreate}
                                                    dataArticle={dataArticle}
                                                />
                                            </div>
                                        </div>
                                        {!!formErrors.tag_id && <span style={{ color: "#d32f2f" }}>{formErrors.tag_id}</span>}
                                        {/* <SelectComponent dataArticle={dataArticle} setDataArticle={setDataArticle} propertyName={"tag_id"} mLeftSelect={5} options={infoCreate.tags} label="Tag" /> */}
                                    </FormGroup>

                                </Box>
                            </Paper>

                        </div>
                        <Paper variant="outlined" style={{
                            boxShadow: "none",
                            padding: '8px',
                            paddingLeft: '15px',
                            // textAlign: 'center',
                            height: '6.3rem',
                            overflow: "hidden",
                            width: "100%",

                            marginTop: "7px"
                        }}>
                            <InputLabel sx={
                                {
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    marginBottom: "6px"

                                }
                            } >
                                Lié un podcast à l’article
                            </InputLabel>
                            <PodcastSelect
                                podcasts={podcasts}
                                selectedPodcastId={dataArticle.podcast_id}
                                handleChangeGeneric={handleChangeGeneric}
                            />
                        </Paper>
                        <Paper variant="outlined" style={{
                            boxShadow: "none",
                            padding: '8px',
                            paddingLeft: '15px',
                            height: isSmallScreen ? "17rem" : '12.5rem',
                            overflow: "hidden",
                            marginBottom: isSmallScreen ? "25px" : "5px",

                            marginTop: "7px"
                        }}>
                            <DatePicker
                                setDataArticle={setDataArticle}

                                dataArticle={dataArticle}
                            />

                            <div className='mt-3'>

                                <div className="mb-2 flex justify-content-end">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginRight: "12px",
                                            backgroundColor: "#ee8b00",
                                            color: "white",
                                            paddingBottom: "6px",


                                        }}
                                        disabled={loaded ? true : false}
                                        onClick={() => handleOpenConfirmPublication()}
                                    >
                                        {loaded ? (
                                            <CircularProgress color="inherit" />
                                        ) : (
                                            <>
                                                <span className="normal-case">Publier Sans validation</span> <PublishOutlined />
                                            </>
                                        )}
                                    </Button>
                                    <Button
                                        color="success"
                                        variant="contained"
                                        sx={{
                                            // backgroundColor: "#ee8b00",
                                            color: "white",
                                            paddingBottom: "6px",
                                        }}
                                        disabled={loaded ? true : false}
                                        onClick={() => createArticle({ toValidate: true })}
                                    >
                                        <span className="normal-case">A valider</span>
                                    </Button>
                                </div>
                                <div className="flex justify-content-end">
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        sx={{
                                            marginRight: "12px",
                                            // backgroundColor: "#ee8b00",
                                            color: "white",
                                            paddingBottom: "6px",
                                        }}
                                        disabled={loaded ? true : false}
                                        onClick={() => createArticle({ toDraft: true })}
                                    >

                                        <span className="normal-case">Brouillon</span>
                                    </Button>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        sx={{
                                            marginRight: "1px",
                                            // backgroundColor: "#ee8b00",
                                            color: "white",
                                            paddingBottom: "6px",
                                            paddingLeft: "20px",
                                        }}
                                        disabled={loaded ? true : false}
                                        onClick={() => setCancelConfirmPublication(true)}
                                    >

                                        <span className="normal-case">Annuler</span>
                                    </Button>


                                </div>

                            </div>

                        </Paper>
                        {open && (
                            <ModalMedia
                                open={open}
                                handleClose={closeMediaPicker}
                                handleSave={closeMediaPicker}
                                setDataArticle={setDataArticle}
                                dataArticle={dataArticle}
                                infoCreate={infoCreate}
                                setInfoCreate={setInfoCreate}

                            />
                        )}
                        {openCroppImage && (
                            <AddImageModal
                                open={openCroppImage}
                                handleCloseCroppImage={handleCloseCroppImage}
                                setInfoCreate={setInfoCreate}
                                infoCreate={infoCreate}
                                dataArticle={dataArticle}
                                setDataArticle={setDataArticle}
                                isCreate={true}
                            />
                        )
                        }
                        {wysiwygAddImageModal && (
                            <AddImageModal
                                open={wysiwygAddImageModal}
                                handleCloseCroppImage={handleClosewysiwygAddImageModal}
                                setInfoCreate={setInfoCreate}
                                infoCreate={infoCreate}
                                dataArticle={wysiwyg}
                                setDataArticle={setWysiwyg}
                                isCreate={true}
                            />
                        )
                        }
                        <AlertDialog
                            open={openConfirmPublication}
                            handleClose={handleCloseConfirmPublication}
                            handleAgree={() => createArticle({ toValidate: false })}
                            title={"Attention"}
                            label={"Etes vous sur de vouloir publier sans validation ? "}
                            buttonAccept={"Publier"}
                            buttonCancel={"Annuler"}
                        />
                        {/* Cancel confirm */}
                        <AlertDialog
                            open={CancelConfirmPublication}
                            handleClose={() => setCancelConfirmPublication(false)}
                            handleAgree={handleCancelArticle}
                            title={"Attention"}
                            label={"Voulez vous vraiment annuler ? Toutes vos modifications seront perdues"}
                            buttonAccept={"Oui"}
                            buttonCancel={"Non"}
                        />
                        <ModalAddVideoYoutubeArticle
                            open={openModalYoutube}
                            onClose={handleCloseModalYoutube}
                            onConfirm={handleCloseModalYoutube}
                            dataArticle={dataArticle}
                            setDataArticle={setDataArticle}
                            listVideoMediaInArticle={listVideoMediaInArticle}
                            setListVideoMediaInArticle={setListVideoMediaInArticle}
                        />
                    </div>
                </Grid>
            </Grid>


            {error ? (
                <Alert sx={{ marginBottom: 1, marginTop: 3, position: "absolute", top: 27, right: 750 }} severity="error">
                    Tous les champs sont obligatoires
                </Alert>
            ) : null}
            {success ? (
                <Alert sx={{ marginBottom: 1, marginTop: 3, position: "absolute", top: 27, right: 750 }} severity="success">
                    Article créé avec succès
                </Alert>
            ) : null}

        </Menu>
    );
}
