import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import UploadVideoForm from './ListPodcastTV/YoutubeApi';

const ModalAddVideoYoutube = ({ open, onClose, onConfirm, dataArticle, setDataArticle }) => {

    return (
        <Modal
            open={open}
            // onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    height: 650,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center', // Centrar el contenido
                }}
            >

                <UploadVideoForm onClose={onClose} setDataArticle={setDataArticle} dataArticle={dataArticle} />

            </Box>
        </Modal>
    );
};

export default ModalAddVideoYoutube;
