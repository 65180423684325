import React from 'react';
import VideoList from './VideoList';
import { Typography } from '@mui/material';

const VideoViewer = ({ video, setVideo, setDataArticle, setListVideoMediaInArticle, dataArticle, onClose, setIsVideoInArticle }) => {


    const handleDelete = (id) => {
        const datacleaned = dataArticle.media_id.filter(video => video !== id)
        setListVideoMediaInArticle(null)
        setDataArticle({ ...dataArticle, media_id: datacleaned })
        setIsVideoInArticle(false)
    }


    return (
        <div>
            <Typography variant="h3">Aperçu de la vidéo</Typography>
            <VideoList onClose={onClose} videos={video} onDelete={handleDelete} />

        </div>
    );
};

export default VideoViewer;
