import instance from "../utils/axios";

class youtube {
    constructor() {
        this.instance = instance;
    }

    createMediaWithUrlId = async (data) => {
        return await instance.post(`media/upload-url`, data, {
            withCredentials: true,
        });
    }

}

var youtubeService = new youtube();
export default youtubeService;
