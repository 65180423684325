import React, { useState } from 'react';
import YouTube from 'react-youtube';
import Paper from "@mui/material/Paper";
import AlertDialog from "../../../components/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const VideoItem = ({ video, onDelete, onClose }) => {
    const [confirmPublication, setConfirmPublication] = useState(false);
    const { id, url } = video;
    const [isLoading, setIsLoading] = useState(true);

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
        },
    };

    const handleDelete = () => {
        onDelete(id);
    };

    const handleOnReady = () => {

        setIsLoading(false);
    };

    return (
        <div>
            {isLoading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />}
            <Paper variant="outlined" style={{
                boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
                height: '30rem',
                overflow: "hidden",
                width: "100%",
                marginTop: "7px"
            }}>
                <YouTube
                    className='ml-12 mt-5'
                    videoId={url}
                    opts={opts}
                    onReady={handleOnReady}
                />
            </Paper>

            <div className="ml-80">
                <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="error"
                    onClick={() => setConfirmPublication(true)}
                >
                    Supprimer la vidéo de l'article
                </Button>
                <Button
                    sx={{ mt: 2, ml: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </div>

            <AlertDialog
                open={confirmPublication}
                handleClose={() => setConfirmPublication(false)}
                handleAgree={handleDelete}
                title={"Attention"}
                label={"Voulez-vous vraiment supprimer cette vidéo ?"}
                buttonAccept={"Oui"}
                buttonCancel={"Non"}
            />

        </div>
    );
};

export default VideoItem;
