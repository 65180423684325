import Compressor from 'compressorjs';


const compressImageBlob = (blobParam) => {
    return new Promise((resolve, reject) => {

        new Compressor(blobParam, {
            quality: 0.6,
            success(result) {
                resolve(result);
            },
            error(error) {
                console.error("Erreur lors de la compression de l'image", error);
                resolve(blobParam);
            },
        });
    });
};

export default compressImageBlob;