import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import AdminService from "../../services/admin.service";
import {toast} from "react-toastify";
import Menu from "../../components/layout/Menu";
import FormAdmin from "./Admin.form";

export default function AdminDetail() {
    //get id on params url
    const { id } = useParams();

    const breadcrumbArray = [
        { name: "Liste des utilisateurs", to: "/admin" },
        { name: "Détail", to: `/admin/${id}` },
    ];
    const [admin, setAdmin] = useState({});
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [author, setAuthor] = useState(false);
    const [proofreader, setProofreader] = useState(false);
    const [password, setPassword] = useState("");
    const [pictureId, setPictureId] = useState('');
    const [pictureUrl, setPictureUrl] = useState('');
    const [buttonState, setButtonState] = useState(true);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        //get data from api
        if (id) {
            setLoading(true)
            //call api
            AdminService.getOneAdmin(id)
                .then((response) => {
                    setLoading(false)
                    if (response.data) {

                        setAdmin(response.data ? response.data : "")
                        setEmail(response.data.account ? response.data.account : "")
                        setName(response.data.name ? response.data.name : "")
                        setAuthor(response.data.is_author ? response.data.is_author : false)
                        setProofreader(response.data.is_proofreader ? response.data.is_proofreader : false)
                        if (response.data.media) {
                            setPictureId(response.data.media.id ? response.data.media.id : "")
                            setPictureUrl(response.data.media.url ? response.data.media.url : "")
                        }
                    } else {
                        toast.error("Erreur lors de la récupération des données")
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    toast.error("Erreur lors de la récupération des données")
                })

            //set data to admin
        }
    }, []);
    const handleUpdate = (event) => {
        //call api
        event.preventDefault();
        setLoading(true)
        AdminService.updateAdmin({id, account: email, name, is_author: author, is_proofreader: proofreader, password, picture_id:pictureId})
            .then((response) => {
                setLoading(false)
                setButtonState(true)
                if (response.data) {
                    toast.success("Utilisateur modifié avec succès", {
                        position: "top-right",
                        theme: "dark",
                    })
                } else {
                    toast.error("Erreur lors de la modification de l'utilisateur", {
                        position: "top-right",
                        theme: "dark",
                    })
                }
            })
            .catch((error) => {
                setLoading(false)
                setButtonState(true)
                toast.error("Erreur lors de la modification de l'utilisateur", {
                    position: "top-right",
                    theme: "dark",
                })
            })
    }

    useEffect(() => {
        if (admin) {
            if (admin.account !== email || admin.name !== name || admin.is_proofreader !== proofreader || admin.is_author !== author || (password !== "" && password.length > 7) || (pictureId !== "" && pictureId !== admin.media?.id)) {
                //check if email is an email valid
                if (email !== "" && !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    setButtonState(true)
                    setEmailError(true)
                } else {
                    setButtonState(false)
                    setEmailError(false)
                }
            } else {
                setButtonState(true)
                setEmailError(false)
            }
        }
    }, [email, name, author, proofreader, password, pictureId])

    return (
        <Menu category="Utilisateurs" breadCrumb={breadcrumbArray} link="/admin/create" buttonText="Créer un utilisateur">
            <FormAdmin name={name} setName={setName} email={email} setEmail={setEmail} password={password} setPassword={setPassword} pictureId={pictureId} setPictureId={setPictureId} author={author} setAuthor={setAuthor} proofreader={proofreader} setProofreader={setProofreader} buttonName={"Modifier"} buttonState={buttonState} handleUpdate={handleUpdate} pictureUrl={pictureUrl} setPictureUrl={setPictureUrl} emailError={emailError} id={id} loading={loading} setLoading={setLoading}  />
        </Menu>
    )
}