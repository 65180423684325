import React, {useEffect, useState} from 'react'
import {Button, Container, Grid} from '@mui/material'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from 'react-router-dom';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FormModal from "../FormModal";
import {ModalMedia} from "../../pages/articles/components/ModalMedia";
import {IMAGE_URL} from "../../constants/url";


export const ImageButton = ({ data, isTv = false, isPodcast = false, infoCreate = {}, setInfoCreate = {}, editPodcast = {}, setLoading = {} }) => {
    const navigate = useNavigate();
    const [openModalEditPodcast, setOpenModalEditPodcast] = React.useState(false);
    const [titlePodcast, setTitlePodcast] = React.useState('');
    const [podcastId, setPodcastId] = React.useState('');
    const [pictureIdPodcast, setPictureIdPodcast] = React.useState('');
    const [podcastPictureUrl, setPodcastPictureUrl] = React.useState('');
    const [openModalMedia, setOpenModalMedia] = useState(false);

    const [dataPodcast, setDataPodcast] = useState({
        media_id: [],
    });


    const handleCloseModalMedia = () => {
        setLoading(true);
        setOpenModalMedia(false);
        if (dataPodcast.media_id.length > 0) {
            setPictureIdPodcast(dataPodcast.media_id[0]);
            const media = infoCreate.media.find((item) => item.id == dataPodcast.media_id[0]);
            setPodcastPictureUrl(`${IMAGE_URL + media.url}`);
        }
        setOpenModalEditPodcast(true)
        setLoading(false);
    }

    const handleOpenPodcastOrTV = (id) => {
        if (isPodcast) {
            navigate(`/podcast/${id}`);
        }
        if (isTv) {
            navigate(`/tv/${id}`);
        }
    }

    const handleModalFormPodcast = (id, title, pictureId, pictureUrl) => {
        setPodcastId(id)
        setTitlePodcast(title);
        setPictureIdPodcast(pictureId);
        setPodcastPictureUrl(pictureUrl);
        setOpenModalEditPodcast(true);
    }


    const handleCloseEditPodcast = () => {
        setOpenModalEditPodcast(false);
    }
    const handleOpenModalMedia = () => {
        setOpenModalMedia(true);
    }

    const _editPodcastShow = () => {
        if (podcastId && titlePodcast && pictureIdPodcast) {
            editPodcast({id: podcastId, name: titlePodcast, media_id:pictureIdPodcast});
        }
        setOpenModalEditPodcast(false);
    }

    return (

        <Grid container sx={{ display: 'flex', marginLeft: "3rem", width: "unset" }}>
            {data.map((item, index) => (
                <Grid key={index} item xs={3}>
                    <Typography
                        variant="body2"
                        style={{ fontSize: "14px", fontWeight: "bold", color: "#16324D", marginBottom: -33, marginTop: 25 }}
                        onClick={() => handleOpenPodcastOrTV(item.id)}
                    >
                        {item.name}
                    </Typography>
                    <Box sx={{ maxWidth: "90%", height: "200px", overflow: "hidden", borderRadius: 2, backgroundColor: "#16324D", position: "relative", marginTop: 5 }}>
                        <img
                            src={item.image}
                            alt={item.name}
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                        />
                        <Typography
                            className={"watermark"}
                            variant="body2"
                            onClick={() => handleOpenPodcastOrTV(item.id)}
                        />
                        {isPodcast && (
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    padding: '10px',
                                    margin: 0,
                                    width: '32px',
                                    minWidth:'32px',
                                    borderRadius: '8px',
                                    position: "relative",
                                    bottom:"195px",
                                    left: "5px",
                                }}
                                onClick={() => {handleModalFormPodcast(item.id, item.name, item.imageId, item.image)}}
                            >
                                <ModeEditIcon style={{ fontSize: 15 }} />
                            </Button>
                        )}
                    </Box>
                </Grid>
            ))}
            {openModalEditPodcast && (
                <FormModal
                    open={openModalEditPodcast}
                    handleClose={handleCloseEditPodcast}
                    handleAgree={_editPodcastShow}
                    title={"Editez votre chaine de podcast"}
                    buttonAccept={"Enregistrer"}
                    buttonCancel={"Annuler"}
                    rows={[
                        {
                            label: "Titre",
                            value: titlePodcast,
                            onChange: (e) => setTitlePodcast(e.target.value),
                            type: "text",
                            disabled: true,
                            required: true,
                        },
                        {
                            label: "Image",
                            value: pictureIdPodcast,
                            onChange: (e) => setPictureIdPodcast(e.target.value),
                            type: "img",
                            required: true,
                            url: podcastPictureUrl,
                        }
                    ]}
                    handleOpenModalMedia={handleOpenModalMedia}
                />
            )}
            {openModalMedia && (
                <ModalMedia
                    open={openModalMedia}
                    handleClose={handleCloseModalMedia}
                    setDataArticle={setDataPodcast}
                    dataArticle={dataPodcast}
                    infoCreate={infoCreate}
                    setInfoCreate={setInfoCreate}
                    isPodcast={true}
                />
            )}
        </Grid>

    )
}
