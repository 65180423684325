import React, { useEffect, useState } from "react";
import Menu from "../../components/layout/Menu";
import { useParams } from 'react-router-dom'
import { Grid, TextField, Paper, FormGroup, FormControlLabel, Box, Button, CircularProgress, InputLabel, Typography } from '@mui/material'
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import SelectComponent from "../../pages/articles/components/SelectComponent";
import { DatePicker } from "../../pages/articles/components/DatePicker";
import CustomInlineComponent from "../../pages/articles/components/CustomInlineComponent";
import CoverImageSelector from "../../pages/articles/components/ConverImageSelector";
import instance from "../../utils/axios";
import IOSSwitch from "../../common/IosSwithc";
import { useNavigate } from 'react-router-dom';
import { PublishOutlined } from "@mui/icons-material";
import { ModalMedia } from "../../pages/articles/components/ModalMedia";
import { AddImageModal } from "../../pages/articles/components/AddImageModal";
import { Alert } from "@mui/material";
import tvService from "../../services/tv.services";
import ConfirmDeleteModal from '../../pages/articles/components/ConfirmDeleteModal';
import { useLocation } from 'react-router-dom';
import ModalAddVideoYoutube from "../ModalAddVideo";
import AddVideoButton from "../buttonAddVideo/AddVideoButton";
import CustomModalSuccess from "../../pages/articles/components/CustomModalSuccess";
import verifyIfDateIsString from "../../utils/verifyIfDateIsString";
import AudioUploadInput from "../../components/ListPodcastTV/AudioUpload"
import podcastService from "../../services/podcast.services";
import formatDuration from "../../utils/formatDuration";
import ReactPlayer from 'react-player';
import CustomModalLoad from "./CustomModalLoad";
import AlertDialog from "../../components/Alert";
import { IMAGE_URL } from '../../constants/url';



export const EditCreatePodcastTv = () => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean); // Filtra segmentos vacíos

    // Lógica para decidir si es tvEdit, tvCreate o ninguna de las dos
    const isEdit = pathSegments.includes('edit');
    const isCreate = pathSegments.includes('create');
    const isPodcast = pathSegments.includes('podcast');
    const isTv = pathSegments.includes('tv');

    const navigate = useNavigate();
    const { podcastId } = useParams(); // Récupère l'ID de l'URL
    const { podcastGroupId } = useParams(); // Récupère le type de l'URL
    const { tvId } = useParams(); // Récupère l'ID de l'URL
    const { tvGroupId } = useParams(); // Récupère le type de l'URL
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [tagsCreate, setTagCreate] = useState([]);
    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openCroppImage, setOpenCroppImage] = useState(false);
    const caculateWidth = Math.max(370 - (1920 - windowWidth) * 0.3, 0);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const handleOpenModalSuccess = () => setOpenModalSuccess(true);
    const handleCloseModalSuccess = () => setOpenModalSuccess(false);
    const [isFocused, setIsFocused] = useState(false);
    const [filePodcast, setFilePodcast] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [audioDuration, setAudioDuration] = useState(0);
    const [openModalAddVideo, setOpenModalAddVideo] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [CancelConfirmPublication, setCancelConfirmPublication] = useState(false);

    const handleCloseVideo = () => setOpenModalAddVideo(false);
    const handleOpenVideo = () => setOpenModalAddVideo(true);

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const [infoCreate, setInfoCreate] = useState({
        tags: [],
        media: [],
        themes: [],
    });
    const [shows, setShows] = useState([]);

    const [dataArticle, setDataArticle] = useState({
        title: "",
        description: "",
        tv_show_id: 0,
        publication_date: Math.round(new Date().getTime() / 1000),
        is_active: false,
        url: "",
        info: false,
        media_id: [],
        view: 0,
        sponsor: false,
        notification: false,
        podcast_show_id: 0,
        podcast_id: 0,
        total_time: 0,
        mediaItems: [],
    });

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };


    const findShowNameById = (id) => {
        const show = shows.find((show) => show.id === Number(id));
        if (isTv) {
            return show ? show.title : "";
        } else {
            return show ? show.name : "";
        }
    };


    let breadcrumbArray = [
        { name: "Tableau de bord", to: "/dashboard" },
        { name: "Podcast", to: "/podcast" },
        { name: `Podcast ${findShowNameById(podcastGroupId)} `, to: `/podcast/${podcastGroupId}` },
    ];
    if (tvGroupId) {
        breadcrumbArray = [
            { name: "Tableau de bord", to: "/dashboard" },
            { name: "TV", to: "/tv" },
            { name: `${findShowNameById(tvGroupId)}`, to: `/tv/${tvGroupId}` },
        ];
    }

    const category = () => {
        if (podcastGroupId && podcastId === undefined) {
            return "Création d'un Podcast"
        } else if (tvGroupId && tvId === undefined) {
            return "Création TV"
        } else if (podcastId) {
            return "Editer un Podcast"
        }
        else if (tvId) {
            return "Editer une TV"
        } else {
            return "Création/Edition"
        }
    }

    const openMediaPicker = () => {
        setOpen(true);
    };

    const closeMediaPicker = () => {
        setOpen(false);
    };
    const handleOpenCroppImage = () => {
        setOpenCroppImage(true)
    };

    const isValidated = (isValidated, validateDate, is_active) => {
        if (isValidated === false && is_active === false) {
            return "A relire"
        } else if (is_active === true) {
            return "Publié"
        } else if (isValidated === true && validateDate !== null && is_active === false) {
            return "A Publier"
        }
        else {
            return "A valider"
        }
    }
    //LOGIC FOR EDIT
    useEffect(() => {
        if (podcastId) {
            podcastService.getOnePodcast(podcastId)
                .then((res) => {
                    if (res && res.status === 200 && res.data) {

                        const { title, description, publication_date, is_active, media_id, podcast_show_id, podcast_id, total_time, podcastMedia, media, view } = res.data;
                        setDataArticle({
                            title,
                            description,
                            publication_date: verifyIfDateIsString(publication_date),
                            is_active,
                            media_id: [media_id],
                            podcast_show_id: [podcast_show_id],
                            podcast_id,
                            total_time,
                            mediaItems: [media],
                            view: view
                        })
                        setAudioUrl(`${IMAGE_URL}${podcastMedia.url}`)
                    }
                })
                .catch((error) => console.log(error));
        }
        if (isTv)
            tvService.getTvShow().then((response) => {
                if (response.data) {
                    setShows(
                        response.data
                    )
                }
            })
        if (tvId) {

            tvService.getTvReplay(tvId)
                .then((res) => {
                    if (res && res.status === 200 && res.data) {

                        const { title, description, publication_date, is_active, url, sponsor, tv_show_id, view, info, medias } = res.data;
                        setDataArticle({
                            ...dataArticle,
                            title,
                            description,
                            publication_date: verifyIfDateIsString(publication_date),
                            url,
                            sponsor,
                            is_active,
                            tv_show_id,
                            view,
                            media_id: medias ? medias.map((media) => media.id) : [],
                            info,
                            mediaItems: medias
                        })
                    }
                })
                .catch((error) => console.log(error));
        }
        if (podcastGroupId) {
            podcastService.getPodcastShows().then((response) => {
                if (response.data) {
                    setShows(
                        response.data
                    )
                }
            })
        }

    }
        , []);

    useEffect(() => {
        instance.get("articles/info-create").then((response) => {
            if (response.data) {
                if (response && response.status === 200 && response.data && response.data.tags && response.data.themes) {
                    setTagCreate(response.data.tags);

                }
                setInfoCreate({
                    ...infoCreate,
                    tags: response.data.tags,
                    media: response.data.media,
                    themes: response.data.themes,
                });
            }
        })
            .catch((error) => console.log(error));

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const handleChangeSwitch = (event, propertyName) => {
        const newValue = event.target.checked;
        setDataArticle({ ...dataArticle, [propertyName]: newValue });
    };
    const handleCancel = () => {
        if (isPodcast) {
            navigate(`/podcast/${podcastGroupId}`)
        }
        if (isTv) {
            navigate(`/tv/${tvGroupId}`)

        }

    }
    const MiniAudioPlayer = ({ audioUrl }) => {
        return (
            <div className="mini-audio-player">
                <ReactPlayer
                    url={audioUrl}
                    controls
                    width="400px"
                    height="30px"
                />
            </div>
        );
    };

    const handleCloseCroppImage = ({ openMediapicker = false }) => {
        if (openMediapicker) {
            setOpenCroppImage(false)
            openMediaPicker();
        } else {
            setOpenCroppImage(false)
        }
    };

    const create = async () => {
        setLoaded(true);
        if (isTv) {

            tvService.createTvReplay({
                ...dataArticle,
                url: dataArticle.url,
                medias: dataArticle.media_id,
                publication_date: dataArticle.publication_date
            }
            ).then((res) => {
                setLoaded(false);
                if (res.status === 200) {
                    handleOpenModalSuccess();
                    //timer to close 
                    setTimeout(() => {

                        navigate(`/tv/${tvGroupId}`)

                    }, 1500);
                }

            })
                .catch((err) => {
                    setLoaded(false);
                    console.log(err);
                })
        }
        if (isPodcast) {
            if (!dataArticle.title || !dataArticle.description || !dataArticle.media_id || !dataArticle.podcast_show_id || !filePodcast) {
                setError(true);
                setLoaded(false);
                return false;
            } else {
                setOpenLoading(true);
                setIsLoading(true);
                await podcastService.uploadPodcastAudioFile(filePodcast).then((response) => {
                    if (response.data) {
                        const id = response.data.data.id;

                        podcastService.createPodcast({
                            title: dataArticle.title,
                            description: dataArticle.description,
                            is_active: dataArticle.is_active,
                            publication_date: dataArticle.publication_date,
                            media_id: dataArticle.media_id[0],
                            podcast_show_id: dataArticle.podcast_show_id[0],
                            total_time: formatDuration(audioDuration),
                            podcast_id: id
                        }).then((res) => {
                            setIsLoading(false);
                            setLoaded(false);
                            if (res.status === 200) {
                                setSuccess(true);
                                //timer to close 
                                setTimeout(() => {
                                    setSuccess(false);
                                    navigate(`/podcast/${podcastGroupId}`)
                                }, 2300);
                            }
                        })
                            .catch((err) => {
                                setSuccess(false);
                                setOpenLoading(false);
                                console.log(err);
                            })
                    }
                }
                )

                    .catch((error) => console.log(error));
            }
        }
    };

    const handleDelete = () => {
        if (isTv) {
            tvService.deleteTvReplay(tvId)
                .then((res) => {
                    if (res.status === 200) {
                        navigate(`/tv/${tvGroupId}`)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        if (isPodcast) {
            handleDeletePodcast();
        }
    }

    const ButtonsCreate = () => {
        return (
            <>
                <div>
                    <button onClick={() => setCancelConfirmPublication(true)} className="btn btn-danger">Annuler</button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        sx={{
                            marginLeft: "5px",
                            backgroundColor: "#4caf50",
                            color: "white",
                            paddingBottom: "6px",
                        }}
                        // disabled={loaded ? true : false}
                        onClick={() => create()}
                    >
                        {loaded ? (
                            <CircularProgress color="inherit" />
                        ) : (
                            <>
                                Publier <PublishOutlined />
                            </>
                        )}
                    </Button>


                </div>
            </>
        )
    }
    const handleChangeGeneric = (event) => {
        const newValue = event.target.value;
        setDataArticle({ ...dataArticle, url: newValue });
    };


    const handleSave = async () => {
        if (isTv) {
            tvService.updateTvReplay({
                ...dataArticle,
                id: tvId,
                medias: dataArticle.media_id,
                publication_date: dataArticle.publication_date,
            }
            ).then((res) => {
                if (res.status === 200) {
                    handleOpenModalSuccess();
                    //timer to close 
                    setTimeout(() => {

                        navigate(`/tv/${tvGroupId}`)

                    }, 1500);
                }
            })
                .catch((err) => {
                    console.log(err);
                })
        }
        if (isPodcast) {

            // if audioUrl is not empty update only the podcast
            if (audioUrl) {
                if (!dataArticle.title || !dataArticle.description || !dataArticle.media_id || !dataArticle.podcast_show_id) {
                    setError(true);
                    return false;
                } else {
                    podcastService.updatePodcast({
                        id: podcastId,
                        title: dataArticle.title,
                        description: dataArticle.description,
                        is_active: dataArticle.is_active,
                        publication_date: dataArticle.publication_date,
                        media_id: dataArticle.media_id[0],
                        podcast_show_id: dataArticle.podcast_show_id[0],
                        total_time: dataArticle.total_time,
                        podcast_id: dataArticle.podcast_id
                    }).then((res) => {
                        if (res.status === 200) {
                            handleOpenModalSuccess();
                            //timer to close 
                            setTimeout(() => {
                                navigate(`/podcast/${podcastGroupId}`)
                            }, 1500);
                        }
                    })
                        .catch((err) => {
                            console.log(err);
                        })
                }
            } else {
                if (!dataArticle.title || !dataArticle.description || !dataArticle.media_id || !dataArticle.podcast_show_id || !filePodcast) {
                    setError(true);
                    return false;
                } else {
                    // upload audio file and update podcast
                    setOpenLoading(true);
                    setIsLoading(true);
                    await podcastService.uploadPodcastAudioFile(filePodcast).then((response) => {
                        if (response.data) {
                            const id = response.data.data.id;
                            podcastService.updatePodcast({
                                id: podcastId,
                                title: dataArticle.title,
                                description: dataArticle.description,
                                is_active: dataArticle.is_active,
                                publication_date: dataArticle.publication_date,
                                media_id: dataArticle.media_id[0],
                                podcast_show_id: dataArticle.podcast_show_id[0],
                                total_time: formatDuration(audioDuration),
                                podcast_id: id
                            }).then((res) => {
                                if (res.status === 200) {
                                    setIsLoading(false);
                                    setSuccess(true);
                                    handleOpenModalSuccess();
                                    //timer to close 
                                    setTimeout(() => {
                                        setSuccess(false);
                                        navigate(`/podcast/${podcastGroupId}`)
                                    }, 2300);
                                }
                            })
                                .catch((err) => {
                                    setSuccess(false);
                                    setOpenLoading(false);
                                    console.log(err);
                                })
                        }
                    }

                    )
                }
            }
        }
    }


    const handleDeletePodcast = () => {
        podcastService.deletePodcast(podcastId)
            .then((res) => {
                if (res.status === 200) {
                    navigate(`/podcast/${podcastGroupId}`)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const ButtonsEdit = () => {
        return (
            <>
                <div>
                    <button onClick={handleOpenModal} className="btn btn-danger mr-1">Supprimer</button>
                    <button onClick={() => setCancelConfirmPublication(true)} className="btn btn-secondary">Annuler</button>
                </div>
                <div>
                    <button onClick={handleSave} className="btn btn btn-dark mr-2">Enregistrer</button>


                </div>
            </>
        )
    }

    return (
        <Menu category={category()} breadCrumb={breadcrumbArray}>
            <Grid container spacing={5} className={"m-4"} sx={{ display: 'flex', width: "100%" }}>
                <Grid sx={{ flexDirection: "column" }} container xs={8}>
                    <TextField
                        className={"mb-3"}
                        id="title"
                        label="Titre"
                        variant="outlined"
                        value={dataArticle.title}
                        onChange={(e) => {
                            setDataArticle({ ...dataArticle, title: e.target.value })
                        }
                        }
                    />
                    <TextField
                        className={"mb-2"}
                        id="description"
                        label="Texte descriptif / Chapeau"
                        multiline
                        value={dataArticle.description}
                        onChange={(e) => {
                            setDataArticle({ ...dataArticle, description: e.target.value })
                        }
                        }
                        rows={3}
                    />

                </Grid>
                <Grid container xs={4}>
                    <div style={
                        {
                            marginLeft: "25px",
                            width: "88%",
                        }
                    } >
                        <CustomInlineComponent
                            textLeft={"Statut"}
                            textRight={isValidated(true, true, true)}
                        />
                        {(tvGroupId || podcastId) && (
                            <>
                                <CustomInlineComponent
                                    textLeft={tvGroupId ? "Nombre de vues" : "Nombre d'écoutes"}
                                    textRight={dataArticle.view}

                                />

                                <AddVideoButton handleOpenVideo={handleOpenVideo} />
                            </>
                        )}
                        {isPodcast && (
                            <AudioUploadInput onFileChange={setFilePodcast} setAudioDuration={setAudioDuration} remplaceAudio={podcastId} setAudioUrl={setAudioUrl} audioUrl={audioUrl} />
                        )}
                        {audioUrl && (
                            <div className="mb-6">
                                <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: '#16324D', marginRight: '5px', marginBottom: "12px" }}>
                                    Audio actuel du podcast :
                                </Typography>
                                <MiniAudioPlayer audioUrl={audioUrl} />
                            </div>
                        )}

                        <CoverImageSelector
                            dataArticle={dataArticle}
                            setDataArticle={setDataArticle}
                            infoCreate={infoCreate}
                            setInfoCreate={setInfoCreate}
                            openMediaPicker={openMediaPicker}
                            setOpen={setOpen}
                            handleOpenCroppImage={handleOpenCroppImage}
                        />

                        <div className='mt-10'>
                            <Paper variant="outlined" style={{
                                boxShadow: "none",
                                padding: '8px',
                                paddingLeft: '15px',
                                height: '9rem',
                                overflow: "auto",
                                overflowY: "scroll",

                            }}>
                                <Box sx={{ marginTop: 1 }}>
                                    <FormGroup>
                                        <FormControlLabel setDataArticle={setDataArticle} control={<IOSSwitch checked={dataArticle.is_active} onChange={((e) => handleChangeSwitch(e, "is_active"))} sx={{ marginRight: 5, marginLeft: 1 }} />} label="Afficher" />
                                        {tvGroupId && (
                                            <>
                                                <FormControlLabel control={<IOSSwitch checked={dataArticle.info} onChange={((e) => handleChangeSwitch(e, "info"))} sx={{ marginRight: 5, marginLeft: 1 }} />} label="Publier Fil info" />
                                                <FormControlLabel control={<IOSSwitch checked={dataArticle.sponsor} onChange={((e) => handleChangeSwitch(e, "sponsor"))} sx={{ marginRight: 5, marginLeft: 1 }} />} label="Sponsor" />

                                            </>
                                        )}
                                        <SelectComponent
                                            dataArticle={dataArticle}
                                            setDataArticle={setDataArticle} propertyName={isPodcast ? "podcast_show_id" : "tv_show_id"}
                                            isTvShow={isPodcast ? false : true}
                                            options={shows}
                                            label={isPodcast ? "Rubrique" : "Emission"} />
                                    </FormGroup>

                                </Box>
                            </Paper>

                        </div>
                        {tvGroupId && (
                            <>
                                <Paper variant="outlined" style={{
                                    boxShadow: "none",
                                    padding: '8px',
                                    paddingLeft: '15px',
                                    // textAlign: 'center',
                                    height: '6rem',
                                    overflow: "hidden",
                                    width: "100%",

                                    marginTop: "7px"
                                }}>
                                    <InputLabel sx={
                                        {
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            marginBottom: "6px"

                                        }
                                    } >
                                        URL Video youtube
                                    </InputLabel>

                                    <input
                                        style={{
                                            border: isFocused ? '1px solid #E0E3E7' : 'none',
                                            outline: 'none',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#16324D',
                                            borderRadius: '4px',
                                            padding: '10px',
                                            boxShadow: 'none',
                                            width: '100%',
                                        }}
                                        value={dataArticle.url}
                                        placeholder="Saisir l’URL du podcast"
                                        onChange={(event) => handleChangeGeneric(event, 'url')}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                    />
                                </Paper>
                            </>
                        )}
                        <Paper variant="outlined" style={{
                            boxShadow: "none",
                            padding: '8px',
                            paddingLeft: '15px',
                            height: '9rem',
                            overflow: "hidden",

                            marginTop: "7px"
                        }}>
                            <DatePicker
                                setDataArticle={setDataArticle}
                                isPodcastAndTv={true}
                                dataArticle={dataArticle}
                            />

                            <div className='mt-3 justify-between flex'>
                                {isCreate && (
                                    <ButtonsCreate />
                                )}
                                {isEdit && (
                                    <ButtonsEdit />
                                )}

                            </div>

                        </Paper>
                        {open && (
                            <ModalMedia
                                open={open}
                                handleClose={closeMediaPicker}
                                handleSave={closeMediaPicker}
                                setDataArticle={setDataArticle}
                                dataArticle={dataArticle}
                                infoCreate={infoCreate}
                                setInfoCreate={setInfoCreate}

                            />
                        )}
                        {openCroppImage && (
                            <AddImageModal
                                open={openCroppImage}
                                handleCloseCroppImage={handleCloseCroppImage}
                                setInfoCreate={setInfoCreate}
                                infoCreate={infoCreate}
                                dataArticle={dataArticle}
                                setDataArticle={setDataArticle}
                            />
                        )
                        }
                    </div>
                </Grid>
            </Grid>

            <ConfirmDeleteModal
                open={openModal}
                onClose={handleCloseModal}
                onConfirm={handleDelete}
            />
            <ModalAddVideoYoutube
                open={openModalAddVideo}
                onClose={handleCloseVideo}
                dataArticle={dataArticle}
                setDataArticle={setDataArticle}

            />
            <CustomModalSuccess
                open={openModalSuccess}
                onClose={handleCloseModalSuccess}
                title={"Succès"}
                description={"L'article a été enregistré avec succès."}

            />
            <CustomModalLoad
                open={openLoading}
                onClose={() => setOpenLoading(false)}
                title={"Succès"}
                description={"Podcast a ete cree avec succès."}
                isLoading={isLoading}
                messageLoading={"La création du podcast est en cours. Veuillez ne pas fermer l'onglet. En fontion de la taille du fichier audio, cela peut prendre plus ou moins de temps."}
            />
            {/* Cancel confirm */}
            <AlertDialog
                open={CancelConfirmPublication}
                handleClose={() => setCancelConfirmPublication(false)}
                handleAgree={handleCancel}
                title={"Attention"}
                label={"Voulez vous vraiment annuler ? Toutes vos modifications seront perdues"}
                buttonAccept={"Oui"}
                buttonCancel={"Non"}
            />

            {error ? (
                <Alert sx={{ marginBottom: 1, marginTop: 3, position: "absolute", top: 27, right: 750 }} severity="error">

                    Tous les champs sont obligatoires
                </Alert>
            ) : null}
            {success ? (
                <Alert sx={{ marginBottom: 1, marginTop: 3, position: "absolute", top: 27, right: 750 }} severity="success">
                    Article créé avec succès
                </Alert>
            ) : null}

        </Menu>
    )
}
