export const formStyle = {
    p: 2,
    border: '1px solid',
    borderColor:'#70767a',
    boxShadow: "0px 0px 5px rgba(112, 118, 122, 0.5)",
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    maxWidth: 800,
    flexWrap: 'wrap',
    marginTop: 5,
    marginBottom: 10,
    padding: 5,
    backgroundColor: "#F2F2F2"
};