import dayjs from 'dayjs';
const ConvertDateToMilliseconds = (date) => {
    if (typeof date === "string") {
        const originalDate = date;

        // Separate the date and time
        const [datePart, timePart] = originalDate.split(' ');

        // Separate the day, month, and year
        const [day, month, year] = datePart.split('/');

        // Create the new inverted date
        const invertedDate = `${month}/${day}/${year} ${timePart}`;

        // Convert the inverted date to Unix timestamp
        return Math.round(dayjs(invertedDate).unix());

    } else {
        // If it's not a string, assume it's already a Unix timestamp and convert to milliseconds

        return Math.round(date);
    }
}

export default ConvertDateToMilliseconds;