import React from 'react';
import { Stack, TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ImageButton } from '../imagesButton/ImageButton';

const CustomListPodcastTV = ({ data, isTv = false, isPodcast = false, infoCreate={}, setInfoCreate={}, editPodcast={}, setLoading={} }) => {
    return (
        <>
            <ImageButton data={data} isTv={isTv} isPodcast={isPodcast} infoCreate={infoCreate} setInfoCreate={setInfoCreate} editPodcast={editPodcast} setLoading={setLoading} />
        </>
    );
};

export default CustomListPodcastTV;
