import axios from "axios";
import { toast } from "react-toastify";
import {API_URL} from "../constants/url";

// const API_URL = "http://localhost:4000/api_bo/";


const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  // headers: {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Credentials": true,
  // },
});
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwt');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
// create interceptor for response to code 401 Invalid Token
let isToastShown = false;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Verificar si el toast ya se ha mostrado
      if (!isToastShown) {
        toast.error("Votre session a expiré, veuillez vous reconnecter, vous allez être redirigé vers la page de connexion", {
          position: "top-center",
          theme: "dark",
        });
        // Marcar que el toast ha sido mostrado
        isToastShown = true;

        setTimeout(() => {
          window.location.href = "/login";
          localStorage.removeItem('jwt');
        }, 2000);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
