import instance from "../utils/axios";

class podcast {
    constructor() {
        this.instance = instance;
    }

    getPodcastShows = async () => {
        return await instance.get("podcast-show", {
            withCredentials: true,
        });
    };

    editPodcastShow = async (data) => {
        return await instance.put(`podcast-show`, data, {
            withCredentials: true,
        });
    }

    getAllPodcastByShow = async (id) => {
        return await instance.get(`podcast/by-show?podcast_show_id=${id}`, {
            withCredentials: true,
        });
    };

    getOnePodcast = async (id) => {
        return await instance.get(`podcast/one?id=${id}`, {
            withCredentials: true,
        });
    };

    uploadPodcastAudioFile = async (file) => {

        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "sound");
        return await instance.post(`media/upload-sound`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
        });
    }

    deletePodcast = async (id) => {
        return await instance.delete(`podcast/delete?id=${id}`, {
            withCredentials: true,
        });
    }

    updatePodcast = async (data) => {
        return await instance.put(`podcast/update`, data, {
            withCredentials: true,
        });
    }

    createPodcast = async (data) => {
        return await instance.post(`podcast/create`, data, {
            withCredentials: true,
        });
    }
    getAllPodcasts = async () => {
        return await instance.get(`podcast/all`, {
            withCredentials: true,
        });
    }





}

var podcastService = new podcast();
export default podcastService;
