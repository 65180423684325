import React, { useState, useEffect } from 'react';
import { Select, FormControlLabel, Typography, MenuItem } from '@mui/material';
import { useTheme, useMediaQuery } from "@mui/material";


const MultiselectCityAndTheme = ({ options, selectedOptions, setSelectedOptions, label, marginRight = 0 }) => {

    // Responsive 
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // 

    const handleChange = (event) => {
        const newValues = event.target.value;
        ;
        setSelectedOptions(newValues)
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const caculateWidth = Math.max(285 - (1920 - windowWidth) * 0.3, 0);

    const optionsItemsTheme = options.map((option, index) => (
        <MenuItem key={index} value={option.id} sx={
            selectedOptions.includes(option.id) ? {
                color: '#16324D',
                fontWeight: 'bold'
            } : {}
        }>
            {option?.name}
        </MenuItem>
    ));


    return (

        <FormControlLabel
            sx={{ marginRight: isSmallScreen ? 0 : 50, marginLeft: 1, color: 'black', marginTop: 2, }}
            // sx={{ marginRight: 50, marginLeft: 1, color: 'black', marginTop: 2 }}
            control={
                <Select

                    sx={{ width: isSmallScreen ? "190px" : `${caculateWidth}px`, maxWidth: '100%', height: 32, marginLeft: 4 }}

                    multiple
                    value={selectedOptions}
                    onChange={handleChange}
                >
                    {optionsItemsTheme}
                </Select>
            }
            label={<Typography style={{ fontWeight: 'bold', fontSize: '16px', marginRight: marginRight }}>{label}</Typography>}
            labelPlacement="start"
        />

    );
};

export default MultiselectCityAndTheme;
