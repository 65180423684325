import React from 'react';
import { Select, MenuItem, FormControlLabel, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from "@mui/material";

const SelectMultipleComponent = ({ options, label, propertyName, isAuteur = false, marginLeft = 0, mLeftSelect = 2, dataArticle, setDataArticle, isTvShow = false }) => {

    // Responsive 
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // 

    const handleChange = (event) => {
        const newValues = event.target.value;
        setDataArticle({ ...dataArticle, [propertyName]: newValues });
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const caculateWidth = Math.max(370 - (1920 - windowWidth) * 0.3, 0);

    const optionsItems = options.map((option, index) => (
        <MenuItem key={index} value={option.id} sx={
            dataArticle[propertyName]?.includes(option.id) ? {
                color: '#16324D',
                fontWeight: 'bold'
            } : {}
        }>
            {isAuteur ? option?.firstname : (isTvShow ? option?.title : option?.name)}
        </MenuItem>
    ));

    return (
        <FormControlLabel
            sx={{ marginRight: isSmallScreen ? 30 : 50, marginLeft: marginLeft, color: 'black', marginTop: 2, width: isSmallScreen ? "250px" : "auto" }}
            control={
                <Select
                    // style={{ width: `${caculateWidth}px`, maxWidth: '100%' }}
                    sx={{ width: isSmallScreen ? "182px" : `${caculateWidth}px`, marginLeft: mLeftSelect, maxWidth: '100%', height: 32 }}
                    multiple
                    value={dataArticle[propertyName] || []}
                    onChange={handleChange}
                >
                    {optionsItems}
                </Select>
            }
            label={<Typography style={{ fontWeight: 'bold', fontSize: '16px', width: "50px", padding: 0 }}>{label}</Typography>}
            labelPlacement="start"
        />
    );
};

export default SelectMultipleComponent;
