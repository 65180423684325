import React, { useEffect, useState } from 'react';
import {
    Button,
    Popover,
    Typography,
    Box,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import tvService from '../../../services/tv.services';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AlertDialog from '../../../components/Alert';


const EditModal = ({ appointment, isOpen, setIsOpen, anchorEl, setAnchorEl, setRadioProgramsFromServer, modifiedData }) => {
    const { id, title, description, days, start, end } = appointment;
    const [openDialog, setOpenDialog] = useState(false);
    const handleDeleteClick = () => {
        setOpenDialog(true);
    };
    const handleDeleteCancelled = () => {
        setOpenDialog(false);
    };

    const [isEditMode, setIsEditMode] = useState(false);
    const dataForm = {
        id: "",
        title: "",
        description: "",
        days: "",
        start: "",
        end: "",
    }

    const [editedContent, setEditedContent] = useState(dataForm);
    useEffect(() => {
        setEditedContent({
            id: id,
            title: title,
            description: description,
            days: days,
            start: start,
            end: end,
        });
        setFormErrors(dataForm);
    }, [isOpen]);

    const [formErrors, setFormErrors] = useState(dataForm);

    const handleClick = (event) => {
        setIsOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleEditModeToggle = () => {
        setIsEditMode(!isEditMode);
    };

    const handleClose = () => {
        setIsOpen(false);
        setAnchorEl(null);
        setIsEditMode(false);

    };
    const handleDelete = (id) => {
        tvService.deleteTvProgram(id).then((response) => {
            if (response.status === 200) {
                tvService.getAllTvProgram().then((res) => {
                    setRadioProgramsFromServer(modifiedData(res.data))
                }
                );
                setIsOpen(false);
                setAnchorEl(null);
                setIsEditMode(false);
            }
        }
        );
    };
    const timeFormat = (dateString) => {
        if (typeof dateString === 'string') {
            return dateString;
        }
        const date = new Date(dateString);

        // Get hours and minutes
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Format to ensure two digits for hours and minutes
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        // Return time in the desired format
        return `${formattedHours}:${formattedMinutes}`;
    };

    const handleEdit = () => {
        const isValid = validateForm();
        if (isValid) {
            tvService.updateTvProgram(
                {
                    id: editedContent.id,
                    title: editedContent.title,
                    description: editedContent.description === "" ? " " : editedContent.description,
                    days: editedContent.days,
                    date_start: timeFormat(editedContent.start),
                    date_end: timeFormat(editedContent.end),
                }
            ).then((response) => {
                if (response.status === 200) {
                    tvService.getAllTvProgram().then((res) => {
                        setRadioProgramsFromServer(modifiedData(res.data))
                    }
                    );
                    setIsOpen(false);
                    setAnchorEl(null);
                    setIsEditMode(false);

                }
            }
            );

        }
    };
    const validateForm = () => {
        let isValid = true;
        const newFormErrors = {};

        if (!editedContent.title) {
            newFormErrors.title = "Le titre est requis.";
            isValid = false;
        }

        // if (!editedContent.description) {
        //     newFormErrors.description = "La description est requise.";
        //     isValid = false;
        // }

        if (!editedContent.days) {
            newFormErrors.days = "Le jour est requis.";
            isValid = false;
        }

        if (!editedContent.start) {
            newFormErrors.start = "L'heure de début est requise.";
            isValid = false;
        } else if (!editedContent.end) {
            newFormErrors.end = "L'heure de fin est requise.";
            isValid = false;
        } else {
            const startTime = new Date(`2024-01-01 ${formatTime(editedContent.start)}`);
            const endTime = new Date(`2024-01-01 ${formatTime(editedContent.end)}`);
            if (startTime >= endTime) {
                newFormErrors.start = "L'heure de début doit être antérieure à l'heure de fin.";
                isValid = false;
            }
        }

        setFormErrors(newFormErrors);
        return isValid;
    };

    const translateDay = (day) => {
        switch (day) {
            case 'monday':
                return 'Lundi';
            case 'tuesday':
                return 'Mardi';
            case 'wednesday':
                return 'Mercredi';
            case 'thursday':
                return 'Jeudi';
            case 'friday':
                return 'Vendredi';
            case 'saturday':
                return 'Samedi';
            case 'sunday':
                return 'Dimanche';
            default:
                return day;
        }
    };
    const formatTime = (date) => {
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

        if (timeRegex.test(date)) {
            return date;
        }
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    return (
        <div>
            <Button onClick={handleClick}><RemoveRedEyeIcon sx={{ color: "white" }} /></Button>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Box p={2} sx={{ width: 550 }}>
                    <DialogTitle>Détails du programme</DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1">
                            {`${start.getHours().toString().padStart(2, '0')}:${start.getMinutes().toString().padStart(2, '0')}- ${end.getHours().toString().padStart(2, '0')}:${end.getMinutes().toString().padStart(2, '0')}`}
                        </Typography>
                        <hr />
                        {isEditMode ? (
                            <>
                                <TextField
                                    label="Titre"
                                    variant="standard"
                                    fullWidth
                                    value={editedContent.title}
                                    onChange={(e) => setEditedContent({ ...editedContent, title: e.target.value })}
                                    error={!!formErrors.title}
                                    helperText={formErrors.title}
                                />
                                <TextField
                                    label="Descripción"
                                    variant="standard"
                                    multiline
                                    rows={1}
                                    fullWidth
                                    sx={{ mb: 2, mt: 2 }}
                                    value={editedContent.description}
                                    onChange={(e) => setEditedContent({ ...editedContent, description: e.target.value })}
                                    error={!!formErrors.description}
                                    helperText={formErrors.description}
                                />
                                <FormControl sx={{ mb: 2 }} fullWidth error={!!formErrors.days}>
                                    <InputLabel>Jour</InputLabel>
                                    <Select
                                        sx={{ mt: 1 }}
                                        name="days"
                                        value={editedContent.days}
                                        onChange={(e) => setEditedContent({ ...editedContent, days: e.target.value })}
                                    >
                                        <MenuItem value="monday">Lundi</MenuItem>
                                        <MenuItem value="tuesday">Mardi</MenuItem>
                                        <MenuItem value="wednesday">Mercredi</MenuItem>
                                        <MenuItem value="thursday">Jeudi</MenuItem>
                                        <MenuItem value="friday">Vendredi</MenuItem>
                                        <MenuItem value="saturday">Samedi</MenuItem>
                                        <MenuItem value="sunday">Dimanche</MenuItem>
                                    </Select>
                                    {formErrors.days && <Typography variant="caption" color="error">{formErrors.days}</Typography>}
                                </FormControl>
                                <TextField
                                    sx={{ mb: 2 }}
                                    label="Heure de début"
                                    type="time"
                                    name="start"
                                    value={formatTime(editedContent.start)}
                                    onChange={(e) => setEditedContent({ ...editedContent, start: e.target.value })}
                                    fullWidth
                                    error={!!formErrors.start}
                                    helperText={formErrors.start}
                                />
                                <TextField
                                    label="Heure de fin"
                                    type="time"
                                    name="end"
                                    value={formatTime(editedContent.end)}
                                    onChange={(e) => setEditedContent({ ...editedContent, end: e.target.value })}
                                    fullWidth
                                    error={!!formErrors.end}
                                    helperText={formErrors.end}
                                />
                            </>
                        ) : (
                            <>
                                <Typography sx={{ fontWeight: "bold" }} variant="body1">{title}</Typography>
                                <Typography variant="body2">{description}</Typography>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {isEditMode && (
                            <Button onClick={handleEditModeToggle}>Retour</Button>
                        )}
                        <Button onClick={handleClose}>Fermer</Button>
                        {!isEditMode && (
                            <Button variant="contained" color="primary" onClick={handleEditModeToggle}>
                                Editer <EditIcon sx={{ ml: 1, mr: -1.5 }} />
                            </Button>
                        )}
                        {isEditMode && (
                            <Button variant="contained" color="primary" onClick={handleEdit}>
                                Enregistrer
                            </Button>
                        )}
                        <Button variant="contained" color="error" onClick={handleDeleteClick}>
                            Supprimer <DeleteIcon sx={{ ml: 1, mr: -1.5 }} />
                        </Button>
                    </DialogActions>
                </Box>
            </Popover>
            <AlertDialog
                open={openDialog}
                handleClose={handleDeleteCancelled}
                handleAgree={() => handleDelete(id)}
                label={"Êtes-vous sûr de vouloir supprimer ce programme?"}
            />
        </div>
    );
};

export default EditModal;
