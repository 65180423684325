import { TextField } from "@mui/material";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import tinymce from 'tinymce';
import mediaService from "../../services/media.service";
import { IMAGE_URL_WYSIWYG } from "../../constants/url";
import compressImageBlob from "../../utils/compressBlobImage";
import { toast } from "react-toastify";


export default function FormAnnexPage({ dataArticle, setDataArticle, canValidate, handleSave }) {

    const handleChangeTitleForm = (e) => {
        setDataArticle({ ...dataArticle, title: e.target.value });
    }
    const handleEditorChange = (content, editor) => {
        setDataArticle({ ...dataArticle, body: content });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: "column" }}>
            <TextField
                label="Titre de la page"
                id="outlined-start-adornment"
                sx={{ backgroundColor: "#F8F8F8", minWidth: 400, maxWidth: 800, mt: 4, mb: 4 }}
                value={dataArticle.title}
                onChange={handleChangeTitleForm}
            />
            <Editor
                tinymceScriptSrc="./tinymce/tinymce.min.js"
                value={dataArticle.body}
                onEditorChange={handleEditorChange}
                // onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                    font_formats:
                        "Raleway=raleway;",
                    height: 500,
                    menubar: true,
                    language: "fr_FR",
                    promotion: false,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "preview",
                        "help",
                        "wordcount",
                    ],
                    file_picker_callback: (cb, value, meta) => {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.addEventListener('change', (e) => {
                            const file = e.target.files[0];
                            const maxSizeAfterCompression = 5 * 1024 * 1024; // 5 MB en bytes
                            compressImageBlob(file).then((file) => {

                                if (file.size > maxSizeAfterCompression) {
                                    toast.error("Votre image est trop lourde meme après compression", {
                                        position: "top-right",
                                        theme: "dark",
                                    });
                                    return;
                                }

                                mediaService.uploadMedia(file)
                                    .then((response) => {
                                        const reader = new FileReader();
                                        reader.addEventListener('load', () => {
                                            const id = 'blobid' + (new Date()).getTime();
                                            const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                            const base64 = reader.result.split(',')[1];
                                            const blobInfo = blobCache.create(id, file, base64);
                                            blobCache.add(blobInfo);
                                            cb(IMAGE_URL_WYSIWYG + response.data.url, { title: file.name });
                                        });
                                        reader.readAsDataURL(file);
                                    });
                            }
                            );
                        });
                        input.click();
                    },
                    toolbar:
                        "undo redo | bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags",

                    content_style: "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap'); body { font-family: Raleway; font-variant-numeric: lining-nums; }; ",
                }}
            />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mt: 4 }}>
                <Button color="secondary" variant="contained" sx={{ mr: 3 }} disabled={!canValidate} onClick={handleSave}>
                    Enregistrer
                </Button>
                <Button color="primary" variant="contained">
                    Annuler
                </Button>
            </Box>
        </Box>
    );
}
