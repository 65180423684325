import React, { useState, useEffect, useRef } from 'react';
import { FastAverageColor } from 'fast-average-color';

const GetColorImage = ({imageUrl, setColor, color}) => {
    const refImage = useRef(null);
    const averageColor = async () => {
        const fac = new FastAverageColor();
        try {
            const colorGen = await fac.getColorAsync(refImage.current);
            if (colorGen) setColor(colorGen.hex);
        } catch (error) {
            if (color === "") {
                setColor("#16324d");
            }
            console.error(error);
        }
    }
    useEffect(() => {
        if (imageUrl && imageUrl !== "") {
            setTimeout(() => {
                averageColor();
            }, 1000);
            averageColor();
        } else {
            setColor("");
        }

        return () => {
            setColor("");
        }
    }, [imageUrl]);
    return (
        <div style={{display:"none"}}>
            <img crossorigin="anonymous" src={`${imageUrl}?dummy=parameter`} ref={refImage}/>
        </div>
    )
}

export default GetColorImage;