import React, {useEffect, useState} from "react";
import UserService from "../services/user.service";
import {useSearchParams, useNavigate} from "react-router-dom";
import {Box, Button, Modal, TextField, Typography} from "@mui/material";
import { modalStyle } from "../constants/modalStyle";
import { formStyle } from "../constants/formStyle";
import {toast} from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";



const UserById = () => {
    const [user, setUser] = useState([]);
    const [getParams] = useSearchParams();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        id: user?.id || '',
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        birthDate: user?.birthDate || '',
        email: user?.email || '',
    });
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };
    const handleSave = (event) => {
        event.preventDefault();
        setIsLoading(true)
        // Send the updated formValues to the backend
        console.log(formValues);
        UserService.updateUser(formValues).then(
            (response) => {
                console.log('dans reponse')
                console.log(response)
                toast.success('Utilisateur modifié avec succès!', {
                    position: "top-right",
                    theme: "dark",
                });
                setIsLoading(false)
            },
            (error) => {
                console.log('dans error')
                console.log(error)
                toast.error('Erreur lors de la modification!', {
                    position: "top-right",
                    theme: "dark",
                });
                setIsLoading(false)
            }
        )
        .catch((error) => console.log(error));
    };

    const handleDelete = (event) => {
        event.preventDefault();
        if (user?.id) {
            UserService.deleteUserById(user?.id).then(
                (response) => {
                    console.log('dans reponse')
                    console.log(response)
                    toast.success('Utilisateur supprimé avec succès!', {
                        position: "top-right",
                        theme: "dark",
                    });
                    handleClose()
                    navigate('/')

                },
                (error) => {
                    console.log('dans error')
                    console.log(error)
                    toast.error('Erreur lors de la suppression!', {
                        position: "top-right",
                        theme: "dark",
                    });
                    handleClose()
                }
            )
            .catch((error) => console.log(error));
        }
    }

    useEffect(() => {
        const idUser = getParams.get('id');
        if (idUser !== undefined && idUser !== null && idUser !== "0") {
            UserService.getUserById(idUser).then(
                (response) => {
                    console.log('dans le réponse ')
                    console.log(response.data)
                    if (response.data !== undefined && response.data != null) {
                        setUser(response.data)
                    }
                },
                (error) => {
                    console.log('dans l error ')
                }
            )
            .catch((error) => console.log(error));
        }

    }, [getParams]);

    useEffect(() => {
        setFormValues({
            id: user?.id || '',
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            birthDate: user?.birthDate || '',
            email: user?.email || '',
        })

    }, [user]);



    useEffect(() => {
        if (formValues.firstName.trim() !== '' && formValues.lastName.trim() !== '' &&
            formValues.birthDate.trim() !== '' && formValues.email.trim() !== '') {
            const isDirty =
                user.firstName !== formValues.firstName ||
                user.lastName !== formValues.lastName ||
                user.birthDate !== formValues.birthDate ||
                user.email !== formValues.email;

            setIsFormDirty(isDirty);
        } else {
            setIsFormDirty(false);

        }

    }, [user, formValues]);



    return (
        <div style={{ height: '100%', minWidth: 300 }}>
            <header className="jumbotron mx-5 mt-5">
                <h3>{t("infoUser")}</h3>
                <hr/>
            </header>

            <Box
                className={"container"}
                component="form"
                onSubmit={handleSave}
                noValidate
                autoComplete="off"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={formStyle}>
                    <TextField
                        style={{backgroundColor:"#FCFCFC"}}
                        id="outlined-basic"
                        label="Prénom"
                        name="firstName"
                        required
                        variant="outlined"
                        value={formValues.firstName}
                        onChange={handleInputChange}
                        error={formValues.firstName.trim() === ''} // Vérifie si l'email est vide
                        helperText={formValues.firstName.trim() === '' ? 'Ce champ est requis' : ''}
                    />
                    <TextField
                        style={{backgroundColor:"#FCFCFC"}}
                        id="outlined-basic"
                        label="Nom"
                        variant="outlined"
                        name="lastName"
                        required
                        value={formValues.lastName}
                        onChange={handleInputChange}
                        error={formValues.lastName.trim() === ''} // Vérifie si l'email est vide
                        helperText={formValues.lastName.trim() === '' ? 'Ce champ est requis' : ''}

                    />
                    <TextField
                        style={{backgroundColor:"#FCFCFC"}}
                        id="outlined-basic"
                        label="Date de naissance"
                        variant="outlined"
                        name="birthDate"
                        required
                        value={formValues.birthDate}
                        onChange={handleInputChange}
                        error={formValues.birthDate.trim() === ''} // Vérifie si l'email est vide
                        helperText={formValues.birthDate.trim() === '' ? 'Ce champ est requis' : ''}

                    />
                    <TextField
                        style={{backgroundColor:"#FCFCFC"}}
                        id="outlined-basic"
                        type="email"
                        required
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={formValues.email}
                        onChange={handleInputChange}
                        error={formValues.email.trim() === ''} // Vérifie si l'email est vide
                        helperText={formValues.email.trim() === '' ? 'Ce champ est requis' : ''}

                    />
                    <TextField
                        style={{backgroundColor:"#FCFCFC"}}
                        id="outlined-basic"
                        disabled
                        label="Créé le"
                        variant="outlined"
                        value={user?.createdAt || ''}
                    />
                    <TextField
                        style={{backgroundColor:"#FCFCFC"}}
                        disabled
                        id="outlined-basic"
                        label="CGU"
                        variant="outlined"
                        value={user?.cgu ? 'Oui' : 'Non' || ''}
                    />
                    <TextField
                        style={{backgroundColor:"#FCFCFC"}}
                        disabled
                        id="outlined-basic"
                        label="Politique de Conf"
                        variant="outlined"
                        value={user?.privacyPolicy ? 'Oui' : 'Non' || ''}
                    />
                    <TextField
                        style={{backgroundColor:"#FCFCFC"}}
                        disabled
                        id="outlined-basic"
                        label="Email Conf"
                        variant="outlined"
                        value={user?.linkConfirmationEmail ? 'Non' : 'Oui' || ''}
                    />
                    {user?.linkConfirmationEmail &&
                    <TextField
                        style={{backgroundColor:"#FCFCFC"}}
                        id="outlined-basic"
                        label="Email Conf"
                        variant="outlined"
                        sx={{
                            minWidth: 600
                        }}
                        value={user?.linkConfirmationEmail || ''}
                    />

                    }


                    <Button
                        type={"button"}
                        variant="contained"
                        color="error"
                        onClick={handleOpen}
                        sx={{
                            width: 200,
                            marginTop: 5
                        }}
                    >
                        Supprimer
                    </Button>
                    {!isLoading ?
                        <Button
                            type={"submit"}
                            variant="contained"
                            disabled={!isFormDirty}
                            color="primary"
                            sx={{
                                width: 200,
                                marginTop: 5
                            }}
                        >
                            Enregistrer
                        </Button>
                        :
                        <LoadingButton
                            loading
                            variant="contained"
                            sx={{
                                width: 200,
                                marginTop: 5
                            }}
                        >
                            Submit
                        </LoadingButton>
                    }


                </Box>

            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Suppression Utilisateur
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Êtes vous sur de vouloir supprimer :
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {formValues?.firstName || ''} {formValues?.lastName || ''}
                    </Typography>
                    <Box style={{display:'flex', justifyContent:"space-around", marginTop:20}}>
                        <Button
                            type={"button"}
                            sx={{bgcolor: "grey", color:"white"}}
                            onClick={handleClose}
                        >
                            Annuler

                        </Button>
                        <Button
                            type={"button"}
                            sx={{bgcolor: "red", color:"white"}}
                            onClick={handleDelete}
                        >
                            Supprimer

                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}

export default UserById