import React from 'react';
import { Select, MenuItem, FormControlLabel, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

const SelectTheme = ({ options, label, propertyName, marginLeft = 0, mLeftSelect = 2, dataArticle, setDataArticle, }) => {

    const handleChange = (event) => {
        const newValue = event.target.value;
        setDataArticle({ ...dataArticle, [propertyName]: [newValue] });
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const caculateWidth = Math.max(270 - (1920 - windowWidth) * 0.3, 0);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const optionsItems = options?.map((option, index) => (
        <MenuItem key={index} value={option.id}>
            {option.name}
        </MenuItem>
    ));

    return (
        <FormControlLabel
            sx={{ marginRight: 3, marginLeft: marginLeft, color: 'black', marginTop: 3 }}
            control={
                <Select
                    style={{ width: `${caculateWidth}px`, maxWidth: '100%' }}
                    sx={{ marginLeft: mLeftSelect, height: 32 }}
                    value={dataArticle[propertyName]}
                    onChange={handleChange}
                >
                    {optionsItems}
                </Select>
            }
            label={<Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>{label}</Typography>}
            labelPlacement="start"
        />
    );
};

export default SelectTheme;
