import React from 'react';
import Menu from "../../components/layout/Menu";
import CropperImageComponent from './components/CropperImageComponent';
import instance from "../../utils/axios";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import mediaService from '../../services/media.service';
import Loader from "../../components/Loader";


const MediasCreate = ({ openMedias = false, handleCloseCroppImage, setInfoCreate, infoCreate, dataArticle, setDataArticle, isCreate = false }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const sendFinalImageWithFormData = (formData) => {
        setLoading(true);

        mediaService.uploadMultipleMedia(formData)
            .then((res) => {
                if (openMedias) {
                    handleCloseCroppImage({ openMediapicker: false });
                    const mapMedia = res.data.map((media) => media.id);


                    setDataArticle({
                        ...dataArticle,
                        media_id: [...dataArticle.media_id, ...mapMedia]
                    });
                    instance.get("articles/info-create").then((response) => {
                        if (response.data) {
                            if (response && response.status === 200 && response.data && response.data.tags && response.data.themes) {

                                setLoading(false);
                            }

                            setInfoCreate({
                                ...infoCreate,
                                tags: response.data.tags,
                                media: response.data.media,
                                themes: response.data.themes,
                            });
                        }
                    })
                }
                else {
                    setLoading(false);
                    navigate('/medias')
                }

            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    const { id } = useParams();

    let breadcrumbArray = [
        { name: "Bibliothèque", to: "/medias" },
        { name: "Nouvelle image", to: "/medias/create" },

    ];
    if (openMedias && !isCreate) {
        breadcrumbArray = [
            { name: "Articles", to: "/articles" },
            { name: "Edition article", to: `/articles/${id}` },
            { name: "Nouvelle image", to: `/articles/${id}` },

        ];
    } else {
        breadcrumbArray = [
            { name: "Articles", to: "/articles" },

        ];
    }

    return (
        <Menu category="Éditeur d'image" breadCrumb={breadcrumbArray}>
            {loading && <Loader />}
            <CropperImageComponent handleCloseCroppImage={handleCloseCroppImage} openMedias={openMedias} canImportImage={true} imageSrcComponent={null} themeIdComponent={[]} tagIdComponent={[]} finishCropImage={sendFinalImageWithFormData} />
        </Menu>
    )
}

export default MediasCreate;