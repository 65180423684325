import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import { Icon } from "leaflet";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';


const NominatimMap = ({ dataArticle, setDataArticle }) => {
    const [errorMsg, setErrorMsg] = useState("Pas d'adresse saisies.");
    const [loading, setLoading] = useState(false);

    const handleAddressChange = (event) => {
        setDataArticle({ ...dataArticle, address: event.target.value });
    };
    const icon = new Icon({
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        iconSize: [25, 41],

    })
    useEffect(() => {
        if (dataArticle.longitude !== "" && dataArticle.latitude !== "") {
            handleSearch();
        }
    }, []);

    const handleSearch = async () => {
        try {
            setLoading(true);
            setDataArticle({ ...dataArticle, longitude: "", latitude: "" });
            const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${dataArticle.address}&format=json&limit=1`);
            if (response.data && response.data.length > 0) {
                const { lat, lon } = response.data[0];
                setDataArticle({ ...dataArticle, longitude: lon, latitude: lat, address: response.data[0].display_name })
                setErrorMsg('');
                setLoading(false);
            } else {
                setLoading(false);
                setErrorMsg("Aucune coordonnée trouvée pour cette adresse.");
            }
        } catch (error) {
            setLoading(false);
            console.error('Erreur lors de la recherche de l\'adresse :', error);
            setErrorMsg("Une erreur s'est produite lors de la recherche de l'adresse.");
        }
    };

    return (
        <Box>
            <Typography
                variant="h6"
                sx={{ marginBottom: 2, fontWeight: 'bold' }}
            >Ajouter une Adresse</Typography>
            <TextField
                label="Adresse"
                variant="outlined"
                value={dataArticle.address}
                onChange={handleAddressChange}
                sx={{ width: '100%', marginBottom: 1 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" onClick={handleSearch}>
                    Rechercher
                    <AddLocationAltIcon sx={{ marginLeft: 2 }} />
                </Button>
            </Box>
            {dataArticle.longitude !== "" && dataArticle.latitude !== "" ? (
                <MapContainer center={[dataArticle.latitude, dataArticle.longitude]} zoom={13} style={{ height: '250px', width: '100%' }}>
                    <TileLayer
                        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[dataArticle.latitude, dataArticle.longitude]} icon={icon}>
                        <Popup>{dataArticle.address}</Popup>
                    </Marker>
                </MapContainer>
            ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    {loading ? (
                        <>
                            <div>
                                <div>
                                    <Typography>Recherche en cours...</Typography>
                                </div>
                                <div className='ml-12 mt-3'>
                                    <CircularProgress />
                                </div>
                            </div>
                        </>
                    )
                        : (<Typography>{errorMsg}</Typography>)}

                </Box>
            )}
        </Box>
    );
};

export default NominatimMap;