import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MultiselectCityAndTheme from '../../components/dashboard/MultiselectCityAndTheme';
import { Button } from '@mui/material';
import TVFluxManagement from "../../components/dashboard/TVFluxManagement";
import Gamecontests from "../../components/dashboard/GameContest";
import LiveShowForm from "../../components/dashboard/LiveYoutubeUrl";
import { MARITIMMA_NEWSLETTER } from "../../constants/url";
import { useTheme, useMediaQuery } from "@mui/material";
import { useState } from 'react';

const AdminBoard = ({
    themes,
    villes,
    selectedTheme,
    setSelectedTheme,
    selectedVille,
    setSelectedVille,
    themesChange,
    citysChange,
    handleUpdateThemeAndCity,
    infoCreate,
    setInfoCreate,
    caculateWidth
}) => {
    // Responsive 

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [liveYoutubeHaveItems, setLiveYoutubeHaveItems] = useState(false);
    // 
    return (
        <Box sx={{ display: isSmallScreen ? "block" : "flex" }}>
            <Box>
                <Paper
                    variant="outlined"
                    style={{
                        borderRadius: "10px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        padding: "8px",
                        paddingLeft: "15px",
                        height: isSmallScreen ? "13rem" : "12.2rem",
                        overflow: "auto",
                        overflowY: "scroll",
                        width: isSmallScreen ? "21rem" : caculateWidth,
                        // marginLeft: "5rem",
                    }}
                >
                    <Typography
                        variant="caption"
                        style={{ fontWeight: "bold", fontSize: "18px", marginRight: -20, color: "#16324D" }}
                    >
                        Thèmes et villes à la une
                    </Typography>
                    <MultiselectCityAndTheme
                        options={themes}
                        selectedOptions={selectedTheme}
                        setSelectedOptions={setSelectedTheme}
                        marginRight={-20}
                        label={"Thèmes"}
                    />
                    <MultiselectCityAndTheme
                        options={villes}
                        selectedOptions={selectedVille}
                        setSelectedOptions={setSelectedVille}
                        label={"Villes"}
                    />
                    <Button
                        sx={{ mt: 2, justifyContent: "end" }}
                        disabled={themesChange && citysChange}
                        variant="contained"
                        color="secondary"
                        onClick={handleUpdateThemeAndCity}
                    >
                        Sauvegarder
                    </Button>
                </Paper>
                <Paper
                    variant="outlined"
                    style={{
                        borderRadius: "10px",
                        padding: "8px",
                        paddingLeft: "15px",
                        height: liveYoutubeHaveItems ? "8.2rem" : "18.2rem",
                        overflow: "auto",
                        overflowY: "scroll",
                        width: isSmallScreen ? "21rem" : caculateWidth,
                        marginTop: "1rem",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
                        // marginLeft: "5rem",
                    }}
                >
                    <Typography
                        variant="caption"
                        style={{ fontWeight: "bold", fontSize: "18px", marginRight: -20, color: "#16324D" }}
                    >
                        Live Youtube Maritima
                    </Typography>
                    <LiveShowForm setLiveYoutubeHaveItems={setLiveYoutubeHaveItems} liveYoutubeHaveItems={liveYoutubeHaveItems} />
                </Paper>
                <Paper
                    variant="outlined"
                    style={{
                        borderRadius: "10px",
                        padding: "8px",
                        paddingLeft: "15px",
                        height: "7.2rem",
                        overflow: "auto",
                        overflowY: "scroll",
                        width: isSmallScreen ? "21rem" : caculateWidth,
                        marginTop: "1rem",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
                        // marginLeft: "5rem",
                    }}
                >
                    <Box>
                        <Typography
                            variant="caption"
                            style={{ fontWeight: "bold", fontSize: "18px", marginRight: -20, color: "#16324D" }}
                        >
                            Newsletter Maritima
                        </Typography>


                    </Box>
                    <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="secondary"
                        onClick={() => window.open(MARITIMMA_NEWSLETTER + "newsletter", '_blank')}
                    >
                        Aperçu Newsletter
                    </Button>

                </Paper>
            </Box>

            <Box>
                <Paper

                    variant="outlined"
                    style={{
                        borderRadius: "10px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        padding: "8px",
                        paddingLeft: "15px",
                        height: "40rem",
                        overflow: "auto",
                        overflowY: "scroll",
                        width: isSmallScreen ? "21rem" : caculateWidth,
                        marginLeft: isSmallScreen ? "0rem" : "3rem",
                        marginTop: isSmallScreen ? "1.2rem" : "0rem",

                    }}
                >
                    <Gamecontests infoCreate={infoCreate} setInfoCreate={setInfoCreate} />
                </Paper>
            </Box>
            <Box>
                <Paper
                    variant="outlined"
                    style={{
                        borderRadius: "10px",
                        padding: "8px",
                        paddingLeft: "15px",
                        height: "24rem",
                        overflow: "auto",
                        overflowY: "scroll",
                        width: isSmallScreen ? "21rem" : caculateWidth,
                        marginLeft: isSmallScreen ? "0rem" : "3rem",
                        marginTop: isSmallScreen ? "1.2rem" : "0rem",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
                    }}
                >
                    <TVFluxManagement />
                </Paper>
            </Box>

        </Box>
    )
}

export default AdminBoard