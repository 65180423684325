import React from 'react';
import { Box, Typography } from '@mui/material';

const CustomInlineComponent = ({ textLeft, textRight }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '15px',
            }}
        >
            <Typography
                style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: '#9298A9',
                }}
            >
                {textLeft}
            </Typography>
            <Typography
                style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: '#03A9F4',
                }}
            >
                {textRight}
            </Typography>
        </Box>
    );
};

export default CustomInlineComponent;
