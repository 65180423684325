export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'black',
    color: "white",
    border: '2px solid #666',
    boxShadow: 24,
    p: 4,
    borderRadius: 3
};