import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MenuItem from '@mui/material/MenuItem';
import {
    Box,
    FormControlLabel,
    FormGroup,
    Switch,
} from "@mui/material";
import { styled } from '@mui/material/styles';


const BriefForm = ({ setBriefs, briefs, setDataArticle, dataArticle, handleChangeSwitch }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleTitleChange = (index, value) => {
        const newArticles = [...briefs];
        newArticles[index].title = value;
        setBriefs(newArticles);
        setDataArticle({ ...dataArticle, briefs: newArticles });
    };

    const handleDescriptionChange = (index, value) => {
        const newArticles = [...briefs];
        newArticles[index].description = value;
        setBriefs(newArticles);
        setDataArticle({ ...dataArticle, briefs: newArticles });
    };

    const createArticle = () => {
        setBriefs([...briefs, { title: '', description: '' }]);
    };
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 38,
        height: 20,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#03A9F4',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 17,
            height: 16,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#39393D' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedIndex(null);
    };

    const handleDelete = () => {
        const newArticles = [...briefs];
        newArticles.splice(selectedIndex, 1);
        setBriefs(newArticles);
        setDataArticle({ ...dataArticle, briefs: newArticles });
        handleClose();
    };

    return (
        <>
            <Button
                variant="contained"
                sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    backgroundColor: '#9298A9',
                    color: 'white',
                    height: '50px',
                    justifyContent: 'start',
                    borderRadius: '10px',
                }}
                onClick={createArticle}
            >
                <AddCircleIcon /> Ajouter une brève
            </Button>
            <FormControlLabel setDataArticle={setDataArticle} control={<IOSSwitch checked={dataArticle.notification} onChange={((e) => handleChangeSwitch(e, "notification"))} sx={{ marginRight: 5, marginLeft: 1 }} />} label="Notification Push" />

            <div className="overflow-x-auto scrollbar-y h-[430px] ">
                {briefs.map((brief, index) => (
                    <Paper
                        key={index}
                        variant="outlined"
                        style={{
                            boxShadow: 'none',
                            padding: '8px',
                            paddingLeft: '15px',
                            height: '12.5rem',
                            overflow: 'hidden',
                            width: '100%',
                            marginTop: '7px',
                        }}
                    >
                        <Box sx={{ marginTop: 1 }}>
                            <FormGroup>
                                <>
                                    <div className="relative justify-end flex ">
                                        <button
                                            className='absolute -top-3'
                                            onClick={(event) => handleClick(event, index)}
                                        >
                                            <MoreVertIcon />
                                        </button>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl && selectedIndex === index)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleDelete}> Supprimer {<DeleteOutlineIcon style={{ marginLeft: "8px", color: "red" }} />}</MenuItem>
                                        </Menu>
                                    </div>
                                    <TextField
                                        className={'mb-3 mt-3'}
                                        id={`title-${index}`}
                                        label="Titre"
                                        variant="outlined"
                                        value={brief.title}
                                        onChange={(e) => handleTitleChange(index, e.target.value)}
                                    />
                                    <TextField
                                        className={'mb-2'}
                                        id={`description-${index}`}
                                        label="Texte descriptif / Chapeau"
                                        multiline
                                        rows={2}
                                        value={brief.description}
                                        onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                    />
                                </>
                            </FormGroup>
                        </Box>
                    </Paper>
                ))}
            </div>
        </>
    );
};

export default BriefForm;
