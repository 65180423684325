import instance from "../utils/axios";

class teleTexte {
    constructor() {
        this.instance = instance;
    }

    getAllTeleTexte = async () => {
        return await instance.get("flux-tv/all", {
            withCredentials: true,
        });
    };

    updateTeleTexte = async (data) => {
        console.log(data)
        return await instance.put("flux-tv/update", data, {
            withCredentials: true,
        });
    };

    createTeleTexte = async (data) => {
        return await instance.post(
            "flux-tv/create",
            data, {
            withCredentials: true,
        }
        );
    };

    deleteTeleTexte = async (id) => {
        return await instance.delete(`flux-tv/delete`, {
            withCredentials: true,
            data: { id }
        });
    };
}

const teleTexteService = new teleTexte();

export default teleTexteService;