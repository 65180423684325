import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import YouTubeIcon from '@mui/icons-material/YouTube';
import youtubeService from '../../../services/youtube.service';

const YoutubeMediaUrlUploader = ({ setDataArticle, dataArticle, setListVideoMediaInArticle, onClose, setIsVideoInArticle, setMethodSelected }) => {
    const [inputUrl, setInputUrl] = useState('');
    const [videoId, setVideoId] = useState('');
    const [isValid, setIsValid] = useState(true);

    const handleInputChange = (event) => {
        const url = event.target.value;
        setInputUrl(url);
        validateUrl(url);
        const id = extractYoutubeVideoId(url);
        setVideoId(id);
    };

    const validateUrl = (url) => {
        const youtubeRegex = /^https:\/\/www\.youtube\.com\/watch\?v=/;

        if (url === '') {
            setIsValid(true);  // Restablecer a verdadero si el campo está vacío
        } else if (youtubeRegex.test(url)) {
            try {
                new URL(url);
                setIsValid(true);
            } catch (error) {
                setIsValid(false);
            }
        } else {
            setIsValid(false);
        }
    };

    const extractYoutubeVideoId = (url) => {
        const regex = /[?&]v=([^#&]*)/i;
        const match = url.match(regex);
        return match ? match[1] : '';
    };

    const handleSubmit = () => {
        if (isValid && videoId) {
            youtubeService.createMediaWithUrlId({ url: videoId, type: 'video' }).then((response) => {
                setDataArticle({ ...dataArticle, media_id: dataArticle.media_id.concat(response.data.id) });
                setListVideoMediaInArticle([response.data]);
                setIsVideoInArticle(true);
                setMethodSelected("");

            });
            console.log(`Envoi de l'ID de la vidéo à votre service : ${videoId}`);
        } else {
            console.log('URL non valide');
        }
    };

    return (
        <div style={{ padding: '20px', marginTop: 150 }}>
            <h2>Ajouter une vidéo YouTube via URL</h2>
            <TextField
                id="youtubeUrl"
                label="Entrez l'URL de YouTube"
                variant="outlined"
                fullWidth
                value={inputUrl}
                onChange={handleInputChange}
                placeholder="Exemple : https://www.youtube.com/watch?v=kiu&y&uéy"
                error={!isValid}
                helperText={isValid ? '' : 'URL non valide (doit commencer par https://www.youtube.com/watch?v=)'}
                style={{ marginBottom: '20px' }}
            />

            <Button sx={{ mr: 2 }} variant="contained" color="primary" onClick={handleSubmit} startIcon={<YouTubeIcon />}>
                Envoyer
            </Button>
            <Button variant="contained" color="secondary" onClick={onClose}>
                Fermer
            </Button>

        </div>
    );
};

export default YoutubeMediaUrlUploader;
