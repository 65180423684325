import instance from "../utils/axios";

class gameConstest {
    constructor() {
        this.instance = instance;
    }
    getGameContest = async () => {
        return await instance.get("game-contest/all", {
            withCredentials: true,
        });
    };
    createGameContest = async (data) => {
        return await instance.post(`game-contest/create`, data, {
            withCredentials: true,
        });
    }
    updateGameContest = async (data) => {
        return await instance.put(`game-contest/update`, data, {
            withCredentials: true,
        });
    }
    deleteGameContest = async (id) => {
        return await instance.delete(`game-contest/delete?id=${id}`, {
            withCredentials: true,
        });
    }
    updateOrderGameContest = async (data) => {
        return await instance.post(`game-contest/update-order`, data, {
            withCredentials: true,
        });
    }
}

var gameConstestService = new gameConstest();
export default gameConstestService;
