import instance from "../utils/axios";

class theme {
    constructor() {
        this.instance = instance;
    }
    updateTheme = async (data) => {
        return await instance.put(`themes/update`, data, {
            withCredentials: true,
        });
    }
    getThemes = async () => {
        return await instance.get("themes/all", {
            withCredentials: true,
        });
    };

}

var themeService = new theme();
export default themeService;
