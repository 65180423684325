import React from 'react';
import VideoItem from './VideoItem';

const VideoList = ({ videos, onDelete, onClose }) => {
    return (
        <div>
            {videos.map(video => (
                <VideoItem onClose={onClose} key={video.id} video={video} onDelete={onDelete} />
            ))}
        </div>
    );
};

export default VideoList;
