import React from 'react'
import Menu from "../../components/layout/Menu";
import AgendaListEvents from "./components/TableListAgenda"


const Agenda = () => {
    // const [loading, setLoading] = useState(false);
    const breadcrumbArray = [
        { name: "Tableau de bord", to: "/dashboard" },
        { name: "Agenda", to: "/agenda" },
    ];


    return (
        <Menu category="Agenda Maritima" breadCrumb={breadcrumbArray} link="/agenda/create" buttonText="Crée un événement"  >
            <AgendaListEvents />
        </Menu>


    )
}

export default Agenda;
