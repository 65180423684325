import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Menu from "../../components/layout/Menu";
import Grid from '@mui/material/Unstable_Grid2';
import { StripedDataGrid } from "../../constants/tableStyle";
import { frFR, GridToolbar } from "@mui/x-data-grid";
import Action from "../../components/Action";
import AdminService from "../../services/admin.service";

export default function NewsletterHome() {
    const breadcrumbArray = [
        { name: "Newsletter", to: "/newsletter" },
    ];
    const [users, setUsers] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            // minWidth: 120,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 2,
            // minWidth: 300,
        },
        {
            field: "newsletter",
            headerName: "Abonné",
            flex: 1,
            // width: 100,
            valueGetter: (params) => {
                return params.value ? "Oui" : "Non";
            }
        },
        {
            field: "createdAt",
            headerName: "Date de création",
            flex: 2,
            // minWidth: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const options = {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);
                localDateString = localDateString.replace(
                    /(\w)(\w*)/g,
                    function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase();
                    }
                ); // Up the first letter in Uppercase
                return localDateString;
            },
        },
    ];
    useEffect(() => {
        AdminService.allUsersSubscribedNewsletter()
            .then((res) => {
                if (res.data && res.data.users) {
                    setUsers(res.data.users);
                } else {
                    toast.error("Erreur lors de la récupération des données")
                }
            })
            .catch((err) => {
                toast.error("Erreur lors de la récupération des données")
            })
    }, []);
    return (
        <Menu category="Newsletter" breadCrumb={breadcrumbArray}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StripedDataGrid
                        rows={users}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        className="mx-3"
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Grid>
            </Grid>
        </Menu>
    )
}
