import instance from "../utils/axios";

const getUserBoard = () => {
  return instance.get("admin/all-users", { withCredentials: true });
};

const getUserById = (id) => {
  return instance.get(`admin/user?id=${id}`, { withCredentials: true });
};
const deleteUserById = (id) => {
  return instance.post(
    `admin/delete-user`,
    { userId: id },
    { withCredentials: true }
  );
};

const updateUser = (user) => {
  return instance.post(
    `admin/update-user`,
    { user },
    { withCredentials: true }
  );
};

const UserService = {
  getUserBoard,
  getUserById,
  deleteUserById,
  updateUser,
};

export default UserService;
