import React from 'react'
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';

export const DatePicker = ({ setDataArticle, dataArticle, isPodcastAndTv, propertyName, title }) => {
    const verifyIfDateIsString = (date) => {
        if (typeof date === "string") {
            const originalDate = date;

            // Separate the date and time
            const [datePart, timePart] = originalDate.split(' ');

            // Separate the day, month, and year
            const [day, month, year] = datePart.split('/');

            // Create the new inverted date
            const invertedDate = `${month}/${day}/${year} ${timePart}`;

            // Convert the inverted date to Unix timestamp
            return Math.round(dayjs(invertedDate).unix() * 1000);

        } else {
            // If it's not a string, assume it's already a Unix timestamp and convert to milliseconds

            return Math.round(date * 1000);
        }
    }
    const setValueByPropertyName = () => {
        if (propertyName) {
            return dataArticle?.[propertyName] ? dayjs(verifyIfDateIsString(dataArticle[propertyName])) : dayjs(new Date())
        } else {
            return dataArticle?.publication_date ? dayjs(verifyIfDateIsString(dataArticle.publication_date)) : dayjs(new Date())
        }
    }
    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <InputLabel sx={
                {
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "0px",
                    marginTop: title ? "14px" : "0px",
                    marginRight: title ? "7px" : "0px"
                }
            } >
                {title ? title : "Publier le"}
            </InputLabel>
            <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
                <DateTimePicker
                    slotProps={{ textField: { size: 'small' } }}
                    required
                    // defaultValue={dataArticle?.publication_date ? dayjs(verifyIfDateIsString(dataArticle.publication_date)) : dayjs(new Date())}
                    value={setValueByPropertyName()}

                    onChange={(newValue) => {
                        // convert dayjs object to timestamp
                        // console.log(newValue);
                        // console.log(newValue.toDate().getTime() / 1000);
                        if (propertyName) {
                            setDataArticle({
                                ...dataArticle,
                                [propertyName]:
                                    Math.round(newValue.toDate().getTime() / 1000)
                                ,
                            });

                        } else {
                            setDataArticle({
                                ...dataArticle,
                                publication_date:
                                    Math.round(newValue.toDate().getTime() / 1000)
                                ,
                            });
                        }

                    }}
                    // fr locale will be used
                    renderInput={(params) => <TextField {...params} />}

                />

            </DemoContainer>
        </LocalizationProvider>
    )
}
