import React from 'react';

const NumberInput = ({ label, value, onChange, dataArticle }) => {
    const handleChange = (event) => {
        // Filtre la valeur pour autoriser uniquement les nombres
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        onChange({ ...dataArticle, time_to_read: inputValue });
    };

    // Génère des options de 1 à 10 (vous pouvez ajuster la plage selon vos besoins)
    const numberOptions = Array.from({ length: 60 }, (_, index) => index + 1);

    return (
        <div className='mt-2'>
            <label className='mr-4 font-bold'>{label}</label>
            <select value={value} onChange={handleChange}>
                <option value={0}>Sélectionnez...</option>
                {numberOptions.map((number) => (
                    <option key={number} value={number}>
                        {number}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default NumberInput;
