import React, { useEffect, useState } from 'react';
import Menu from "../../components/layout/Menu";
import { Grid } from "@mui/material";
import mediaService from "../../services/media.service";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from '../../assets/Logo MARITIMA-horizontal-Blanc.png';
import "../../App.css";
import { Link } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Loader from "./../../components/Loader";
import { toast } from "react-toastify";


const MediasLibrary = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleClickTheme = (id) => {
        navigate(`/medias/theme/${id}`);
    }

    useEffect(() => {
        setLoading(true);
        mediaService.mediaWithTag()
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    if (res.data && res.data.length > 0) {
                        setData(res.data);

                    } else {
                        toast.error("Pas d'image pour le moment", {
                            position: "top-right",
                            theme: "dark",
                        })
                    }
                } else {
                    toast.error("Une erreur est survenue", {
                        position: "top-right",
                        theme: "dark",
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                toast.error("Une erreur est survenue", {
                    position: "top-right",
                    theme: "dark",
                })
                setLoading(false);
            });
    }, [])
    const breadcrumbArray = [
        { name: "Bibliothèque", to: "/medias" },
    ];

    return (
        <Menu category="Bibliothèque d'images" breadCrumb={breadcrumbArray}>
            {loading && <Loader />}
            <Grid container sx={{ display: 'flex', marginLeft: "3rem", width: "unset" }}>
                {data.map((item, index) => (
                    <Grid key={index} item xs={3}>
                        <Box sx={{ maxWidth: "90%", height: "200px", overflow: "hidden", borderRadius: 2, backgroundColor: "#16324D", position: "relative", marginTop: 5 }}>
                            <img
                                src={logo}
                                alt={item.name}
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            />
                            <Typography
                                className={"watermark"}
                                variant="body2"
                                onClick={() => handleClickTheme(item.id)}
                            >
                                <Box sx={{ position: "absolute", bottom: 0, left: 0, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff" }}>
                                    {item.name}
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Menu>
    )
}

export default MediasLibrary;