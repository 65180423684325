const arrayOfSizesC4 = [
    {
        position: "1",
        title: `Image carrée 1 (radio/TV/recherche) (250x250)`,
        width: 250,
        height: 250,
        link: '',
        media: null,
        url_image: null

    },
    {
        position: "2",
        title: `Image carrée 2 (radio/TV/recherche) (250x250)`,
        width: 250,
        height: 250,
        link: '',
        media: null,
        url_image: null
    },
    {
        position: "3",
        title: `Image carrée 3 (radio/TV/recherche) (250x250)`,
        width: 250,
        height: 250,
        link: '',
        media: null,
        url_image: null
    },
    {
        position: "4",
        title: `Image carrée 4 (radio/TV/recherche) (250x250)`,
        width: 250,
        height: 250,
        link: '',
        media: null,
        url_image: null
    },
]

const arrayOfSizesB5 = [
    {
        title: `Bannière horizontale web (home (x2)/radio/TV/podcast)(970x250)`,
        width: 970,
        height: 250,
        link: '',
        media: null,
        url_image: null

    },
    {
        title: `Bannière horizontale responsive/app (640x200)`,
        width: 640,
        height: 200,
        link: '',
        media: null,
        url_image: null
    },
    {
        title: `Bannière horizontale (TV) (320x100)`,
        width: 320,
        height: 100,
        link: '',
        media: null,
        url_image: null
    },
    {
        title: `Bannière verticale (fil info / recherche) (300x600)`,
        width: 300,
        height: 600,
        link: '',
        media: null,
        url_image: null
    },
    {
        title: `Bannière verticale (article) (160x600)`,
        width: 160,
        height: 600,
        link: '',
        media: null,
        url_image: null
    }
]

const arrayOfSizesAC3 = [
    {
        title: `Arche (horizontale) (3840x526)`,
        width: 3840,
        height: 526,
        link: '',
        media: null,
        url_image: null,
        archH: true,
    },
    {
        title: `Arche colonne droite (384x582)`,
        width: 384,
        height: 582,
        link: '',
        media: null,
        url_image: null,
        archR: true
    },
    {
        title: `Arche colonne gauche (384x582)`,
        width: 384,
        height: 582,
        link: '',
        media: null,
        url_image: null,
        archL: true
    }
]

export { arrayOfSizesC4, arrayOfSizesB5, arrayOfSizesAC3 }