import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from "@mui/material/CircularProgress";
import { Container, Button } from "@mui/material";



const CustomModalLoad = ({ open, onClose, title, description, isLoading, messageLoading, cancelUpload }) => {
  useEffect(() => {
    let timeoutId;
    ;
    if (isLoading === false) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoading, onClose]);


  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        {isLoading ? (
          <>
            <Container className="flex justify-content-center">
              <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} >
                <span className='font-bold text-red-600 '>Attention :</span> {messageLoading}
              </Typography>
              <CircularProgress className="mx-5 mt-5" color="secondary" />

            </Container>
            <Button onClick={cancelUpload} color="primary" variant="contained" sx={{ mr: 2, mt: 7 }}>
              Annuler
            </Button>
          </>
        ) : (
          <>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 40, mb: 2 }} />
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} >
              {title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {description}
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CustomModalLoad;
