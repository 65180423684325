import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ToastContainer, toast } from 'react-toastify';
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { UserConsumer } from "./context/UserContext";
import "react-big-calendar/lib/css/react-big-calendar.css";
const theme = createTheme({
  typography: {
    fontFamily: "Raleway",
  },
  palette: {
    primary: {
      main: "#666",
    },
    secondary: {
      main: "#0057BA",
      light: "#03A9F4",
    },
    greyButton: {
      main: "#9298A9",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserConsumer>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
          <ToastContainer />
        </ThemeProvider>
      </BrowserRouter>
    </UserConsumer>
  </React.StrictMode>
);

reportWebVitals();
