import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ConfirmDeleteModal = ({ open, onClose, onConfirm, text }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center', // Centrar el contenido
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
          Confirmer la suppression
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          {text
            ? text
            : 'Êtes-vous sûr de vouloir supprimer cet élément ?'}

        </Typography>
        <Button onClick={onClose} color="primary" variant="contained" sx={{ mr: 2 }}>
          Annuler
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Supprimer
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmDeleteModal;
