import React, { useEffect, useState } from "react";
import {toast} from "react-toastify";
import Menu from "../../components/layout/Menu";
import FormAdmin from "./Admin.form";
import AdminService from "../../services/admin.service";

export default function CreateAdmin() {
    const breadcrumbArray = [
        { name: "Liste des utilisateurs", to: "/admin" },
        { name: "Création", to: `/admin/create` },
    ];
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [author, setAuthor] = useState(false);
    const [proofreader, setProofreader] = useState(false);
    const [password, setPassword] = useState("");
    const [pictureId, setPictureId] = useState(null);
    const [pictureUrl, setPictureUrl] = useState('');
    const [buttonState, setButtonState] = useState(true);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleUpdate = (event) => {
        //call api
        event.preventDefault();
        setLoading(true)
        AdminService.registerAdmin({account: email, name, is_author: author, is_proofreader: proofreader, password, picture_id:pictureId})
            .then((response) => {
                setLoading(false)
                setButtonState(true)
                if (response.data) {
                    toast.success("Utilisateur créé avec succès", {
                        position: "top-right",
                        theme: "dark",
                    })
                } else {
                    toast.error("Erreur lors de la création de l'utilisateur", {
                        position: "top-right",
                        theme: "dark",
                    })
                }
            })
            .catch((error) => {
                setLoading(false)
                setButtonState(true)
                toast.error(error.response?.data?.message === "Email already exist" ? "Erreur lors de la création de l'utilisateur : Un compte existe déjà avec cet email" : "Erreur lors de la création de l'utilisateur", {
                    position: "top-right",
                    theme: "dark",
                })
            })
    }
    useEffect(() => {
        if (email && email.length > 3 && name && name.length > 3  && (password.length > 7)) {
            if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setButtonState(true)
                setEmailError(true)
            } else {
                setButtonState(false)
                setEmailError(false)
            }
        } else {
            setButtonState(true)
        }
    }, [email, name, author, proofreader, password, pictureId])

    return (
        <Menu category="Utilisateurs" breadCrumb={breadcrumbArray}>
            <FormAdmin name={name} setName={setName} email={email} setEmail={setEmail} password={password} setPassword={setPassword} pictureId={pictureId} setPictureId={setPictureId} author={author} setAuthor={setAuthor} proofreader={proofreader} setProofreader={setProofreader} buttonName={"Créer"} buttonState={buttonState} handleUpdate={handleUpdate} pictureUrl={pictureUrl} setPictureUrl={setPictureUrl} emailError={emailError} loading={loading} setLoading={setLoading} />
        </Menu>
    )
}