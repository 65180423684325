import React, { useEffect } from 'react'
import Menu from '../../components/layout/Menu'
import { Grid, Container, CircularProgress } from '@mui/material'
import { StripedDataGrid } from '../../constants/tableStyle'
import { frFR, GridToolbar } from '@mui/x-data-grid'
import AlertDialog from '../../components/Alert'
import Action from '../../components/Action'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import tvService from "../../services/tv.services";
import createColumnsTV from './columsForListTv'
import podcastService from "../../services/podcast.services";


export const TvAnsPodcastTableList = () => {

    const { podcastGroupId } = useParams(); // Récupère l'ID de l'URL
    const { tvGroupId } = useParams(); // Récupère l'ID de l'URL
    const linkMenuCreate = podcastGroupId ? `/podcast/${podcastGroupId}/create` : `/tv/${tvGroupId}/create`;
    const category = podcastGroupId ? "Podcast" : "TV";
    const buttonText = podcastGroupId ? "Créer un Podcast" : "Créer une TV";
    const [shows, setShows] = useState([]);
    const [loader, setLoader] = useState(false);

    //breadCrumb 
    const findShowNameById = (id) => {
        const show = shows.find((show) => show.id === Number(id));
        if (tvGroupId) {
            return show ? show.title : "";
        } else {
            return show ? show.name : "";
        }
    };

    let breadcrumbArray = [
        { name: "Tableau de bord", to: "/dashboard" },
        { name: "Podcast", to: "/podcast" },
        { name: `${findShowNameById(podcastGroupId)} `, to: "/podcast:id" },
    ];
    if (tvGroupId) {
        breadcrumbArray = [
            { name: "Tableau de bord", to: "/dashboard" },
            { name: "TV", to: "/tv" },
            { name: `${findShowNameById(tvGroupId)}`, to: "/tv:id" },
        ];
    }

    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (tvGroupId) {
            setLoader(true);
            tvService.getAllTvReplayByTvShow(tvGroupId).then((response) => {
                setRows(response.data);
                setLoader(false);

            });
            tvService.getTvShow().then((response) => {
                if (response.data) {
                    setShows(
                        response.data
                    )
                }
            })
        }
        if (podcastGroupId) {
            setLoader(true);
            podcastService.getAllPodcastByShow(podcastGroupId).then((response) => {
                setRows(response.data);
                setLoader(false);
            });
            podcastService.getPodcastShows().then((response) => {
                if (response.data) {
                    setShows(
                        response.data
                    )
                }
            })
        }
    }, []);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const editLink = podcastGroupId ? `/podcast/edit/${podcastGroupId}` : `/tv/edit/${tvGroupId}`;



    const columnsPodcast = [
        {
            field: "actions",
            headerName: "#",
            width: 130,
            renderCell: (params) => {

                return (
                    <Action
                        // notificationActivate={true}
                        editActivate={true}
                        setRows={setRows}
                        rows={rows}
                        editLink={`${editLink}/${params.id}`}
                        idArticle={params.id}
                        notifications={0}
                        label="Êtes-vous sûr de vouloir supprimer cet article ?"
                    />
                );
            },
        },
        {
            field: "title",
            headerName: "Titre",
            width: 380,
            hideable: false
        },
        {
            field: "total_time",
            headerName: "Durée",
            width: 250,
            valueGetter: (params) => params.row.total_time
        },
        {
            field: "is_active",
            headerName: "Afficher",
            width: 300,
            valueGetter: (params) => params.row.is_active ? "Oui" : "Non"
        },
        {
            field: "delete",
            headerName: "",
            width: 70,
            renderCell: (params) => {
                return (
                    <Action
                        trashIcon={true}
                        deleteAction={() => handleDeleteArticle(params.id)}// to finish when ws is finish

                    />
                );
            },
        },

    ];
    const handleDeleteArticle = (id) => {
        if (category === "TV") {
            tvService.deleteTvReplay(id)
                .then((res) => {
                    if (res.status === 200) {
                        setRows(rows.filter((row) => row.id !== id));
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }

        if (category === "Podcast") {
            podcastService.deletePodcast(id)
                .then((res) => {
                    if (res.status === 200) {
                        setRows(rows.filter((row) => row.id !== id));
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const columnsTV = createColumnsTV({ setRows, rows, editLink, handleDeleteArticle });

    return (
        <Menu category={`${category} : ${findShowNameById(podcastGroupId ? podcastGroupId : tvGroupId)}`} breadCrumb={breadcrumbArray} link={linkMenuCreate} buttonText={buttonText}>
            <AlertDialog title="Supression d'articles" />
            {loader ? (
                <Container className="d-flex justify-content-center">
                    <CircularProgress className="mx-5" color="inherit" />
                </Container>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StripedDataGrid
                            style={{ maxWidth: "100%" }}
                            rows={rows}
                            columns={tvGroupId ? columnsTV : columnsPodcast}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            className="mx-3"
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Grid>
                </Grid>

            )}
        </Menu>
    )
}
