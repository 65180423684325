import React, {useState} from "react";
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {TextField} from "@mui/material";


export default function FormModal({ open, handleClose, handleAgree, title, buttonAccept, buttonCancel, rows = [], handleOpenModalMedia = {} }) {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    padding: 50,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: "10px",
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} sx={{textAlign:"center"}}>
                    {title ? title : "Confirmer la suppression"}
                </Typography>
                {/*check if rows is an array and length superior at zero*/}
                {Array.isArray(rows) && rows.length > 0 && rows.map((row, index) => {
                    if (row.type === "text") {
                        return (
                            <Box sx={{display:"flex", flexDirection:"column", mb:2}}>
                                <Typography key={index} id="modal-modal-description" sx={{ mt: 2, mb: 0 }}>
                                    {row.label ? row.label : ""}
                                </Typography>
                                <TextField
                                    value={row.value}
                                    onChange={row.onChange}
                                    fullWidth
                                    disabled={row.disabled}
                                    variant="outlined"
                                    sx={{ marginTop: "3px", marginBottom: "10px" }}
                                />
                            </Box>
                        )
                    } else if (row.type === "img") {
                        return (
                            <Box sx={{display:"flex", alignItems:"center", justifyContent: "space-around"}}>
                                <img style={{width: "200px", height: "200px", borderRadius: "10px", objectFit: "contain"}} key={index} src={row.url} alt={row.label} />
                                <Button variant="contained" color="secondary" onClick={handleOpenModalMedia}>Modifier image</Button>
                            </Box>
                        )
                    }
                })}
                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", mt: 5}}>
                    <Button onClick={handleClose} color={buttonCancel ? "error" : "primary"} variant="contained" sx={{ mr: 2 }}>
                        {buttonCancel ? buttonCancel : "Annuler"}
                    </Button>
                    <Button onClick={handleAgree} color={buttonAccept ? "success" : "error"} variant="contained">
                        {buttonAccept ? buttonAccept : "Supprimer"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
