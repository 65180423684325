import React from 'react'
import { Button, Box, Typography } from '@mui/material'



const AddVideoButton = ({ handleOpenVideo, label, text }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: '#16324D', marginRight: '5px' }}>
                Video :
            </Typography>
            <Button
                sx={{ borderRadius: 1, boxShadow: 0, textTransform: 'none' }}
                color="secondary"
                size="medium"
                variant="contained"
                component="label"
                onClick={handleOpenVideo}
            >
                {text ? text : "Ajouter une video"}

            </Button>
        </Box>
    )
}

export default AddVideoButton