import instance from "../utils/axios";

class city {
    constructor() {
        this.instance = instance;
    }
    updateCity = async (data) => {
        return await instance.put(`city/update`, data, {
            withCredentials: true,
        });
    }
    getCitys = async () => {
        return await instance.get("city/all", {
            withCredentials: true,
        });
    };

}

var citysService = new city();
export default citysService;
