import React from 'react';
import Action from '../../components/Action'

const createColumnsTV = ({ setRows, rows, editLink, handleDeleteArticle }) => {
    return [
        {
            field: "actions",
            headerName: "#",
            width: 130,
            renderCell: (params) => {
                return (
                    <Action
                        notificationActivate={false}
                        editActivate={true}
                        setRows={setRows}
                        rows={rows}
                        editLink={`${editLink}/${params.id}`}
                        idArticle={params.id}
                        notifications={0}
                        label="Êtes-vous sûr de vouloir supprimer cet article ?"
                    />
                );
            },
        },
        {
            field: "title",
            headerName: "Titre",
            width: 380,
            hideable: false
        },
        {
            field: "description",
            headerName: "Description",
            width: 200,

        },
        {
            field: "publication_date",
            headerName: "Date de publication",
            width: 200,
        },
        {
            field: "updatedAt",
            headerName: "Date de modification",
            width: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const options = {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);
                localDateString = localDateString.replace(
                    /(\w)(\w*)/g,
                    function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase();
                    }
                ); // Up the first letter in Uppercase
                return localDateString;
            },
        },
        {
            field: "is_active",
            headerName: "Afficher",
            width: 300,
            valueGetter: (params) => (params.row.is_active ? "Oui" : "Non")
        },
        {
            field: "delete",
            headerName: "",
            width: 70,
            renderCell: (params) => {
                return <Action trashIcon={true} deleteAction={() => handleDeleteArticle(params.id)} />;
            },
        },
    ];
};

export default createColumnsTV;
