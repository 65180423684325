import React from 'react'
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MediasCreate from '../../medias/Medias.create';



export const AddImageModal = ({ open, handleCloseCroppImage, setInfoCreate, infoCreate, dataArticle, setDataArticle, isCreate = false }) => {
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleCloseCroppImage}
                TransitionComponent={Transition}
            >

                <MediasCreate
                    openMedias={true}
                    handleCloseCroppImage={handleCloseCroppImage}
                    setInfoCreate={setInfoCreate}
                    infoCreate={infoCreate}
                    dataArticle={dataArticle}
                    setDataArticle={setDataArticle}
                    isCreate={isCreate}
                />


            </Dialog>
        </>
    )
}
