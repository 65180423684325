import React, { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import AuthService from "../services/auth.service";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";


const Register = (props) => {


    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const { t } = useTranslation();


    const initialValues = {
        email: "",
        password: "",
    };

    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().required("Required"),
    });

    const handleRegister = (values, { setSubmitting }) => {
        setMessage("");
        setSuccessful(false);
        setSubmitting(true);

        /*if (initialValues) {
            AuthService.register(username, email, password).then(
                (response) => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );
        }*/
    };

    return (
        <>
        <h3>{ t ('register')}</h3>
        <hr/>
        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleRegister}>
                    {({ isSubmitting }) => (
                        <Form className="form-group">

                            <label htmlFor="email">Email</label>
                            <Field
                                name="email"
                                className="form-control"
                            />
                            <ErrorMessage name="email" component="div" className="error" />
                            <label htmlFor="password">{ t ('password')}</label>
                            <Field
                                type="password"
                                name="password"
                                className="form-control"
                            />
                            <ErrorMessage name="password" component="div" className="error" />


                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                            <button style={{ display: "none" }} />

                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-dark btn-block mt-3"
                            >
                                { t ('submit')}

                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
        </>
    );
};

export default Register;