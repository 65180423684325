import { useLocation } from 'react-router-dom';
import Menu from "../../components/layout/Menu";
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import regiePubService from "../../services/regiePub.service";
import { Grid, TextField, Paper, FormGroup, Box, Button, CircularProgress, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import CustomInlineComponent from "../../pages/articles/components/CustomInlineComponent";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '../../pages/articles/components/DatePicker';
import { toast } from "react-toastify";
import { IMAGE_URL } from "../../constants/url";
import ConvertDateToMilliseconds from "../../common/DateConvert";
import IOSSwitch from "../../common/IosSwithc";
import ConfirmDeleteModal from '../articles/components/ConfirmDeleteModal';
import { arrayOfSizesC4, arrayOfSizesB5, arrayOfSizesAC3 } from './components/DataPub'
import AlertDialog from "../../components/Alert";

const EditCreateRegiePub = () => {
    const navigate = useNavigate();
    const [fisrtTryPublication, setFisrtTryPublication] = useState(false);
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const { pubId } = useParams();
    const [mergedArrayForSeeIfHaveChanges, setMergedArrayForSeeIfHaveChanges] = useState([]);
    const [arrayOfIdImagesFromPub, setArrayOfIdImagesFromPub] = useState([]);
    const isEdit = pathSegments.includes('edit');
    const isCreate = pathSegments.includes('create');
    const breadcrumbArray = [
        { name: "Régie pub", to: "/regie-pub" },
        { name: isEdit ? "Modifier une campagne" : "Créer une campagne", to: "/regie-pub" }

    ];
    const [confirmPublication, setConfirmPublication] = useState(false);
    const handleComfirmPublication = () => setConfirmPublication(true);
    const handleCloseComfirmPublication = () => setConfirmPublication(false);
    const [cancelConfirmPublication, setCancelConfirmPublication] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    //Banniere 5
    const [banner5, setBanner5] = useState(arrayOfSizesB5);
    //Image carre 4
    const [imageCarre4, setImageCarre4] = useState(arrayOfSizesC4);
    //image Arche 3

    const [imageArche3, setImageArche3] = useState(arrayOfSizesAC3);

    useEffect(() => {
        if (isEdit) {
            regiePubService.getPubById(pubId).then((response) => {
                setDataPub({
                    ...response.data,
                    date_start: ConvertDateToMilliseconds(response.data.date_start),
                    date_end: ConvertDateToMilliseconds(response.data.date_end)
                }
                );

                const arrayOfImages = response.data.media_publicities;

                setArrayOfIdImagesFromPub(arrayOfImages.map((image) => image.id));

                // Banniere 5 : update the array with the images and the links that have the same size with arrayOfImages
                const banner = banner5.map((banner) => {
                    const image = arrayOfImages.find((image) => image.size === `${banner.width}x${banner.height}`);
                    if (image) {
                        return {
                            ...banner,
                            id: image.id,
                            url_image: IMAGE_URL + image.url,
                            link: image.link
                        }
                    }
                    return banner;
                })

                setBanner5(banner);

                // Image carre 4
                const carreFilter = arrayOfImages.map((banner) => {
                    if (banner.size === "250x250") {
                        return banner;
                    }
                    return null;
                }).filter((banner) => banner !== null);


                const carre = imageCarre4.map((banner, index) => {
                    const imageCarre = carreFilter.find((image, indexx) => image.size + String(indexx) === `${banner.width}x${banner.height}${index}`);

                    if (imageCarre) {
                        const matchingImages = carreFilter.filter((image, indexx) => image.size + String(indexx) === `${banner.width}x${banner.height}${index}`);
                        const matchedImage = matchingImages[index % matchingImages.length];
                        return {
                            ...banner,
                            id: matchedImage.id,
                            url_image: IMAGE_URL + matchedImage.url,
                            link: matchedImage.link
                        };
                    }
                    return banner;
                })

                setImageCarre4(carre);

                // image Arche 3
                const arche = imageArche3.map((banner, index) => {
                    const imageArche = arrayOfImages.find((image) => image.size === `${banner.width}x${banner.height}`);
                    if (imageArche) {
                        const matchingImages = arrayOfImages.filter((image) => image.size === `${banner.width}x${banner.height}`);
                        const matchedImage = matchingImages[index % matchingImages.length];
                        return {
                            ...banner,
                            id: matchedImage.id,
                            url_image: IMAGE_URL + matchedImage.url,
                            link: matchedImage.link
                        };
                    }
                    return banner;
                })
                setImageArche3(arche);
                console.log("arcge", arche);
                const arraysImagesFromPub = mergeArrays(banner, carre, arche).map((image) => {
                    if (image.id !== undefined && image.link !== undefined) {
                        return {
                            id: image.id,
                            link: image.link,
                        }
                    }
                }).filter((image) => image !== undefined);

                setMergedArrayForSeeIfHaveChanges(arraysImagesFromPub);
            }
            );
        }

    }, []);
    //liste de champs
    const [dataPub, setDataPub] = useState({
        id: 0,
        advertiser: '',
        title: '',
        url: '',
        date_start: Math.round(new Date().getTime() / 1000),
        date_end: Math.round(new Date().getTime() / 1000) + 86400 * 7,
        is_active: false,
        is_arch: false,
        createdAt: '',
        updatedAt: '',
        clicks: 0,
        views: 0,
        media_publicities: [],
    });

    const [errorArch, setErrorArch] = useState(false);

    //get data by id
    useEffect(() => {
        if (isEdit) {
            regiePubService.getPubById(pubId).then((response) => {
                setDataPub(response.data);
            }
            );
        }
    }
        , []);

    //validation des champs
    const [formErrors, setFormErrors] = useState({
        advertiser: '',
        title: '',
        url: '',
        date_start: '',
        date_end: '',
        createdAt: '',
        updatedAt: '',
        media_publicities: '',
    });

    const validateForm = () => {
        let isValid = true;
        const newFormErrors = {};

        if (dataPub.advertiser === "") {
            newFormErrors.advertiser = "L'annonceur est requis.";
            isValid = false;
        }
        if (dataPub.title === "") {
            newFormErrors.title = "Le titre est requis.";
            isValid = false;
        }

        if (dataPub.date_start === "") {
            newFormErrors.date_start = "La date de début est requise.";
            isValid = false;
        }
        if (dataPub.date_end === "") {
            newFormErrors.date_end = "La date de fin est requise.";
            isValid = false;
        }
        if (dataPub.media_publicities === "") {
            newFormErrors.media_publicities = "La publicité est requise.";
            isValid = false;
        }

        setFormErrors(newFormErrors);
        return isValid;
    };
    //validation des images taille et format

    const isUrlValid = (url) => {
        // Expresión regular para validar URL
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    };
    const AddBanner = ({ index, elementPub, finalArray, setElementPub, setInvalidForm, invalidForm }) => {

        const [error, setError] = useState(null);
        const [redirectUrl, setRedirectUrl] = useState(elementPub.link);

        const fileInputRef = useRef(null);
        const handleButtonClick = () => {
            fileInputRef.current.click();
        };

        const handleUrlChange = (e) => {

            setRedirectUrl(e.target.value);

            finalArray[index] = {
                ...finalArray[index],
                link: e.target.value
            };

        }


        const handleImageUpload = (event) => {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = () => {

                        if (img.width === elementPub.width && img.height === elementPub.height) {
                            const newArray = [...finalArray];
                            newArray[index] = {
                                ...newArray[index],
                                media: file,
                                link: redirectUrl,
                                url_image: reader.result
                            };
                            setElementPub(newArray);
                        } else {
                            setError(`La taille de l'image doit être de ${elementPub.width}x${elementPub.height}`);
                        }
                    };
                };
            }

        };

        const tryTosetError = () => {

            if (fisrtTryPublication) {
                if (elementPub.media === null && redirectUrl !== "") {
                    return "red"
                } else {
                    return "#F2F2F2"
                }
            }
        }
        const encodedUrl = encodeURI(elementPub.url_image);

        return (
            <Box p={1} display="flex" flexDirection="column" >
                <Box flexDirection="column">
                    <div className='h-16'>
                        <Typography variant="h8" style={{ fontWeight: "bold", color: "#16324D" }} gutterBottom>
                            {elementPub.title}
                        </Typography>
                    </div>
                    <Paper
                        className="responsive-paper"
                        variant="outlined"
                        square
                        style={{
                            borderRadius: '10px',
                            borderColor: tryTosetError(),
                            width: '100%',
                            height: '220px',
                            marginBottom: '1rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundImage: elementPub.url_image ? `url("${encodedUrl}")` : 'none',
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat"
                        }}
                    >

                        {elementPub.url_image === null && elementPub.width + "x " + elementPub.height}

                    </Paper>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <Tooltip title={elementPub.url_image === "" ? "Veuillez ajouter d'abord une URL pour l'image" : ""}>
                        <Button
                            style={{
                                width: '195px',
                                color: "#0057BA",
                            }}
                            onClick={() => {
                                if (redirectUrl !== "") {
                                    handleButtonClick();
                                    setError(null);
                                }
                                else {
                                    setError("Veuillez ajouter d'abord une URL pour l'image");
                                }
                            }}
                        >
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                ref={fileInputRef}
                                style={{ display: "none" }}
                            />
                            <AddCircleIcon style={{ color: "#0057BA", marginRight: "8px" }} />Ajouter une image
                        </Button>
                    </Tooltip>
                    <TextField
                        label="Saisir une URL de redirection"
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="normal"
                        error={redirectUrl === "" && elementPub.media !== null}
                        required={elementPub.media !== null}
                        value={redirectUrl}
                        onChange={handleUrlChange}

                    />
                    {redirectUrl !== "" && (
                        <>
                            {isUrlValid(redirectUrl) ? "" : <p style={{ color: "red" }}>
                                URL invalide le format doit être http://www.example.com ou https://www.example.com
                            </p>}
                        </>
                    )}
                </Box>
            </Box>
        )
    }

    const createPubImageWithIndex = async ({ height, width, mediaInput, link, archH = false, archR = false, archL = false, idBefore }) => {
        const createImagePub = regiePubService.uploadImageForPub(
            {
                data: mediaInput,
                size: `${width}x${height}`,
                link: link,
                archH: archH,
                archR: archR,
                archL: archL
            }
        ).then((response) => {
            if (idBefore !== null) {
                response.data[0].idBefore = idBefore;
            }
            return response.data[0];

        });
        return await createImagePub;
    }

    const checkArch = (array) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i].url_image === null) {

                return `${array[i].title}: Media es null`;
            }
        }
        return null;
    }
    const arrayOfImagesAreValid = () => {
        const mergedArray = mergeArrays(banner5, imageCarre4, imageArche3);
        for (let i = 0; i < mergedArray.length; i++) {

            if (mergedArray[i].url_image !== null && mergedArray[i].link === "") {
                return true;
            }
        }
        return false;
    }
    const arrayOfUrlDontHaveUrl = () => {
        const mergedArray = mergeArrays(banner5, imageCarre4, imageArche3);
        for (let i = 0; i < mergedArray.length; i++) {
            if (mergedArray[i].url_image === null && mergedArray[i].link !== "") {
                return true;
            }
        }
        return false;
    }
    const arrayOfImagesUrlAreValid = () => {
        const mergedArray = mergeArrays(banner5, imageCarre4, imageArche3);
        for (let i = 0; i < mergedArray.length; i++) {
            // isUrlValid return true if the url is valid
            if (mergedArray[i].url_image !== null && !isUrlValid(mergedArray[i].link)) {
                return true;
            }
        }
        return false;
    }

    const handleValidation = () => {

        let isValid = true;
        if (!validateForm()) {
            setFisrtTryPublication(true);
            isValid = false;
        }
        if (arrayOfImagesUrlAreValid()) {
            toast.error("Une ou plusieurs images n'ont pas d'URL valide", {
                position: "top-right",
                theme: "dark",
            });
            isValid = false;
        }

        if (arrayOfImagesAreValid()) {
            toast.error("Une ou plusieurs images n'ont pas d'URL", {
                position: "top-right",
                theme: "dark",
            });
            isValid = false;
        }
        if (arrayOfUrlDontHaveUrl()) {
            toast.error("Une ou plusieurs publicités n'ont pas d'image", {
                position: "top-right",
                theme: "dark",
            });
            isValid = false;
        }
        if (dataPub.is_arch) {
            if (checkArch(imageArche3) !== null) {
                toast.error("Les 3 images des arches sont requises pour continuer", {
                    position: "top-right",
                    theme: "dark",
                });
                setErrorArch(true);
                isValid = false;
            }
        }
        // Reset the error
        const mergedArray = mergeArrays(banner5, imageCarre4, imageArche3);
        const verifyIfHave1Image = mergedArray.filter((image) => image.media !== null).length;
        // Verify if at least one image is added in if isCreate
        if (isCreate) {
            if (verifyIfHave1Image === 0) {
                toast.error(` ${dataPub.is_active ? "Au moins une image est requise pour continuer" : "Au moins une image doit être insérée pour sauvegarder la campagne en brouillon"}`, {
                    position: "top-right",
                    theme: "dark",
                });
                isValid = false;
            }
        }
        const verifyIfHave1ImageEdit = mergedArray.filter((image) => image.url_image !== null).length;
        if (isEdit) {
            if (verifyIfHave1ImageEdit === 0) {
                toast.error(` ${dataPub.is_active ? "Au moins une image est requise pour continuer" : "Au moins une image doit être insérée pour sauvegarder la campagne en brouillon"}`, {
                    position: "top-right",
                    theme: "dark",
                });
                isValid = false;
            }
        }

        setErrorArch(false);
        return isValid;
    }




    const handleCreatePub = async ({ is_arch = false, is_draft = false }) => {
        if (!validateForm()) {
            setFisrtTryPublication(true);
            return null;
        }
        if (arrayOfImagesUrlAreValid()) {
            toast.error("Une ou plusieurs images n'ont pas d'URL valide", {
                position: "top-right",
                theme: "dark",
            });
            return null;
        }

        if (arrayOfImagesAreValid()) {
            toast.error("Une ou plusieurs images n'ont pas d'URL", {
                position: "top-right",
                theme: "dark",
            });
            return null;
        }
        if (arrayOfUrlDontHaveUrl()) {
            toast.error("Une ou plusieurs publicités n'ont pas d'image", {
                position: "top-right",
                theme: "dark",
            });
            return null;
        }
        if (is_arch) {
            if (checkArch(imageArche3) !== null) {
                toast.error("Les 3 images des arches sont requises pour continuer", {
                    position: "top-right",
                    theme: "dark",
                });
                setErrorArch(true);
                return null;
            }
        }

        // Reset the error
        setErrorArch(false);
        // merge all the arrays
        const mergedArray = mergeArrays(banner5, imageCarre4, imageArche3);
        const verifyIfHave1Image = mergedArray.filter((image) => image.media !== null).length;
        // Verify if at least one image is added in if isCreate
        if (isCreate) {
            if (verifyIfHave1Image === 0) {
                toast.error(`${dataPub.is_active ? "Au moins une image est requise pour continuer" : "Au moins une image doit être insérée pour sauvegarder la campagne en brouillon"}`, {
                    position: "top-right",
                    theme: "dark",
                });
                return null;
            }
        }
        // Create the images

        const promisesArray = mergedArray.map(image => {
            if (image.media !== null) {
                return createPubImageWithIndex({
                    height: image.height,
                    width: image.width,
                    mediaInput: image.media,
                    link: image.link,
                    archH: image.archH || false,
                    archR: image.archR || false,
                    archL: image.archL || false,
                    idBefore: image.id || null
                });
            } else {
                // Return a resolved promise if media is null
                return Promise.resolve(null);
            }
        });
        const results = await Promise.all(promisesArray);
        if (isEdit) {

            const newArray = mergedArray.map((image) => {
                if (image.id !== undefined && image.link !== undefined) {
                    return {
                        id: image.id,
                        link: image.link,
                    }
                }
            }).filter((image) => image !== undefined);

            const originalArray = mergedArrayForSeeIfHaveChanges
            const changedLinks = newArray.filter(newObj => {
                const correspondingObj = originalArray.find(origObj => origObj.id === newObj.id);
                return correspondingObj && correspondingObj.link !== newObj.link;
            });


            const mapWithIdAndBeforeId = results.map((result) => {

                if (result !== null) {
                    return {
                        id: result.id,
                        idBefore: result?.idBefore
                    }
                }
                return null;
            });
            const cleanMapOfNull = mapWithIdAndBeforeId.filter((result) => result !== null);

            // fisrt filter in arrayOfIdImagesFromPub with the idBefore 

            const finalArray = arrayOfIdImagesFromPub.map(id => {
                const correspondingId = cleanMapOfNull.find(result => result.idBefore === id);
                return correspondingId ? correspondingId.id : id;
            });
            cleanMapOfNull.forEach(({ idBefore, id }) => {
                if (idBefore === null || idBefore === undefined) {
                    finalArray.push(id);
                }
            });

            if (changedLinks.length > 0) {
                await regiePubService.updateImagesPub(changedLinks).then((response) => {
                });
            }
            await regiePubService.updatePub({
                id: pubId,
                advertiser: dataPub.advertiser,
                title: dataPub.title,
                url: "https://www.maritima.fr",
                date_start: ConvertDateToMilliseconds(dataPub.date_start),
                date_end: ConvertDateToMilliseconds(dataPub.date_end),
                is_active: !is_draft,
                is_arch: is_arch,
                medias_id: finalArray
            }).then((response) => {
                setBanner5(arrayOfSizesB5);
                setImageCarre4(arrayOfSizesC4);
                setImageArche3(arrayOfSizesAC3);
                navigate("/regie-pub");
            });
        } else {
            //get only the id of the images
            const imagesIds = results.map((result) => result?.id).filter((id) => id !== undefined);;
            // Create the createCampaignPub
            const data = {
                advertiser: dataPub.advertiser,
                title: dataPub.title,
                url: "https://www.maritima.fr",
                date_start: dataPub.date_start,
                date_end: dataPub.date_end,
                is_active: !is_draft,
                is_arch: is_arch,
                medias_id: imagesIds
            };

            await regiePubService.createCampaignPub(data).then((response) => {
                setBanner5(arrayOfSizesB5);
                setImageCarre4(arrayOfSizesC4);
                setImageArche3(arrayOfSizesAC3);
                navigate("/regie-pub");
            });
        }
    }


    const mergeArrays = (...arrays) => {
        const mergedArray = [].concat(...arrays);
        const uniqueArray = [...new Set(mergedArray)];
        return uniqueArray;
    }
    useEffect(() => {

        if (fisrtTryPublication) {
            validateForm();
        }
    }, [dataPub]);

    const handleDeleteArticle = () => {
        regiePubService.deletePub(pubId)
            .then((res) => {
                if (res.status === 200) {
                    navigate('/regie-pub')
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }
    return (
        <Menu category="Régie Pub" breadCrumb={breadcrumbArray}>
            <div>
                <h1>{isEdit ? 'Editer' : isCreate ? 'Créer' : 'RegiePub'}</h1>
            </div>
            <Grid container spacing={5} className={"m-4"} sx={{ display: 'flex', width: "100%" }}>
                {/* LEFT */}
                <Grid sx={{ flexDirection: "column" }} container xs={8}>
                    <TextField
                        className={"mb-2"}
                        fullWidth
                        id="title"
                        label="Titre de  la campagne"
                        variant="outlined"
                        error={!!formErrors.title}
                        helperText={formErrors.title}
                        value={dataPub.title}
                        onChange={(e) => {
                            setDataPub({ ...dataPub, title: e.target.value })
                        }
                        }
                    />
                    <Typography variant="h5" style={{ fontWeight: "bold", color: "#16324D" }} gutterBottom>
                        Formats
                    </Typography>
                    {/* BANNER 5 */}
                    <Grid container spacing={2} sx={{ marginBottom: "20px", display: "flex", justifyContent: "space-between" }}>
                        {banner5?.map((banner, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12} sm={6} md={6} lg={5}>
                                    <AddBanner
                                        index={index}
                                        elementPub={banner}
                                        setElementPub={setBanner5}
                                        finalArray={banner5}
                                        link={banner.link}
                                    />
                                </Grid>
                                {index === 1 && (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Divider orientation="horizontal" sx={{ backgroundColor: 'black', height: '2px' }} />
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                    </Grid>
                    <Divider orientation="horizontal" sx={{ backgroundColor: 'black', height: '2px' }} />
                    {/* CARRE */}

                    <Grid container spacing={2} sx={{ marginLeft: '-8px', marginRight: '-8px', marginTop: "8px", display: "flex", justifyContent: "space-between" }}>
                        {imageCarre4.map((banner, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={5}>
                                <AddBanner
                                    index={index}
                                    elementPub={banner}
                                    setElementPub={setImageCarre4}
                                    finalArray={imageCarre4}


                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Divider orientation="horizontal" sx={{ backgroundColor: 'black', height: '2px' }} />

                    {/* ARCHE */}
                    {dataPub.is_arch && (
                        <>
                            {errorArch && <p style={{ color: "red" }}>
                                Toutes les images de l'arche doivent être ajoutées
                            </p>}

                            <Grid container spacing={2} sx={{ marginLeft: '-8px', marginRight: '-8px', marginTop: "8px", marginBottom: "20px", display: "flex", justifyContent: "space-between" }}>
                                {imageArche3.map((banner, index) => (
                                    <Grid item key={index} xs={12} sm={6} md={6} lg={5}>
                                        <AddBanner
                                            index={index}
                                            elementPub={banner}
                                            arrayMutable={imageArche3}
                                            setElementPub={setImageArche3}
                                            finalArray={imageArche3}

                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </Grid>

                {/* RIGHT  */}

                <Grid container xs={4}>
                    <div style={
                        {

                            marginLeft: "25px",
                            width: "88%",
                        }
                    } >
                        <CustomInlineComponent
                            textLeft={"Nombre de clics"}
                            textRight={dataPub.clicks}

                        />
                        <CustomInlineComponent
                            textLeft={"Nombre de vues"}
                            textRight={dataPub.views}

                        />

                        <Paper
                            style={{
                                padding: "10px",
                                marginTop: "20px",
                                borderRadius: "10px",
                                border: "1px solid #F2F2F2",

                                display: "flex",
                                flexDirection: "column",

                            }}
                        >
                            <Typography variant="h7" style={{ fontWeight: "bold", color: "#16324D" }} gutterBottom>
                                Nom de  l’annonceur
                            </Typography>
                            <TextField
                                className={"mb-2"}
                                fullWidth
                                id="advertiser"

                                placeholder='Saisir un nom d’annonceur'
                                variant="standard"
                                error={!!formErrors.advertiser}
                                helperText={formErrors.advertiser}
                                value={dataPub.advertiser}
                                onChange={(e) => {
                                    setDataPub({ ...dataPub, advertiser: e.target.value })
                                }
                                }
                            />
                        </Paper>
                        <Paper
                            style={{
                                padding: "10px",
                                marginTop: "20px",
                                borderRadius: "10px",
                                border: "1px solid #F2F2F2",

                                display: "flex",
                                flexDirection: "column",

                            }}
                        >
                            <Typography variant="h7" style={{ fontWeight: "bold", color: "#16324D" }} gutterBottom>
                                Activer l'Arche
                            </Typography>
                            <FormGroup>
                                <label>
                                    <IOSSwitch
                                        checked={dataPub.is_arch}
                                        onChange={(e) => {
                                            setDataPub({ ...dataPub, is_arch: e.target.checked })
                                        }}
                                        sx={{ marginRight: 5, marginLeft: 1 }} />
                                </label>
                            </FormGroup>
                        </Paper>
                        <Paper
                            style={{
                                padding: "10px",
                                marginTop: "20px",
                                borderRadius: "10px",
                                border: "1px solid #F2F2F2",

                                display: "flex",
                                flexDirection: "column",

                            }}
                        >
                            <Typography variant="h7" style={{ fontWeight: "bold", color: "#16324D" }} gutterBottom>
                                Durée d’affichage de la campagne
                            </Typography>
                            {formErrors.date_start && <p style={{ color: "red" }}>{formErrors.date_start}</p>}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    setDataArticle={setDataPub}
                                    dataArticle={dataPub}
                                    propertyName={"date_start"}
                                    title={"Date de début"}
                                />
                            </LocalizationProvider>
                            {formErrors.date_end && <p style={{ color: "red" }}>{formErrors.date_end}</p>}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    setDataArticle={setDataPub}
                                    dataArticle={dataPub}
                                    propertyName={"date_end"}
                                    title={"Date de fin"}
                                />
                            </LocalizationProvider>
                        </Paper>
                        <Paper
                            style={{
                                padding: "10px",
                                marginTop: "20px",
                                borderRadius: "10px",
                                border: "1px solid #F2F2F2",
                            }}
                        >
                            <div className='flex justify-content-lg-between'>
                                <div>
                                    <Button
                                        variant="contained"
                                        color='primary'
                                        sx={{
                                            marginLeft: "5px",
                                            color: "white",
                                            paddingBottom: "6px",
                                        }}
                                        onClick={() => setCancelConfirmPublication(true)}
                                    >

                                        <span className="normal-case">Annuler</span>

                                    </Button>
                                </div>
                                {/* Draft */}
                                <div>
                                    {!dataPub.is_active && (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                marginLeft: "5px",
                                                color: "white",
                                                paddingBottom: "6px",
                                                backgroundColor: "#16324D"
                                            }}
                                            onClick={() => {
                                                handleCreatePub({ is_arch: dataPub.is_arch, is_draft: true });
                                            }
                                            }
                                        >
                                            <span className="normal-case">{isEdit ? "Enregistrer en tant que brouillon" : "Brouillon"}</span>
                                        </Button>
                                    )}
                                    {/* Publication */}
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginLeft: "5px",
                                            color: "white",
                                            paddingBottom: "6px",
                                            backgroundColor: "#03A9F4"
                                        }}
                                        onClick={() => {
                                            if (isCreate || dataPub.is_active === false) {
                                                if (handleValidation()) {

                                                    handleComfirmPublication();
                                                }
                                            } else {
                                                handleComfirmPublication();
                                            }

                                        }
                                        }
                                    >
                                        <span className="normal-case">{isEdit && dataPub.is_active ? "Mettre à jour" : "Publier"}</span>
                                    </Button>
                                </div>

                            </div>
                            {isEdit && (
                                <div className='justify-content-end flex'>
                                    <Button
                                        variant="contained"
                                        color='error'
                                        sx={{
                                            marginTop: "7px",
                                            marginLeft: "5px",
                                            color: "white",
                                            paddingBottom: "6px",
                                            paddingLeft: "24px",
                                            paddingRight: "24px",
                                        }}
                                        onClick={handleOpenModal}
                                    >
                                        <span className="normal-case">Supprimer</span>
                                    </Button>
                                </div>
                            )}
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <ConfirmDeleteModal
                open={openModal}
                onClose={handleCloseModal}
                onConfirm={handleDeleteArticle}
                text={"Êtes-vous sûr de vouloir supprimer cette publicité ?"}
            />
            <AlertDialog
                open={cancelConfirmPublication}
                handleClose={() => setCancelConfirmPublication(false)}
                handleAgree={() => navigate("/regie-pub")}
                title={"Attention"}
                label={"Voulez vous vraiment annuler ? Toutes vos modifications seront perdues"}
                buttonAccept={"Oui"}
                buttonCancel={"Non"}
            />
            <AlertDialog
                open={confirmPublication}
                handleClose={handleCloseComfirmPublication}
                handleAgree={() => handleCreatePub({ is_arch: dataPub.is_arch, is_draft: false })}
                title={"Attention"}
                label={isEdit && dataPub.is_active ? "Voulez vous vraiment mettre à jour?" : " Voulez vous vraiment publier  ?"}
                buttonAccept={"Oui"}
                buttonCancel={"Non"}
            />
        </Menu >
    )
}
export default EditCreateRegiePub;