import instance from "../utils/axios";

const getDataAnnexPages = async (name) => {
    return await instance.get("annex-pages/?name=" + name);
}

const UpdateAnnexPagesService = async (data) => {
    return await instance.put("annex-pages/", data);
}

const AnnexPagesService = {
    UpdateAnnexPagesService,
    getDataAnnexPages
}

export default AnnexPagesService;