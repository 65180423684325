import React, {useEffect, useState} from 'react';
import AnnexPagesService from "../../services/annex_pages.service";
import {toast} from "react-toastify";
import Menu from "../../components/layout/Menu";
import Loader from "../../components/Loader";
import FormAnnexPage from "./FormAnnexPage";
import {useParams} from "react-router-dom";

export default function AnnexPages() {
    const { name } = useParams();
    const [loader, setLoader] = useState(false);
    const [canValidate, setCanValidate] = useState(false);
    const [dataArticle, setDataArticle] = useState({
        name: name,
        title: "",
        body: "",
    });
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const breadcrumbArray = [
        {
            name: title,
            link: "/",
        },
    ];
    const handleSave = () => {
        setLoader(true);
        AnnexPagesService.UpdateAnnexPagesService(dataArticle)
            .then((response) => {
                setLoader(false);
                if (response.data && response.data.annexPages) {
                    toast.success("Page mise à jour avec succès", {
                        position: "top-right",
                        theme:"dark"
                    });
                    setTitle(response.data.annexPages.title);
                    setBody(response.data.annexPages.body);
                } else {
                    toast.error("Erreur lors de la mise à jour de la page", {
                        position: "top-right",
                        theme:"dark"
                    });
                }
            })
            .catch((error) => {
                setLoader(false);
                toast.error("Erreur lors de la mise à jour de la page", {
                    position: "top-right",
                    theme:"dark"
                });
            })
    }
    useEffect(() => {
        if (dataArticle.title && dataArticle.body) {
            if (dataArticle.title.trim() !== title || dataArticle.body.trim() !== body) {
                setCanValidate(true);
            } else {
                setCanValidate(false);
            }
        } else {
            setCanValidate(false);
        }
    }, [dataArticle, title, body])

    useEffect(() => {
        if (name) {
            setLoader(true);
            AnnexPagesService.getDataAnnexPages(name)
                .then((response) => {
                    if (response.data && response.data.annexPages) {
                        setTitle(response.data.annexPages.title)
                        setBody(response.data.annexPages.body)
                        setDataArticle({name: response.data.annexPages.name, title: response.data.annexPages.title, body: response.data.annexPages.body});
                    } else {
                        toast.error("Erreur lors du chargement de la page", {
                            position: "top-right",
                            theme:"dark"
                        });
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                    toast.error("Erreur lors du chargement de la page", {
                        position: "top-right",
                        theme:"dark"
                    });
                })
        }
        return () => {
            setDataArticle({
                name: "",
                title: "",
                body: "",
            });
            setTitle("");
            setBody("");
        }
    },[name]);


    return (
        <Menu category={dataArticle.title} breadCrumb={breadcrumbArray} link={name} openParams={true}>
            {loader && <Loader/>}
            <FormAnnexPage dataArticle={dataArticle} setDataArticle={setDataArticle} canValidate={canValidate} handleSave={handleSave} />
        </Menu>
    );
}