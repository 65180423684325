import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios'; // Asegúrate de tener axios instalado: npm install axios
import GoogleIcon from '@mui/icons-material/Google';
import { API_URL } from '../../constants/url';


const UploadVideoForm = ({ onClose, dataArticle, setDataArticle }) => {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');
    const [tokenGoogleValid, setTokenGoogleValid] = useState(false);
    const [description, setDescription] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [errorUpload, setErrorUpload] = useState(false);
    const [disabledButtonWhenUpload, setDisabledButtonWhenUpload] = useState(false);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };


    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };
    useEffect(() => {
        setUploadSuccess(false);
        setUploadProgress(0);
        setCopiedText('');
        const script = document.createElement('script');

        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (uploadProgress > 0 && uploadProgress < 100) {
                const message = "Vous quittez la page et le chargement de la vidéo n'est pas encore terminé. Êtes-vous sûr ?";
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [uploadProgress]);

    const handleToken = async () => {
        //important changer pour prod
        const client = window.google.accounts.oauth2.initCodeClient({
            client_id: '948245997486-f1l2dmc6518rpanrfkdiunmksuv24lfm.apps.googleusercontent.com',
            scope: 'https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtube.upload',
            ux_mode: 'popup',
            callback: (response) => {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', `${API_URL}googleSignIn`, true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.setRequestHeader('X-Requested-With', 'XmlHttpRequest');
                xhr.onload = function () {
                    console.log('Auth code response: ' + xhr.responseText);
                    localStorage.setItem('accessToken', xhr.responseText);
                };
                setTokenGoogleValid(true);

                xhr.send('code=' + response.code);
            },
        });

        client.requestCode();
    }

    const updateVideo = async (idVideo) => {
        try {
            // Paramètres de la requête
            setCopiedText(`https://www.youtube.com/watch?v=${idVideo}`);
            setDataArticle({ ...dataArticle, url: `https://www.youtube.com/watch?v=${idVideo}` });

            const access_token = localStorage.getItem('accessToken');

            //A CHANGER IMPORTANT POUR PROD
            const metadataUrl = `https://youtube.googleapis.com/youtube/v3/videos?id=gvz1lDvQVsE&part=snippet%2Cstatus%2Clocalizations&key=AIzaSyCUK0dCer25z4O7lzG9dtnt5CB9F6r9mdU`;

            const metadata = {
                id: idVideo,
                snippet: {
                    categoryId: 22,
                    defaultLanguage: "fr",
                    description: description,
                    // tags: [
                    //     "new tags"
                    // ],
                    title: title
                },
                status: {
                    privacyStatus: "public",
                    embeddable: true,
                },
                localizations: {
                    en: {
                        title: title,
                        description: description
                    }
                }
            };
            const response = await axios.put(metadataUrl, metadata, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });
            setDisabledButtonWhenUpload(false);
            setUploadSuccess(true);
            console.log(response.data);
        }
        catch (error) {
            setDisabledButtonWhenUpload(false);
            console.log(error);
            console.error('Erreur lors du téléchargement de la vidéo :', error.message);
        }
    }


    const handleUpload = async () => {
        try {
            // Paramètres de la requête
            setDisabledButtonWhenUpload(true);
            const access_token = localStorage.getItem('accessToken');
            const url = 'https://www.googleapis.com/upload/youtube/v3/videos?part=snippet,status';
            const headers = {
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/octet-stream',
                'X-Upload-Content-Length': file.size,
                'X-Upload-Content-Type': file.type,
            };
            const config = {
                headers,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            };
            // add metadata to the request


            const response = await axios.post(url, file, config).then((response) => {
                const idVideo = response.data.id;
                updateVideo(idVideo);


            });

            console.log(response);
        } catch (error) {
            console.log(error);
            setDisabledButtonWhenUpload(false);
            setErrorUpload(true);
            console.error('Erreur lors du téléchargement de la vidéo :', error.message);
        }
    };
    const checkTokenValidity = async (accessToken) => {
        try {
            const response = await axios.get(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessToken}`);
            if (response.data.expires_in > 0) {
                setTokenGoogleValid(true);
            }

        } catch (error) {
            setTokenGoogleValid(false);
            console.error('Erreur lors de la vérification de la validité du :token:', error.message);
            return null;
        }
    };


    useEffect(() => {
        checkTokenValidity(localStorage.getItem('accessToken'));
    }
        , []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {

        }).catch((err) => {
            console.error('Erreur de copie dans le presse-papier :', err);
        });
    };



    return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded shadow-md">
            <h1 className="text-2xl font-semibold mb-4">Télécharger une vidéo sur YouTube</h1>
            {uploadSuccess ? (
                <>
                    <p className="text-green-600 font-bold mb-4">La vidéo a été téléchargée avec succès !</p>
                    <p className="text-gray-600 mb-4"><p className='font-bold'>Lien de la vidéo :</p> {copiedText}</p>
                    <button onClick={() => copyToClipboard(copiedText)} className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-700">
                        Copier dans le presse-papier
                    </button>
                    <div className="mt-4">
                        <Button onClick={onClose} color="primary" variant="contained" sx={{ mr: 2 }}>
                            Fermer
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    {tokenGoogleValid ? (
                        <>
                            <div className="mb-4">
                                <label className="block text-gray-600 text-sm font-bold mb-2">Titre :</label>
                                <input
                                    type="text"
                                    value={title}
                                    onChange={handleTitleChange}
                                    className="w-full border rounded p-2"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-600 text-sm font-bold mb-2">Description :</label>
                                <textarea
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    className="w-full border rounded p-2"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-600 text-sm font-bold mb-2">Sélectionner une vidéo :</label>
                                <input
                                    type="file"
                                    accept="video/*"
                                    onChange={handleFileChange}
                                    className="border rounded p-2"
                                />
                            </div>
                            <div>
                                <button
                                    disabled={disabledButtonWhenUpload}
                                    onClick={handleUpload}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                                >
                                    Télécharger la vidéo
                                </button>
                            </div>
                            {uploadProgress > 0 && (
                                <div className="mt-4">
                                    <p className="text-gray-600">Progression de l'upload : {uploadProgress}%</p>
                                    <div className="w-full bg-gray-200 h-2 mt-2 rounded">
                                        <div
                                            className="bg-blue-500 h-full rounded"
                                            style={{ width: `${uploadProgress}%` }}
                                        ></div>
                                    </div>
                                </div>
                            )}

                            {uploadProgress === 100 && errorUpload && (
                                <div className="mt-4">
                                    <p className="text-red-600 font-bold mb-4">Erreur lors du téléchargement de la vidéo</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <button
                            onClick={handleToken}
                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-green-700"
                        >
                            Se connecter à YouTube via Google
                            {<GoogleIcon />}
                        </button>
                    )}
                    <div className="mt-4">
                        <Button disabled={disabledButtonWhenUpload} onClick={onClose} color="primary" variant="contained" sx={{ mr: 2 }}>
                            Annuler
                        </Button>
                    </div>
                </>
            )}
        </div>

    );
};

export default UploadVideoForm;

