import Grid from "@mui/material/Unstable_Grid2";
import { Checkbox, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import mediaService from "../../services/media.service";
import AdminService from "../../services/admin.service";
import { toast } from "react-toastify";
import { IMAGE_URL } from "../../constants/url";
import Loader from "../../components/Loader";
import AlertDialog from "../../components/Alert";
import { useNavigate } from 'react-router-dom';

export default function FormAdmin({ name, setName, email, setEmail, password, setPassword, pictureId, setPictureId, proofreader, setProofreader, author, setAuthor, buttonName, buttonState, handleUpdate, pictureUrl, setPictureUrl, emailError, id = 0, loading, setLoading }) {

    const [openAlert, setOpenAlert] = useState(false);

    const navigate = useNavigate();

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    const handleFileChange = (event) => {

        setLoading(true);
        mediaService.uploadMedia(event.target.files[0])
            .then((response) => {
                setLoading(false);
                if (response.data && response.data.id && response.data.url) {
                    setPictureId(response.data.id);
                    setPictureUrl(response.data.url);
                    toast.success("Image uploadée avec succès", {
                        position: "top-right",
                        theme: "dark",
                    });
                } else {
                    toast.error("Erreur lors de l'upload de l'image", {
                        position: "top-right",
                        theme: "dark",
                    })
                }

            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                toast.error("Erreur lors de l'upload de l'image", {
                    position: "top-right",
                    theme: "dark",
                })
            });
    };

    const _handleDeleteAdmin = () => {
        setOpenAlert(true);
    }

    const handleDeleteAdmin = () => {
        if (!id) return;
        setLoading(true);
        //call api
        AdminService.deleteAdmin(id)
            .then((response) => {
                setLoading(false);
                if (response.data && response.data.success) {
                    toast.success("Utilisateur supprimé avec succès", {
                        position: "top-right",
                        theme: "dark",
                    })
                } else {
                    toast.error("Erreur lors de la suppression de l'utilisateur", {
                        position: "top-right",
                        theme: "dark",
                    })
                }
                navigate('/admin');
            })
            .catch((error) => {
                setLoading(false);
                toast.error("Erreur lors de la suppression de l'utilisateur", {
                    position: "top-right",
                    theme: "dark",
                })
                navigate('/admin');
            })
    }

    return (
        <Grid container spacing={2} sx={{ mt: 6, ml: 3, mr: 3 }}>
            {loading && <Loader />}
            <Grid xs={8}>
                <Grid xs={12}>
                    <Typography variant="caption" sx={{ color: "#16324D", fontSize: "16px", fontWeight: "bold" }} gutterBottom>
                        Auteur
                    </Typography>
                    <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginTop: "3px", marginBottom: "10px" }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Typography variant="caption" sx={{ color: "#16324D", fontSize: "16px", fontWeight: "bold" }} gutterBottom>
                        Adresse mail
                    </Typography>
                    <TextField
                        error={emailError}
                        helperText={emailError ? "Adresse mail invalide" : ""}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginTop: "3px", marginBottom: "10px" }}
                    />
                </Grid>
                <Grid xs={12}>
                    <Typography variant="caption" sx={{ color: "#16324D", fontSize: "16px", fontWeight: "bold" }} gutterBottom>
                        Mot de passe
                    </Typography>
                    <TextField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginTop: "3px", marginBottom: "10px", pb: 0, mb: 0 }}
                    />
                    <Typography variant="caption" sx={{ color: "#16324D", fontSize: "10px", fontStyle: "italic", mt: 0, pt: 0 }} gutterBottom>
                        Doit faire 8 caractères minimum
                    </Typography>
                </Grid>
                <Grid xs={12} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                    <Typography variant="caption" sx={{ color: "#16324D", fontSize: "16px", fontWeight: "bold", mb: 0, minWidth: "100px" }} gutterBottom>
                        Relecteur
                    </Typography>
                    <Checkbox
                        checked={proofreader}
                        color="secondary"
                        onChange={(e) => setProofreader(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
                <Grid xs={12} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                    <Typography variant="caption" sx={{ color: "#16324D", fontSize: "16px", fontWeight: "bold", mb: 0, minWidth: "100px" }} gutterBottom>
                        Auteur
                    </Typography>
                    <Checkbox
                        color="secondary"
                        checked={author}
                        onChange={(e) => setAuthor(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
            </Grid>
            <Grid xs={4}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="caption" sx={{ color: "#16324D", fontSize: "16px", fontWeight: "bold" }} gutterBottom>
                        Photo de profil
                    </Typography>
                    <input type="file" name="file" id="file" className={"inputfile"} onChange={handleFileChange} />
                    <label htmlFor="file"><strong>Ajouter une image</strong></label>

                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: "#F8F8F8" }}>
                    {/*if pictureUrl display pictureUrl else display box green*/}
                    {pictureUrl ? (
                        <img style={{ minWidth: "100px", minHeight: '100px', maxHeight: "300px", maxWidth: "300px" }} alt={'profil'} src={IMAGE_URL + pictureUrl} />
                    ) : (
                        <p>Pas d'image sur ce profil</p>
                    )}
                </Box>

            </Grid>
            <Button
                disabled={buttonState}
                sx={{ borderRadius: 2.5, boxShadow: 0, minWidth: "150px" }}
                color="secondary"
                size="medium"
                onClick={handleUpdate}
                variant="contained">
                {buttonName}
            </Button>
            <Button
                sx={{ borderRadius: 2.5, boxShadow: 0, minWidth: "150px", marginLeft: "30px", }}
                color="secondary"
                size="medium"
                variant="contained">
                <Link
                    to={"/admin"}
                    style={{ textAlign: "center", boxShadow: 0, color: "#FFF", textDecoration: "none", backgroundColor: "#0057BA", textTransform: "uppercase" }}
                    color="secondary"
                    size="medium"
                >
                    Annuler
                </Link>
            </Button>

            {id !== undefined && id !== 0 && (
                <Button
                    sx={{ borderRadius: 2.5, boxShadow: 0, minWidth: "150px", marginLeft: "30px", }}
                    color="error"
                    size="medium"
                    onClick={_handleDeleteAdmin}
                    variant="contained">
                    Supprimer utilisateur
                </Button>
            )}
            {openAlert && (
                <AlertDialog
                    open={openAlert}
                    handleClose={handleCloseAlert}
                    handleAgree={handleDeleteAdmin}
                    title={"Suppression d'utilisateur"}
                    label={"Etes-vous sûr de vouloir supprimer cet utilisateur ?"}
                    buttonAccept={"Supprimer"}
                    buttonCancel={"Annuler"}
                />
            )}
        </Grid>
    )
}