import React from 'react'
import Modal from '@mui/material/Modal';
import {
    Autocomplete,
    Box,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import { useState, useEffect } from 'react';
import instance from "../../../utils/axios";
import { IMAGE_URL } from "../../../constants/url";
import FreeSoloCreateOptionDialog from "./AutoCompleteCreateTag";
import ConfirmDeleteModal from '../../articles/components/ConfirmDeleteModal';
import mediaService from "../../../services/media.service";
import { toast } from "react-toastify";

export const EditImageModal = ({ open, handleClose, imageToEdit, setDataImages, idTheme }) => {

    const [dataForm, setDataForm] = useState({
        id: imageToEdit.id,
        tag_id: imageToEdit?.tags?.map(tag => tag.id),
        theme: [imageToEdit?.medias_has_themes?.themeId],
    });

    // delete Image
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleOpenModalDelte = () => {
        setOpenModalDelete(true);
    };
    const handleCloseModalDelete = () => {
        setOpenModalDelete(false);
    };
    const updateImageTheme = () => {
        mediaService.mediaByTheme(idTheme).then((res) => {
            if (res && res.status === 200 && res.data) {
                setDataImages(res.data.medias);
            } else {
                console.log('Erreur récupération article');
            }
        }
        )
            .catch((e) => {
                console.log('Erreur récupération article', e);

            })
    }
    const handleDeleteArticle = () => {
        mediaService.deleteMedia(imageToEdit?.id)
            .then(() => {
                handleCloseModalDelete();
                handleClose();
                updateImageTheme();

            })
            .catch(() => {
                handleCloseModalDelete();
            })
    }
    // 

    const [themesCreate, setThemesCreate] = useState([]);
    const [tagsCreate, setTagCreate] = useState([]);
    const [tags, setTags] = useState([]);

    const imageThemeId = imageToEdit?.medias_has_themes?.themeId;

    const findNameTheme = themesCreate.find(theme => theme.id === imageThemeId);

    const loadData = async () => {
        instance.get("articles/info-create")
            .then((res) => {
                if (res && res.status === 200 && res.data && res.data.tags && res.data.themes) {
                    setTagCreate(res.data.tags);
                    setThemesCreate(res.data.themes);
                } else {
                    console.log('Erreur récupération info auteur, theme et tag');
                }
            })
            .catch(() => {
                console.log('Erreur récupération info auteur, theme et tag');
            })
    }

    const handleSelectTheme = (event, newValue) => {
        if (newValue && newValue.id) {
            setDataForm({ ...dataForm, theme: [newValue.id] })
        } else {
            setDataForm({ ...dataForm, theme: [] })
        }
    }
    const handleSelectTags = (newValue) => {
        let arrayId = [];
        if (newValue && Array.isArray(newValue) && newValue.length > 0) {
            for (const newValueElement of newValue) {
                if (newValueElement) {
                    arrayId.push(newValueElement)
                }
            }
            setDataForm({ ...dataForm, tag_id: arrayId })
        } else {
            setDataForm({ ...dataForm, tag_id: [] })
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            loadData();
            setDataForm({
                id: imageToEdit.id,
                tag_id: imageToEdit?.tags?.map(tag => tag.id),
                theme: [imageToEdit?.medias_has_themes?.themeId]
            });
            setTags(imageToEdit.tags);
        };
        fetchData();
    }
        , [imageToEdit, open])

    const handleUpdateMedia = () => {
        mediaService.updateMedia({
            id: dataForm.id,
            tag: dataForm.tag_id,
            theme: dataForm.theme,

        })
            .then(() => {
                handleClose();
                updateImageTheme();
            })
            .catch(() => {
                toast.error("Erreur lors de la modification de l\'article", {
                    position: "top-right",
                    theme: "dark",
                })
            })
    };
    console.log("dataForm", dataForm);

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        padding: 50,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: "10px",
                        p: 4,
                        overflowY: "auto",
                    }}
                >
                    <Typography sx={{
                        color: "#16324D",
                    }} id="modal-modal-title" variant="h6" component="h2" mb={2}>
                        Modifier l'image
                    </Typography>


                    <img src={`${IMAGE_URL}` + imageToEdit.url} alt={`${1}`}
                        style={{ width: '100%', height: '368px', objectFit: 'cover', borderRadius: 4 }} />

                    <Box sx={{
                        mt: 4,
                        mb: 3,
                        display: "flex",
                    }}>
                        <label style={{ minWidth: "70px", marginTop: 8, fontWeight: "bold" }} htmlFor="number-input-height">Theme  </label>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-demo"
                            sx={{ width: "100%" }}
                            onChange={handleSelectTheme}
                            options={themesCreate}
                            value={findNameTheme}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField size="small" {...params} label={params.name} />}
                        />
                    </Box>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 4, paddingLeft: 0, paddingBottom: 0, paddingTop: 12 }}>
                        <label style={{ minWidth: "70px", fontWeight: "bold" }} htmlFor="number-input-height">Tags</label>
                        <div style={{ width: 670, maxWidth: '100%' }}>
                            <FreeSoloCreateOptionDialog
                                listTag={tagsCreate}
                                getInputValue={handleSelectTags}
                                reloadPage={loadData}
                                value={tags ? tags : imageToEdit?.tags}
                                setValue={setTags}
                                setDataArticle={setDataForm}
                                setTagCreate={setTagCreate}
                                dataArticle={dataForm}
                            />
                        </div>
                    </div>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "30px",

                    }}>
                        <Button
                            variant="contained"
                            color='error'
                            sx={{
                                marginLeft: "5px",
                                marginBottom: "10px",

                                color: "white",
                                paddingBottom: "6px",
                            }}
                            onClick={handleOpenModalDelte}
                        >

                            <span className="normal-case">Supprimer</span>

                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                marginLeft: "5px",
                                marginBottom: "10px",

                                color: "white",
                                paddingBottom: "6px",
                            }}
                            onClick={handleClose}
                        >
                            <span className="normal-case">Annuler</span>
                        </Button>

                        <Button
                            variant="contained"
                            color='success'
                            sx={{
                                marginLeft: "5px",
                                marginBottom: "10px",

                                color: "white",
                                paddingBottom: "6px",
                            }}
                            onClick={handleUpdateMedia}
                        >
                            <span className="normal-case">Enregistrer</span>
                        </Button>

                    </Box>

                </Box>

            </Modal>

            <ConfirmDeleteModal
                open={openModalDelete}
                onClose={handleCloseModalDelete}
                onConfirm={handleDeleteArticle}
            />

        </>
    )
}
