import React, { useEffect, useState } from "react";
import AdminService from "../../services/admin.service";
import Menu from "../../components/layout/Menu";
import { frFR, GridToolbar } from "@mui/x-data-grid";
import Grid from '@mui/material/Unstable_Grid2';
import { StripedDataGrid } from "../../constants/tableStyle";
import Action from "../../components/Action";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";


export default function AllAdmins() {
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(false);
    const breadcrumbArray = [
        { name: "Liste des utilisateurs", to: "/admin" },

    ];
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const columns = [
        {
            field: "actions",
            headerName: "#",
            width: 110,
            renderCell: (params) => {

                return (
                    <Action
                        notificationActivate={false}
                        editActivate={true}
                        editLink={`/admin/${params.id}`}
                        idArticle={params.id}
                    />
                );
            },
        },
        {
            field: "id",
            headerName: "ID",
            minWidth: 120,
        },
        {
            field: "account",
            headerName: "Email",
            minWidth: 300,
        },
        {
            field: "name",
            headerName: "Auteur",
            minWidth: 200,
        },
        {
            field: "is_proofreader",
            headerName: "Relecteur",
            width: 100,
            valueGetter: (params) => {
                return params.value ? "Oui" : "Non";
            }
        },
        {
            field: "is_author",
            headerName: "Auteur",
            width: 100,
            valueGetter: (params) => {
                return params.value ? "Oui" : "Non";
            }
        },
        {
            field: "createdAt",
            headerName: "Date de création",
            minWidth: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const options = {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);
                localDateString = localDateString.replace(
                    /(\w)(\w*)/g,
                    function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase();
                    }
                ); // Up the first letter in Uppercase
                return localDateString;
            },
        },
    ];
    useEffect(() => {
        setLoading(true);
        AdminService.allAdmin()
            .then((res) => {
                setLoading(false);
                if (res.data) {
                    setAdmins(res.data);
                } else {
                    toast.error("Erreur lors de la récupération des données")
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error("Erreur lors de la récupération des données")
            });
    }, []);

    return (
        <Menu category="Utilisateurs" breadCrumb={breadcrumbArray} link="/admin/create" buttonText="Créer un admin">
            {loading && <Loader />}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StripedDataGrid
                        rows={admins}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        className="mx-3"
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Grid>
            </Grid>
        </Menu>
    )
}