import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Popover,
    Typography,
    Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import tvService from "../../services/tv.services";
import CustomModalSuccess from '../../pages/articles/components/CustomModalSuccess';
import ConfirmDeleteModal from '../../pages/articles/components/ConfirmDeleteModal';





const LiveShowForm = ({ liveYoutubeHaveItems, setLiveYoutubeHaveItems }) => {
    const [errorTitle, setErrorTitle] = useState(false);
    const [errorURL, setErrorURL] = useState(false);
    const [newItemTitle, setNewItemTitle] = useState('');
    const [newItemURL, setNewItemURL] = useState('');


    const liveLinkConverter = (link) => {
        if (link.includes('/live/')) {
            const id = link.split('/live/')[1].split('?')[0];
            return `https://www.youtube.com/watch?v=${id}`;
        }
        return link;
    };



    const [items, setItems] = useState([]);

    const [editIndex, setEditIndex] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const [editItemTitle, setEditItemTitle] = useState('');
    const [editItemURL, setEditItemURL] = useState('');
    const [indexToDelete, setIndexToDelete] = useState(null);


    const [isItemAdded, setIsItemAdded] = useState(false);

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));
    const [openModalConfirmModal, setOpenModalConfirmModal] = useState(false);

    const handleCloseModalConfirmModal = () => {
        setOpenModalConfirmModal(false);
    };
    const handleOpenModalConfirmModal = (index) => {
        setIndexToDelete(index)
        setOpenModalConfirmModal(true);
    };

    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const handleCloseModalSuccess = () => { setOpenModalSuccess(false); };
    const handleOpenModalSuccess = () => setOpenModalSuccess(true);

    const [openModalSuccessDelete, setOpenModalSuccessDelete] = useState(false);

    const handleCloseModalSuccessDelete = () => { setOpenModalSuccessDelete(false); };
    const handleOpenModalSuccessDelete = () => setOpenModalSuccessDelete(true);

    // edit success modal
    const [openModalSuccessEdit, setOpenModalSuccessEdit] = useState(false);
    const handleCloseModalSuccessEdit = () => { setOpenModalSuccessEdit(false); };
    const handleOpenModalSuccessEdit = () => setOpenModalSuccessEdit(true);

    useEffect(() => {
        tvService.getLiveYoutubeUrl().then((response) => {
            const data = response.data[0];
            if (data === undefined) {
                return;
            }
            setItems([{ id: data.id, title: data.title, url: data.url }]);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleAddItem = () => {
        // verify if url youtube is valid
        const youtubeUrl = liveLinkConverter(newItemURL);
        const youtubeUrlRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
        let is_valid = true;
        if (!youtubeUrlRegex.test(youtubeUrl)) {
            setErrorURL(true);
            is_valid = false;
        } else {
            setErrorURL(false);
        }

        if (newItemTitle.trim() === '' || newItemURL.trim() === '') {
            setErrorTitle(true);
            is_valid = false;
        } else {
            setErrorTitle(false);
        }
        if (is_valid) {
            if (!isItemAdded && newItemTitle.trim() !== '' && newItemURL.trim() !== '') {
                tvService.createLiveYoutube({ title: newItemTitle, url: liveLinkConverter(newItemURL), is_active: true }).then((response) => {

                    setItems([{ id: response.data.liveTvShow.id, title: newItemTitle, url: liveLinkConverter(newItemURL) }]);
                    handleOpenModalSuccess();

                    setIsItemAdded(true);
                    setNewItemTitle('');
                    setNewItemURL('');
                }
                ).catch((error) => {
                    console.log(error);
                });
            }
        }

    };

    const handleSaveEditItem = () => {

        const youtubeUrlRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
        let is_valid = true;
        if (!youtubeUrlRegex.test(liveLinkConverter(editItemURL))) {
            setErrorURL(true);
            is_valid = false;
        } else {
            setErrorURL(false);
        }

        if (editItemTitle.trim() === '' || editItemURL.trim() === '') {
            setErrorTitle(true);
            is_valid = false;
        } else {
            setErrorTitle(false);
        }
        if (is_valid) {
            if (editItemTitle.trim() !== '' && editItemURL.trim() !== '') {
                if (editIndex !== null) {
                    const updatedItems = [...items];
                    updatedItems[editIndex] = { id: updatedItems[editIndex].id, title: editItemTitle, url: editItemURL };
                    tvService.updateLiveYoutube({ id: updatedItems[editIndex].id, title: editItemTitle, url: liveLinkConverter(editItemURL), is_active: true }).then((response) => {
                        if (response.status === 200) {
                            handleOpenModalSuccessEdit();
                            setItems(updatedItems);
                            setEditIndex(null);
                            setEditItemTitle('');
                            setEditItemURL('');
                            setAnchorEl(null);
                        }
                    }
                    ).catch((error) => {
                        console.log(error);
                    }
                    );
                }
            }
        }
    };

    const handleEditItem = (index, event) => {
        setEditIndex(index);
        setEditItemTitle(items[index].title);
        setEditItemURL(liveLinkConverter(items[index].url));
        setAnchorEl(event.currentTarget);
    };
    const handleDeleteItem = () => {
        tvService.deleteLiveYoutube(items[indexToDelete].id).then((response) => {
            handleCloseModalConfirmModal();
            handleOpenModalSuccessDelete();
            const updatedItems = [...items];
            updatedItems.splice(indexToDelete, 1);
            setItems(updatedItems);
            setIsItemAdded(false);
        }
        ).catch((error) => {
            console.log(error);
        });
    };

    const handleClosePopover = () => {
        setErrorURL(false);
        setErrorTitle(false);
        setEditIndex(null);
        setEditItemTitle('');
        setEditItemURL('');
        setAnchorEl(null);
    };
    useEffect(() => {
        if (items.length === 0) {
            setLiveYoutubeHaveItems(false);
        } else {

            setLiveYoutubeHaveItems(true);
        }
    }, [items]);


    const openPopover = Boolean(anchorEl);
    return (
        <div className='ml-2 mt-1'>
            {items.length === 0 && (
                <>
                    <TextField
                        label="Titre Live youtube Maritima"
                        value={newItemTitle}
                        onChange={(e) => setNewItemTitle(e.target.value)}
                        fullWidth
                        variant="outlined"
                        error={errorTitle}
                        errorMessage="Le titre est obligatoire"
                        sx={{ marginTop: "3px", marginBottom: "10px" }}
                    />
                    <TextField
                        label="URL Live Tv youtube Maritima"
                        value={newItemURL}
                        onChange={(e) => setNewItemURL(e.target.value)}
                        fullWidth
                        error={errorURL}
                        errorMessage="URL youtube invalide"
                        variant="outlined"
                        sx={{ marginTop: "3px", marginBottom: "10px" }}
                    />
                    <Button variant="contained" color="secondary" onClick={handleAddItem}>
                        Ajouter Live
                    </Button>
                </>
            )}
            <List>
                {items.map((item, index) => (
                    <ListItem key={index}>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            variant="dot"
                            sx={{ marginRight: 1 }}
                        ></StyledBadge>
                        <ListItemText primary={<span className='font-bold'>{item.title}</span>} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" onClick={(e) => handleEditItem(index, e)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleOpenModalConfirmModal(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>

            <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2}>
                    <Typography variant="h6" gutterBottom>
                        Modifier l'élément TV-Flux
                    </Typography>
                    <TextField
                        label="Titre Live youtube Maritima"
                        value={editItemTitle}
                        onChange={(e) => setEditItemTitle(e.target.value)}
                        fullWidth
                        error={errorTitle}
                        errorMessage="Le titre est obligatoire"
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        label="URL Live Tv youtube Maritima"
                        value={editItemURL}
                        onChange={(e) => setEditItemURL(e.target.value)}
                        fullWidth
                        error={errorURL}
                        errorMessage="URL youtube invalide"
                        margin="normal"
                        variant="outlined"
                    />
                    <Button sx={{ marginRight: 1 }} variant="contained" color="success" onClick={handleSaveEditItem}>
                        Enregistrer
                    </Button>

                    <Button variant="contained" color="error" onClick={handleClosePopover}>
                        Annuler
                    </Button>
                </Box>
            </Popover>
            <ConfirmDeleteModal
                open={openModalConfirmModal}
                onClose={handleCloseModalConfirmModal}
                onConfirm={handleDeleteItem}
            />

            <CustomModalSuccess
                open={openModalSuccessDelete}
                onClose={handleCloseModalSuccessDelete}
                title={"Succès"}
                description={"Le live a été supprimé avec succès"}

            />

            <CustomModalSuccess
                open={openModalSuccess}
                onClose={handleCloseModalSuccess}
                title={"Succès"}
                description={"Le live a été creé avec succès"}

            />
            <CustomModalSuccess
                open={openModalSuccessEdit}
                onClose={handleCloseModalSuccessEdit}
                title={"Succès"}
                description={"Le live a été modifié avec succès"}

            />
        </div>

    );
};

export default LiveShowForm;
