import React, { useState, useEffect } from 'react';
import Menu from "../../components/layout/Menu";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/fr';
import AppointmentEventTv from "./components/AppoinmentEventTv";
import { Button, CircularProgress } from '@mui/material';
import ModalSuccess from '../../components/ModalSuccess';
import tvService from '../../services/tv.services';
import CreateShowModalTv from './components/CreateShowModalTv';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';

const TvProgramGrille = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [tvProgramsFromServer, setTvProgramsFromServer] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const handleOpenModal = () => {
        setIsModalOpen(true);
    }

    useEffect(() => {
        setIsLoading(true)
        tvService.getAllTvProgram().then((response) => {
            setTvProgramsFromServer(modifiedData(response.data))
            setIsLoading(false)
        }
        );
    }, []);

    moment.locale('fr');
    const localizer = momentLocalizer(moment)

    const MyCalendar = (props) => (
        <div>
            <Calendar
                {...props}
                localizer={localizer}
                defaultDate={new Date(2024, 0, 1)}
                events={tvProgramsFromServer}
                startAccessor="start"
                endAccessor="end"
                defaultView='week'
                style={{ height: 850 }}
            />
        </div>
    )
    const components = {
        event: (props) => {
            if (props.event) {
                return <AppointmentEventTv modifiedData={modifiedData}
                    setRadioProgramsFromServer={setTvProgramsFromServer} appointment={props.event} />
            }
        }
    }

    const breadcrumbArray = [
        { name: "TV Martima", to: "/tv" },
        { name: "Programme TV ", to: "/programtv" },
    ];
    const daysOfWeek = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6
    };
    const today = new Date(2024, 0, 1);
    const currentDayIndex = today.getDay();

    const modifiedData = (data) => {
        const result = data.map(data => {
            const daysDifference = daysOfWeek[data.days] - currentDayIndex;
            const startDate = new Date(today);
            startDate.setDate(today.getDate() + daysDifference);
            if (startDate < today) {
                startDate.setDate(startDate.getDate() + 7);
            }
            const [startHours, startMinutes] = data.date_start.split(':');
            startDate.setHours(startHours, startMinutes, 0, 0);

            let endDate;
            if (data.date_end) {
                const [endHours, endMinutes] = data.date_end.split(':');
                endDate = new Date(startDate);
                endDate.setHours(endHours, endMinutes, 0, 0);
            } else {
                endDate = new Date(startDate);
                endDate.setHours(endDate.getHours() + 1);
            }
            return {
                id: data.id,
                title: data.title,
                description: data.description,
                days: data.days,
                start: startDate,
                end: endDate
            };
        });
        return result;
    }
    return (
        <Menu category="Tv Programme Maritima" breadCrumb={breadcrumbArray} >
            {isLoading ? (
                <CircularProgress sx={{ position: "absolute", top: 400, left: 1000 }} />
            ) : (
                <>
                    <Button variant="contained" color="primary" onClick={handleOpenModal}>
                        <span className='mr-3'>Ajouter un programme </span>    {<AddAlarmIcon />}
                    </Button>
                    <div className="app">
                        <MyCalendar
                            components={components}
                        />

                    </div>
                </>
            )}
            <CreateShowModalTv
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                openSuccessModal={() => setIsModalSuccessOpen(true)}
                closeSuccessModal={() => setIsModalSuccessOpen(false)}
                setRadioProgramsFromServer={setTvProgramsFromServer}
                modifiedData={modifiedData}

            />
            <ModalSuccess title={"Succès"} description={"Le programme a été enregistré avec succès."} open={isModalSuccessOpen} onClose={() => setIsModalSuccessOpen(false)} />
        </Menu>

    )
}


export default TvProgramGrille;