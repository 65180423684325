import React, { useEffect, useState } from "react";
import UserService from "../services/user.service";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  frFR,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { StripedDataGrid } from "../constants/tableStyle";
import { useTranslation } from "react-i18next";
import Menu from "../components/layout/Menu";

const BoardUser = () => {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    UserService.getUserBoard().then(
      (response) => {
        if (response?.data?.allUsers && response?.data?.allUsers.length > 0) {
          setRows(response?.data?.allUsers);
        }
      },
      (error) => {
        console.log("dans l error ");
      }
    )
      .catch((error) => console.log(error));
  }, []);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "firstName",
      headerName: "Prénom",
      minWidth: 150,
    },
    {
      field: "lastName",
      headerName: "Nom",
      minWidth: 150,
    },
    {
      field: "birthDate",
      headerName: "Age",
      minWidth: 100,
      valueGetter: (params) => {
        const birthDate = new Date(params.value);
        const ageDifMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
    },
    {
      field: "linkConfirmationEmail",
      headerName: "Email confirmé",
      minWidth: 150,
      valueGetter: (params) => {
        if (!params.value) {
          return "Oui";
        } else {
          return "Non";
        }
      },
    },
    {
      field: "fullName",
      headerName: "Nom complet",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      minWidth: 200,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "createdAt",
      headerName: "Créé le",
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        let localDateString = date.toLocaleDateString("fr-FR", options);
        localDateString = localDateString.replace(
          /(\w)(\w*)/g,
          function (g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
          }
        ); // Up the first letter in Uppercase
        return localDateString;
      },
    },
  ];

  const redirectOnPage = (id) => {
    const idString = id.toString();
    const params = { id: idString };
    navigate({
      pathname: "/page_user",
      search: `?${createSearchParams(params)}`,
    });
  };
  const breadcrumbArray = [
    { name: "Gestion des utilisateurs", to: "/users" },
  ];

  return (
    <Menu category="Administration" label="Gestion des utilisateurs">
      <StripedDataGrid
        className="mx-5"
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          toolbar: GridToolbar,
        }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        onRowClick={(params, event) => {
          redirectOnPage(params.id);
        }}
      />
    </Menu>
  );
};

export default BoardUser;
