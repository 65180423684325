import React, { useEffect, useState } from 'react';
import Menu from "../../components/layout/Menu";
import { useNavigate, useParams } from "react-router-dom";
import mediaService from "../../services/media.service";
import Box from "@mui/material/Box";
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, Chip, Fab, Grid, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Paper from "@mui/material/Paper";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { IMAGE_URL } from "../../constants/url";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { EditImageModal } from "./components/EditImageModal";


const MediasByTheme = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Récupère l'ID de l'URL
    const [data, setData] = useState([]);
    const [dataSave, setDataSave] = useState([]);
    const [themeName, setThemeName] = useState('')
    const [copiedStates, setCopiedStates] = useState([]);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState({ id: '', title: '' });

    // modal edit image
    const [open, setOpen] = useState(false);
    const [imageToEdit, setImageToEdit] = useState([]);
    const handleOpenModal = ({ data }) => {
        setImageToEdit(data);
        setOpen(true)
    };
    const handleCloseModal = () => setOpen(false);

    const handleCopyClick = ({ index, imageUrl }) => {
        console.log("imageUrl", imageUrl);
        navigator.clipboard.writeText(imageUrl).then(() => {

            const newCopiedStates = [...copiedStates];
            newCopiedStates[index] = true;
            setCopiedStates(newCopiedStates);
            setTimeout(() => {
                setCopiedStates([]);
            }, 2000);
        });
    };

    const handleClickAddMedia = () => {
        navigate(`/medias/create`);
    }

    const handleClickChip = (data) => {
        if (data && data.id) {
            const filteredData = dataSave.filter(item => {
                const tagWithId1 = item.tags.find(tag => tag.id === data.id);
                return tagWithId1 !== undefined;
            });
            if (filteredData && Array.isArray(filteredData) && filteredData.length > 0) {
                setData(filteredData)
                setSelectedValue({ id: data.id, title: data.name })
            }
        }
    }

    const handleSelectionChange = (event, newValue) => {
        setSelectedValue(newValue);
        if (newValue && newValue.id) {
            const filteredData = dataSave.filter(item => {
                const tagWithId1 = item.tags.find(tag => tag.id === newValue.id);
                return tagWithId1 !== undefined;
            });
            if (filteredData && Array.isArray(filteredData) && filteredData.length > 0) {
                setData(filteredData)
            }
        } else {
            setData(dataSave);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (!selectedValue || !selectedValue.id) {
                setSelectedValue({ id: '', title: '' });
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        mediaService.mediaByTheme(id)
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    if (res.data && res.data.medias && Array.isArray(res.data.medias) && res.data.medias.length > 0) {
                        const listMedias = res.data.medias;
                        setData(res.data.medias);
                        setDataSave(res.data.medias);
                        setThemeName(`${res.data.name ? res.data.name : ""}`);
                        for (const listMedia of listMedias) {
                            if (listMedia.tags && Array.isArray(listMedia.tags) && listMedia.tags.length > 0) {
                                const tagsList = listMedia.tags;
                                for (const tagList of tagsList) {
                                    if (tagList.id && tagList.name) {
                                        const existingTag = tags.find((tag) => tag.id === tagList.id);
                                        if (!existingTag) {
                                            const newTag = {
                                                id: tagList.id,
                                                title: tagList.name,
                                            };
                                            tags.push(newTag);
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        toast.error("Pas d'image pour le moment", {
                            position: "top-right",
                            theme: "dark",
                        })
                    }
                } else {
                    toast.error("Une erreur est survenue", {
                        position: "top-right",
                        theme: "dark",
                    })
                }
            })
            .catch(() => {
                setLoading(false);
                toast.error("Une erreur est survenue", {
                    position: "top-right",
                    theme: "dark",
                })
            })
    }, []);
    const breadcrumbArray = [
        { name: "Bibliothèque", to: "/medias" },
        { name: themeName, to: `/medias/theme/${id}` },
    ];

    return (
        <Menu category="Bibliothèque d'images" breadCrumb={breadcrumbArray}>
            {loading && <Loader />}
            <Box className={"my-5"}>
                <Grid container
                    sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginBottom: 5 }}>
                    <Grid key={1} item xs={9}>
                        <Autocomplete
                            disablePortal
                            id="free-solo-2-demo"
                            options={tags}
                            value={selectedValue}
                            onKeyPress={handleKeyPress}
                            getOptionLabel={(option) => option.title}
                            onChange={handleSelectionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Rechercher"
                                    id="outlined-start-adornment"
                                    sx={{ width: "100%", backgroundColor: "#F8F8F8" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid sx={{ paddingLeft: 5, display: "flex", justifyContent: "flex-end" }} key={2} item xs={3}>
                        <Button
                            sx={{ borderRadius: 2.5, boxShadow: 0, textTransform: "none" }}
                            onClick={handleClickAddMedia}
                            color="secondary"
                            variant="contained"
                            startIcon={<AddCircleIcon />}
                        >
                            Ajouter une image
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    {data.map((image, index) => (
                        <Grid item xs={10} sm={6} md={3} key={index}>
                            <Paper variant="outlined" style={{
                                boxShadow: "none",
                                padding: '8px',
                                textAlign: 'center',
                                height: '13rem',
                                overflow: "hidden",
                                position: "relative"
                            }}>
                                <img src={`${IMAGE_URL}` + image.url} alt={`${index + 1}`}
                                    style={{ width: '100%', height: '80%', objectFit: 'cover', borderRadius: 4 }} />
                                <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                    <Tooltip title="Copier URL" placement="right">
                                        <Fab
                                            id={`copy-${index}`}
                                            sx={{
                                                color: copiedStates[index] ? "white" : "grey",
                                                height: "25px", width: "25px", minHeight: 25,
                                                backgroundColor: (theme) => copiedStates[index] ? theme.palette.secondary.main : "#F2F4F7", // Rouge ou vert
                                                '&:hover': {
                                                    backgroundColor: (theme) => copiedStates[index] ? theme.palette.secondary.main : "#F2F4F7", // Assure que le hover respecte le comportement désiré
                                                },
                                                '&:active': {
                                                    backgroundColor: (theme) => theme.palette.secondary.main,
                                                },
                                                transition: "background-color 0.3s"
                                            }}
                                            aria-label="add"
                                            onClick={() => handleCopyClick({ index: index, imageUrl: IMAGE_URL + image.url })}>
                                            <FileCopyOutlinedIcon
                                                sx={{
                                                    height: "15px",
                                                    width: "15px",
                                                    transform: copiedStates[index] ? "scale(1.2)" : "scale(1)",
                                                    transition: "color 0.3s, transform 0.3s",
                                                }}
                                            />
                                        </Fab>

                                    </Tooltip>
                                    <Button

                                    >
                                        <Fab sx={{
                                            color: copiedStates[index] ? "white" : "grey",
                                            height: "25px", width: "25px", minHeight: 25,
                                            backgroundColor: "#F2F4F7",
                                            transition: "background-color 0.3s"
                                        }}
                                            aria-label="add"
                                            onClick={() =>
                                                handleOpenModal({ data: image })}
                                        >
                                            <ModeEditIcon
                                                sx={{ color: "#16324D", fontSize: 20 }}
                                            />
                                        </Fab>

                                    </Button>
                                </div>
                                <div style={{ maxHeight: '20%', overflowY: 'auto' }}>
                                    <Stack direction="row" sx={{ flexWrap: 'wrap', paddingTop: 1 }}>
                                        {(Array.isArray(image.tags) && image.tags.length > 0) ?
                                            image.tags.map((tag, index) => (
                                                <Chip
                                                    key={index}
                                                    onClick={() => handleClickChip(tag)}
                                                    sx={{
                                                        height: "unset",
                                                        paddingX: "5px",
                                                        paddingY: '1px',
                                                        marginRight: "5px",
                                                        marginBottom: "5px",
                                                        borderRadius: 2,
                                                        backgroundColor: "rgba(3, 169, 244, 0.05)",
                                                        color: (theme) => theme.palette.secondary.light,
                                                        border: 0.2,
                                                        borderColor: (theme) => theme.palette.secondary.light,
                                                        '& .MuiChip-label': {
                                                            padding: '0px', // Ajuste le padding du label ici
                                                        },

                                                    }} label={tag.name} />
                                            ))
                                            : null
                                        }
                                    </Stack>
                                </div>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <EditImageModal
                open={open}
                imageToEdit={imageToEdit}
                handleClose={handleCloseModal}
                dataImages={data}
                setDataImages={setData}
                idTheme={id}
            />
        </Menu>
    )

}
export default MediasByTheme;