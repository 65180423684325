import instance from "../utils/axios";

const sendNotification = (notification) => {
  return instance.post(
    "admin/sendPushByAdmin",
    { notification },
    { withCredentials: true }
  );
};

const NotificationServices = {
  sendNotification,
};

export default NotificationServices;
