import React, { useEffect, useState } from 'react'
import Menu from "../../components/layout/Menu";
import CustomListPodcastTV from "../../components/ListPodcastTV/ListPodCastAndTv";
import tvService from "../../services/tv.services";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { Button } from '@mui/material';
import { Link } from "react-router-dom";
import {IMAGE_URL} from '../../constants/url';

const TvList = () => {
    const [loading, setLoading] = useState(false);
    const breadcrumbArray = [
        { name: "Tableau de bord", to: "/dashboard" },
        { name: "TV", to: "/tv" },
    ];
    const [tvShow, setTvShow] = useState([]);
    useEffect(() => {
        setLoading(true)
        tvService.getTvShow()
            .then((response) => {
                setLoading(false)
                setTvShow(response.data);
            })
            .catch((error) => {
                setLoading(false)
                toast.error('Erreur lors de la récupération des données', {
                    position: "top-right",
                    theme: "dark",
                })
            })
    }, []);
    const data = tvShow.map((item) => {
        return {
            id: item.id,
            name: item.title,
            link: `/tv/${item.id}`,
            image: `${IMAGE_URL}${item.media?.url}?w=248&fit=crop&auto=format`,
            imageId: item.media?.id,
        };
    }
    );

    return (
        <Menu category="TV Maritima" breadCrumb={breadcrumbArray} link="/tv/program" buttonText="Programme TV"  >
            {loading ? (
                <Loader />
            ) : (
                <>

                    <CustomListPodcastTV data={data} isTv={true} />
                </>
            )}
        </Menu>


    )
}

export default TvList;
