import instance from "../utils/axios";

class tv {
    constructor() {
        this.instance = instance;
    }

    getTvShow = async () => {
        return await instance.get("tv-show/all", {
            withCredentials: true,
        });
    };

    getAllTvReplayByTvShow = async (id) => {
        return await instance.get(`replay-tv/all?tv_show_id=${id}`, {
            withCredentials: true,
        });
    };

    getTvReplay = async (id) => {
        return await instance.get(`replay-tv/one?id=${id}`, {
            withCredentials: true,
        });
    };

    updateTvReplay = async (values) => {
        return await instance.put("replay-tv/update", values, {
            withCredentials: true,
        });
    };
    createTvReplay = async (data) => {
        return await instance.post(
            "replay-tv/create",
            data, {
            withCredentials: true,
        }
        );
    };

    deleteTvReplay = async (id) => {
        return await instance.delete(`replay-tv/delete?id=${id}`, {
            withCredentials: true,
        });
    };

    getAllTvProgram = async () => {
        return await instance.get("tv-program/all", {
            withCredentials: true,
        });
    }

    createTvProgram = async (data) => {
        return await instance.post("tv-program/create", data, {
            withCredentials: true,
        });
    }

    updateTvProgram = async (data) => {
        return await instance.put("tv-program/edit", data, {
            withCredentials: true,
        });
    }

    deleteTvProgram = async (id) => {
        return await instance.delete(`tv-program/delete`, {
            data: { id },
            withCredentials: true,
        });
    }

    getLiveYoutubeUrl = async () => {
        return await instance.get("live-tv-show/all", {
            withCredentials: true,
        });
    };
    createLiveYoutube = async (data) => {
        return await instance.post("live-tv-show/create", data, {
            withCredentials: true,
        });
    };
    updateLiveYoutube = async (data) => {
        return await instance.put("live-tv-show/update", data, {
            withCredentials: true,
        });
    };
    deleteLiveYoutube = async (id) => {
        return await instance.delete(`live-tv-show/delete?id=${id}`, {
            withCredentials: true,
        });
    };




}

var tvService = new tv();
export default tvService;
