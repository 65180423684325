import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, MobileStepper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import AutoPlaySwipeableViews from 'react-swipeable-views';
import mediaService from "../../../services/media.service";
import { IMAGE_URL } from "../../../constants/url";


const CoverImageSelector = ({ infoCreate, setInfoCreate, dataArticle, openMediaPicker, setOpen, handleOpenCroppImage }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [maxSteps, setMaxSteps] = useState(0);
    const [mapImages, setMapImages] = useState([]);

    const addImageDesactivatedToInfoCreate = (arrayOfImages) => {
        // if array of images is empty return arrayInfoCreatex
        if (arrayOfImages === undefined) {
            return null;
        } else {
            // push the images to the arrayInfoCreate if the image is not already in the arrayInfoCreate
            for (let i = 0; i < arrayOfImages.length; i++) {
                if (!infoCreate.media.some((item) => item.id === arrayOfImages[i].id)) {
                    infoCreate.media.push(arrayOfImages[i]);
                }
            }
            return infoCreate.media;
        }
    };

    useEffect(() => {
        addImageDesactivatedToInfoCreate(dataArticle?.mediaItems)
        const findImageInMedias = infoCreate.media.filter((media) => dataArticle.media_id.includes(media.id));
        const images = findImageInMedias.map((media, index) => ({
            label: `image${index}`,
            imgPath: `${IMAGE_URL}${media?.url}?w=248&fit=crop&auto=format`,
        }));

        setMaxSteps(images.length);
        setMapImages(images);

    }, [infoCreate.media, dataArticle.media_id]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleImageUpload = async (e) => {
        try {
            const response = await mediaService.uploadMedia(e.target.files[0]);
            if (response.data) {
                setInfoCreate({
                    ...infoCreate,
                    media: [...infoCreate.media, response.data],
                });
                setOpen(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: '#16324D', marginRight: '5px' }}>
                    Image de couverture
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    <Button
                        sx={{ borderRadius: 1, boxShadow: 0, textTransform: 'none', marginRight: '8px' }}
                        color="secondary"
                        size="medium"
                        variant="contained"
                        component="label"
                        onClick={handleOpenCroppImage}
                    >
                        Ajouter une image
                        {/* <input type="file" hidden onChange={(e) => handleOpenCroppImage()} /> */}
                    </Button>
                    <Button
                        sx={{ borderRadius: 1, boxShadow: 0, textTransform: 'none' }}
                        color="greyButton"
                        size="medium"
                        variant="contained"
                        onClick={openMediaPicker}
                    >
                        Bibliothèque
                    </Button>
                </Box>
            </Box>
            <div className="max-w-sm h-[170px] mt-4 ml-10">
                {mapImages.length > 0 ? (
                    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                        <AutoPlaySwipeableViews
                            autoplay={false}
                            axis="x"
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {mapImages.map((step, index) => (
                                <div key={step.label}>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 170,
                                                display: 'block',
                                                maxWidth: 400,
                                                overflow: 'hidden',
                                                width: '100%',
                                            }}
                                            src={step.imgPath}
                                            alt={step.label}
                                        />
                                    ) : null}
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                    Next
                                    {<KeyboardArrowRight />}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {<KeyboardArrowLeft />}
                                    Back
                                </Button>
                            }
                        />
                    </Box>
                ) : (
                    <div className="flex items-center justify-center w-full h-full bg-gray-200">
                        <p className="text-gray-500">Aucune image</p>
                    </div>
                )}
            </div>


        </>
    );
};

export default CoverImageSelector;
