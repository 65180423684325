import instance from "../utils/axios";

class regiePub {
    constructor() {
        this.instance = instance;
    }

    getAllPub = async () => {
        return await instance.get("app-design/all", {
            withCredentials: true,
        });
    }

    getPubById = async (id) => {
        return await instance.get(`app-design/one?id=${id}`, {
            withCredentials: true,
        });
    }

    uploadImageForPub = async ({ data, size, link, archH = false, archR = false, archL = false }) => {

        const formData = new FormData();
        formData.append("files", data);
        formData.append("size", size);
        formData.append("link", link);
        if (archH) {

            formData.append("arch_horizontal", archH);
        }
        if (archR) {

            formData.append("arch_vertical_left", archR);
        }
        if (archL) {

            formData.append("arch_vertical_right", archL);
        }

        try {
            const response = await instance.post(
                "app-design/upload-images",

                formData,

                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.log("error", error);
        }
    };

    createCampaignPub = async (data) => {
        return await instance.post("app-design/create", data, {
            withCredentials: true,
        });
    }

    deletePub = async (id) => {
        return await instance.delete(`app-design/delete?id=${id}`, {
            withCredentials: true,
        });
    }
    updatePub = async (data) => {
        return await instance.put("app-design/update", data, {
            withCredentials: true,
        });
    }
    updateImagesPub = async (data) => {
        return await instance.put("app-design/update-medias", {
            medias: data

        }, {
            withCredentials: true,
        });
    }

}

var regiePubService = new regiePub();
export default regiePubService;
