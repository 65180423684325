import React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import Login from "./pages/Login";
import Register from "./pages/Register";
import BoardUser from "./pages/BoardUser";
import UserById from "./pages/UserById";
import Notification from "./pages/notification/Notification.create";

// import AuthVerify from "./common/AuthVerify";
import Dashboard from "./pages/Dashboard";
import ArticlesTable from "./pages/articles/Articles.table";
import AdminTable from "./pages/admin/Admin.table";
import AdminDetail from "./pages/admin/Admin.detail";
import AdminCreate from './pages/admin/Admin.create';
import ArticlesCreate from "./pages/articles/Articles.create";
import DispatchesTable from "./pages/dispatches/Dispatches.table";
import ArticleDetail from "./pages/articles/Articles.detail";
import MediasLibrary from "./pages/medias/Medias.list";
import MediasByTheme from "./pages/medias/Medias.theme";
import PodcastList from "./pages/podcast/Podcast.list";
import NewsletterHome from "./pages/newsletter/Newsletter.home";
import AnnexPages from "./pages/annex_pages/AnnexPages";
import { EditCreatePodcastTv } from "./components/ListPodcastTV/EditCreatePodcastTv";
import TvList from "./pages/tv/TvList";
import { TvAnsPodcastTableList } from "./components/ListPodcastTV/TableListTvAndPodcast";
import MediasCreate from "./pages/medias/Medias.create";
import TvProgramGrille from "./pages/tv/TvProgram";
import { GoogleOAuthProvider } from '@react-oauth/google';
import RadioProgram from "./pages/radio/RadioProgram";
import PrivateRoute from "./PrivateRoute";
import Agenda from "./pages/agenda/Agenda";
import EditCreateEvent from "./pages/agenda/EditAndCreateEvent";
import RegiePub from "./pages/regiePub/regiePub";
import EditCreateRegiePub from "./pages/regiePub/EditAndCreateRegiePub";

i18n.use(initReactI18next).init({
  lng: "fr",
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
  },
});


const App = () => {
  console.log('dans app')
  const apiKey = process.env.REACT_APP_API_KEY;
  console.log(`API Key: ${apiKey}`);
  console.log(`REACT_APP_NODE_ENV: ${process.env.REACT_APP_NODE_ENV}`);
  return (
    <GoogleOAuthProvider
      clientId="201392862191-vka04kmgiqml4v25mgn0ginkrfu2lufs.apps.googleusercontent.com">
      {/* a changer qand api active */}
      <div style={
        {
          // backgroundColor: "#f0f2f5",
          height: "100vh",
          overflowY: "auto",
          overflowX: "hidden"
        }

      }>
        <Routes>
          {/* Route protected */}
          <Route exact path={"/"} element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/register" element={<PrivateRoute element={<Register />} />} />
          <Route path="/notification" element={<PrivateRoute element={<Notification />} />} />
          {/* User */}
          <Route path="/user" element={<PrivateRoute element={<BoardUser />} />} />
          <Route path="/page_user" element={<PrivateRoute element={<UserById />} />} />
          {/* Admin */}
          <Route path="/admin" element={<PrivateRoute element={<AdminTable />} />} />
          <Route path="/admin/:id" element={<PrivateRoute element={<AdminDetail />} />} />
          <Route path="/admin/create" element={<PrivateRoute element={<AdminCreate />} />} />
          {/* Article */}
          <Route path="/articles" element={<PrivateRoute element={<ArticlesTable />} />} />
          <Route path="/articles/create" element={<PrivateRoute element={<ArticlesCreate />} />} />
          <Route path="/articles/:id" element={<PrivateRoute element={<ArticleDetail />} />} />
          {/* Media */}
          <Route path="/medias" element={<PrivateRoute element={<MediasLibrary />} />} />
          <Route path="/medias/create" element={<PrivateRoute element={<MediasCreate />} />} />
          <Route path="/medias/theme/:id" element={<PrivateRoute element={<MediasByTheme />} />} />
          {/* Dispatch */}
          <Route path="/dispatches" element={<PrivateRoute element={<DispatchesTable />} />} />
          {/* Newsletter */}
          <Route path="/newsletter" element={<PrivateRoute element={<NewsletterHome />} />} />
          <Route exact path="/articles/:id" element={<PrivateRoute element={<ArticleDetail />} />} />
          <Route path="/articles/create" element={<PrivateRoute element={<ArticlesCreate />} />} />
          {/* TV */}
          <Route path="/tv" element={<PrivateRoute element={<TvList />} />} />
          <Route path="/tv/:tvGroupId" element={<PrivateRoute element={<TvAnsPodcastTableList />} />} />
          <Route path="/tv/:tvGroupId/create" element={<PrivateRoute element={<EditCreatePodcastTv />} />} />
          <Route path="/tv/edit/:tvGroupId/:tvId" element={<PrivateRoute element={<EditCreatePodcastTv />} />} />
          <Route path="/tv/program" element={<PrivateRoute element={<TvProgramGrille />} />} />

          {/* Regie pub */}
          <Route path="/regie-pub" element={<PrivateRoute element={< RegiePub />} />} />
          <Route path="/regie-pub/edit/:pubId" element={<PrivateRoute element={< EditCreateRegiePub />} />} />
          <Route path="/regie-pub/create" element={<PrivateRoute element={< EditCreateRegiePub />} />} />




          {/* Podcast */}
          <Route path="/podcast" element={<PrivateRoute element={<PodcastList />} />} />
          <Route path="/podcast/:podcastGroupId" element={<PrivateRoute element={<TvAnsPodcastTableList />} />} />
          <Route path="/podcast/:podcastGroupId/create" element={<PrivateRoute element={<EditCreatePodcastTv />} />} />
          <Route path="/podcast/edit/:podcastGroupId/:podcastId" element={<PrivateRoute element={<EditCreatePodcastTv />} />} />
          {/* Radio */}
          <Route path="/radio" element={<PrivateRoute element={<RadioProgram />} />} />
          {/* Annex pages */}
          <Route path="/annex-pages/:name" element={<PrivateRoute element={<AnnexPages />} />} />
          {/* Agenda */}
          <Route path="/agenda" element={<PrivateRoute element={<Agenda />} />} />
          <Route path="/agenda/edit/:eventId" element={<PrivateRoute element={<EditCreateEvent />} />} />
          <Route path="/agenda/create" element={<PrivateRoute element={<EditCreateEvent />} />} />
        </Routes>

        {/* <AuthVerify logOut={logOut}/> */}
      </div>
    </GoogleOAuthProvider>
  );
};

export default App;
