import React, { useEffect, useState } from "react";
import Menu from "../../components/layout/Menu";
import { useParams } from 'react-router-dom'
import { Grid, TextField, Paper, FormGroup, Box, Button, CircularProgress } from '@mui/material'
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { DatePicker } from "../../pages/articles/components/DatePicker";
import CustomInlineComponent from "../../pages/articles/components/CustomInlineComponent";
import CoverImageSelector from "../../pages/articles/components/ConverImageSelector";
import instance from "../../utils/axios";
import { useNavigate } from 'react-router-dom';
import { PublishOutlined } from "@mui/icons-material";
import { ModalMedia } from "../../pages/articles/components/ModalMedia";
import { AddImageModal } from "../../pages/articles/components/AddImageModal";
import { Alert } from "@mui/material";
import ConfirmDeleteModal from '../../pages/articles/components/ConfirmDeleteModal';
import { useLocation } from 'react-router-dom';
import CustomModalSuccess from "../../pages/articles/components/CustomModalSuccess";
import AlertDialog from "../../components/Alert";
import { Editor } from "@tinymce/tinymce-react";
import SelectTheme from "./components/SelectThemes";
import agendaService from "../../services/agenda.service";
import NominatimMap from "./components/MapEvent";
import verifyIfDateIsString from "../../utils/verifyIfDateIsString";
import { toast } from "react-toastify";
import tinymce from 'tinymce';
import mediaService from "../../services/media.service";
import { IMAGE_URL_WYSIWYG } from "../../constants/url";
import compressImageBlob from "../../utils/compressBlobImage";



const EditCreateEvent = () => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean); // Filtra segmentos vacíos
    const [initialValue, setInitialValue] = useState([]);
    const [themes, setThemes] = useState([]);

    // Lógica para decidir si es tvEdit, tvCreate o ninguna de las dos
    const isEdit = pathSegments.includes('edit');
    const isCreate = pathSegments.includes('create');

    const navigate = useNavigate();
    const { eventId } = useParams(); // Récupère l'ID de l'URL
    // Récupère le type de l'URL
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openCroppImage, setOpenCroppImage] = useState(false);

    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const handleOpenModalSuccess = () => setOpenModalSuccess(true);
    const handleCloseModalSuccess = () => {
        navigate('/agenda');
        setOpenModalSuccess(false)
    };
    const [CancelConfirmPublication, setCancelConfirmPublication] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const [infoCreate, setInfoCreate] = useState({
        tags: [],
        media: [],
        themes: [],
    });
    const [dataArticle, setDataArticle] = useState({
        title: "",
        description: "",
        address: "",
        latitude: "",
        longitude: "",
        event_programming: "",
        event_date_start: Math.round(new Date().getTime() / 1000),
        event_date_end: Math.round(new Date().getTime() / 1000),
        publication_date: Math.round(new Date().getTime() / 1000),
        counter: 0,
        notification: 0,
        media_id: [],
        themes_agendas: [],
        phone: "",
        is_active: false,
        mediaItems: [],
    });

    const handleEditorChange = (content, editor) => {
        setDataArticle({ ...dataArticle, event_programming: content })
    };

    let breadcrumbArray = [
        { name: "Tableau de bord", to: "/dashboard" },
        { name: "Podcast", to: "/podcast" },
        { name: `Event `, to: `/event` },
    ];

    const openMediaPicker = () => {
        setOpen(true);
    };

    const closeMediaPicker = () => {
        setOpen(false);
    };
    const handleOpenCroppImage = () => {
        setOpenCroppImage(true)
    };

    const isValidated = (is_active) => {
        if (is_active === false || is_active === null) {
            return "Brouillon"
        } else if (is_active === true) {
            return "Publié"
        }

    }
    useEffect(() => {
        if (isEdit) {
            if (eventId) {
                agendaService.getAgendaEventById(eventId).then((response) => {
                    if (response.data) {
                        setDataArticle({
                            id: response.data.id,
                            title: response.data.title,
                            description: response.data.description,
                            address: response.data.address,
                            latitude: response.data.latitude ? response.data.latitude : "",
                            longitude: response.data.longitude ? response.data.longitude : "",
                            event_programming: response.data.event_programming ? response.data.event_programming : "",
                            event_date_start: verifyIfDateIsString(response.data.event_date_start),
                            event_date_end: verifyIfDateIsString(response.data.event_date_end),
                            publication_date: Number(response.data.publication_date),
                            counter: response.data.counter,
                            notification: response.data.notification,
                            media_id: response.data?.medias.map((media) => media.id),
                            themes_agendas: response.data?.themes_agendas.map((theme) => theme.id),
                            phone: response.data.phone,
                            is_active: response.data.is_active,
                            mediaItems: response.data.medias,
                        });
                        setInitialValue(response.data.event_programming ? response.data.event_programming : "");
                    }
                }
                ).catch((error) => console.log(error));

            }
        }

        instance.get("articles/info-create").then((response) => {
            if (response.data) {
                setInfoCreate({
                    authors: response.data.authors,
                    city: response.data.city,
                    tags: response.data.tags,
                    media: response.data.media,
                    themes: response.data.themes,
                });

            }
        })
            .catch((error) => console.log(error));
        agendaService.getThemes().then((response) => {
            if (response.data) {
                setThemes(response.data.agendaThemes);
            }
        }
        ).catch((error) => console.log(error));

    }
        , []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const handleCancel = () => {
        navigate('/agenda');

    }
    const dataFormError = {
        title: "",
        description: "",
        event_programming: "",
        event_date_start: "",
        event_date_end: "",
        themes_agendas: "",
        media_id: "",
    };
    const [formErrors, setFormErrors] = useState(dataFormError);
    const validateForm = ({ isPublicationMode = false }) => {
        let isValid = true;
        const newFormErrors = {};

        if (dataArticle.title === "") {
            newFormErrors.title = "Le titre est requis.";
            isValid = false;
        }

        if (dataArticle.description.trim() === "") {
            newFormErrors.description = "La description est requise.";
            isValid = false;
        }

        if (isPublicationMode) {
            if (dataArticle.media_id.length === 0) {
                newFormErrors.media_id = "L'image est requise.";
                isValid = false;
            }
        }
        if (dataArticle.address === "" || dataArticle.latitude === "" || dataArticle.longitude === "") {
            newFormErrors.address = "L'adresse est requise.";
            isValid = false;
        }


        if (dataArticle.event_programming === "") {
            newFormErrors.event_programming = "Le contenu de l'eventment est requis.";
            isValid = false;
        }
        if (dataArticle.themes_agendas.length === 0) {
            newFormErrors.themes_agendas = "Le contenu de l'eventment est requis.";
            isValid = false;
        }

        if (!dataArticle.event_date_start) {
            newFormErrors.event_date_start = "L'heure de début est requise.";
            isValid = false;
        } else if (!dataArticle.event_date_end) {
            newFormErrors.event_date_start = "L'heure de fin est requise.";
            isValid = false;
        } else {
            const startTime = dataArticle.event_date_start;
            const endTime = dataArticle.event_date_end

            if (startTime >= endTime) {
                newFormErrors.start = "L'heure de début doit être antérieure à l'heure de fin.";
                isValid = false;
            }
        }

        setFormErrors(newFormErrors);
        return isValid;
    };

    const handleCloseCroppImage = ({ openMediapicker = false }) => {
        if (openMediapicker) {
            setOpenCroppImage(false)
            openMediaPicker();
        } else {
            setOpenCroppImage(false)
        }
    };

    const create = async ({ isDraft = true }) => {
        const valid = validateForm({ isPublicationMode: isDraft });
        if (valid) {
            try {
                setLoaded(true);
                agendaService.createAgendaEvent({
                    title: dataArticle.title,
                    description: dataArticle.description,
                    address: dataArticle.address,
                    latitude: dataArticle.latitude,
                    longitude: dataArticle.longitude,
                    event_programming: dataArticle.event_programming,
                    event_date_start: dataArticle.event_date_start,
                    event_date_end: dataArticle.event_date_end,
                    publication_date: dataArticle.publication_date,
                    counter: dataArticle.counter,
                    notification: dataArticle.notification,
                    medias_id: dataArticle.media_id,
                    themes_id: dataArticle.themes_agendas,
                    phone: dataArticle.phone,
                    is_active: isDraft
                }).then((response) => {
                    if (response.status === 200) {
                        setSuccess(true);
                        handleOpenModalSuccess();
                        setTimeout(() => {
                            navigate('/agenda');
                        }, 2000);
                    }
                }
                ).catch((error) => {
                    setLoaded(false);
                    toast.error("Échec lors de l'enregistrement de l'événement ", {
                        position: 'top-right',
                        theme: "dark"
                    });
                });
            }
            catch (error) {
                setLoaded(false);
                toast.error("Échec lors de l'enregistrement de l'événement ", {
                    position: 'top-right',
                    theme: "dark"
                });
                console.log(error);
            }
        }


    };


    const handleDelete = () => {
        try {
            agendaService.deleteAgendaEvent(eventId).then((response) => {
                if (response.status === 200) {
                    navigate('/agenda');
                }
            }
            ).catch((error) => console.log(error));
        }
        catch (error) {
            console.log(error);
        }

    }

    const ButtonsCreate = () => {
        return (
            <>
                <div>
                    <button onClick={() => setCancelConfirmPublication(true)} className="btn btn-danger">Annuler</button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        sx={{
                            marginLeft: "5px",
                            backgroundColor: "#0057BA",
                            color: "white",
                            paddingBottom: "6px",
                        }}
                        // disabled={loaded ? true : false}
                        onClick={() => create({ isDraft: false })}
                    >
                        {loaded ? (
                            <CircularProgress color="warning" />
                        ) : (
                            <>
                                Brouillon
                            </>
                        )}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            marginLeft: "5px",
                            backgroundColor: "#4caf50",
                            color: "white",
                            paddingBottom: "6px",
                        }}
                        // disabled={loaded ? true : false}
                        onClick={() => create({ isDraft: true })}
                    >
                        {loaded ? (
                            <CircularProgress color="inherit" />
                        ) : (
                            <>
                                Publier <PublishOutlined />
                            </>
                        )}
                    </Button>



                </div>
            </>
        )
    }

    const handleSave = async ({ isDraft = false }) => {
        const valid = validateForm({ isPublicationMode: isDraft });
        if (valid) {
            agendaService.updateAgendaEvent({
                ...dataArticle,
                medias_id: dataArticle.media_id,
                is_active: isDraft

            }).then((response) => {
                if (response.status === 200) {
                    handleOpenModalSuccess();
                }
            }
            ).catch((error) =>
                toast.error("Échec lors de l'enregistrement de l'événement ", {
                    position: 'top-right',
                    theme: "dark"
                }));
        }

    }

    const ButtonsEdit = () => {
        return (
            <>

                <div>
                    <button onClick={handleOpenModal} className="btn btn-danger mr-1">Supprimer</button>
                    <button onClick={() => setCancelConfirmPublication(true)} className="btn btn-secondary">Annuler</button>
                </div>

                <div>
                    <button onClick={handleSave} className="btn btn btn-dark mr-2">Enregistrer</button>
                </div>
                {(dataArticle.is_active === null || dataArticle.is_active === false) && (
                    <div>
                        <Button
                            variant="contained"
                            sx={{
                                marginLeft: "5px",
                                backgroundColor: "#4caf50",
                                color: "white",
                                paddingBottom: "6px",
                            }}
                            // disabled={loaded ? true : false}
                            onClick={() => handleSave({ isDraft: true })}
                        >
                            {loaded ? (
                                <CircularProgress color="inherit" />
                            ) : (
                                <>
                                    Publier <PublishOutlined />
                                </>
                            )}
                        </Button>
                    </div>


                )}
            </>
        )
    }


    return (
        <Menu category={"event"} breadCrumb={breadcrumbArray}>
            <Grid container spacing={5} className={"m-4"} sx={{ display: 'flex', width: "100%" }}>
                <Grid sx={{ flexDirection: "column" }} container xs={8}>
                    <TextField
                        className={"mb-2"}
                        id="title"
                        label="Titre"
                        variant="outlined"
                        error={!!formErrors.title}
                        helperText={formErrors.title}
                        value={dataArticle.title}
                        onChange={(e) => {
                            setDataArticle({ ...dataArticle, title: e.target.value })
                        }
                        }
                    />
                    <TextField
                        className={"mb-2"}
                        id="description"
                        label="Texte descriptif / Chapeau"
                        multiline
                        error={!!formErrors.description}
                        helperText={formErrors.description}
                        value={dataArticle.description}
                        onChange={(e) => {
                            setDataArticle({ ...dataArticle, description: e.target.value })
                        }
                        }
                        rows={3}
                    />
                    <TextField
                        className={"mb-2"}
                        id="phone"
                        label="Numéro de téléphone"
                        variant="outlined"
                        value={dataArticle.phone}

                        onChange={(e) => {
                            setDataArticle({ ...dataArticle, phone: e.target.value })
                        }
                        }
                        rows={3}
                    />
                    {formErrors.event_programming && (
                        <div className="text-danger">{formErrors.event_programming}</div>
                    )
                    }
                    <Editor
                        tinymceScriptSrc="./tinymce/tinymce.min.js"
                        initialValue={initialValue}
                        onEditorChange={handleEditorChange}
                        // onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                            font_formats:
                                "Raleway=raleway;",
                            height: 500,
                            menubar: true,
                            language: "fr_FR",
                            promotion: false,
                            plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "preview",
                                "help",
                                "wordcount",
                            ],
                            file_picker_callback: (cb, value, meta) => {
                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.setAttribute('accept', 'image/*');
                                input.addEventListener('change', (e) => {
                                    const file = e.target.files[0];
                                    const maxSizeAfterCompression = 5 * 1024 * 1024; // 5 MB en bytes
                                    compressImageBlob(file).then((file) => {

                                        if (file.size > maxSizeAfterCompression) {
                                            toast.error("Votre image est trop lourde meme après compression", {
                                                position: "top-right",
                                                theme: "dark",
                                            });
                                            return;
                                        }

                                        mediaService.uploadMedia(file)
                                            .then((response) => {
                                                const reader = new FileReader();
                                                reader.addEventListener('load', () => {
                                                    const id = 'blobid' + (new Date()).getTime();
                                                    const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                                    const base64 = reader.result.split(',')[1];
                                                    const blobInfo = blobCache.create(id, file, base64);
                                                    blobCache.add(blobInfo);
                                                    cb(IMAGE_URL_WYSIWYG + response.data.url, { title: file.name });
                                                });
                                                reader.readAsDataURL(file);
                                            });
                                    }
                                    );
                                });
                                input.click();
                            },
                            toolbar:
                                "undo redo | bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags",

                            content_style: "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap'); body { font-family: Raleway; font-variant-numeric: lining-nums; }; ",
                        }}
                    />
                    <Paper variant="outlined" style={{
                        boxShadow: "none",
                        padding: '8px',
                        paddingLeft: '15px',
                        height: '9rem',
                        overflow: "auto",
                        overflowY: "scroll",
                        marginBottom: "6px"

                    }}>

                        <Box sx={{ marginTop: -1, display: "flex" }}>
                            <div className="flex mt-1 mr-5">
                                <DatePicker
                                    setDataArticle={setDataArticle}

                                    dataArticle={dataArticle}
                                    title={"Date de début"}
                                    propertyName={"event_date_start"}
                                />
                            </div>
                            <div className="flex mt-1">
                                <DatePicker
                                    setDataArticle={setDataArticle}

                                    dataArticle={dataArticle}
                                    title={"Date de fin"}
                                    propertyName={"event_date_end"}
                                />
                            </div>
                        </Box>
                        {!!formErrors.event_date_start && (<div className="text-danger">{formErrors.event_date_start}</div>)}
                        {!!formErrors.event_date_end && (<div className="text-danger">{formErrors.event_date_end}</div>)}
                        {!!formErrors.start && (<div className="text-danger">{formErrors.start}</div>)}
                        <Box sx={{ marginTop: -1, display: "flex" }}>

                            <FormGroup>
                                <SelectTheme
                                    mLeftSelect={8}
                                    dataArticle={dataArticle}
                                    setDataArticle={setDataArticle} propertyName={"themes_agendas"}
                                    options={themes}
                                    label="Theme" />
                            </FormGroup>
                        </Box>
                        {!!formErrors.themes_agendas && (<div className="text-danger">{formErrors.themes_agendas}</div>)}
                    </Paper>


                </Grid>
                <Grid container xs={4}>
                    <div style={
                        {
                            marginLeft: "25px",
                            width: "88%",
                        }
                    } >
                        <CustomInlineComponent
                            textLeft={"Statut"}
                            textRight={isValidated(dataArticle.is_active)}
                        />

                        {formErrors.media_id && (
                            <div className="text-danger">{formErrors.media_id}</div>
                        )
                        }
                        <CoverImageSelector
                            dataArticle={dataArticle}
                            setDataArticle={setDataArticle}
                            infoCreate={infoCreate}
                            setInfoCreate={setInfoCreate}
                            openMediaPicker={openMediaPicker}
                            setOpen={setOpen}
                            handleOpenCroppImage={handleOpenCroppImage}
                        />

                        <div className='mt-9'>


                        </div>
                        <Paper variant="outlined" style={{
                            boxShadow: "none",
                            padding: '8px',
                            paddingLeft: '15px',
                            height: '26rem',
                            overflow: "auto",
                            overflowY: "scroll",

                        }}>
                            {formErrors.address && (
                                <div className="text-danger">{formErrors.address}</div>
                            )
                            }
                            <NominatimMap
                                dataArticle={dataArticle}
                                setDataArticle={setDataArticle}
                            />
                        </Paper>


                        <Paper variant="outlined" style={{
                            boxShadow: "none",
                            padding: '8px',
                            paddingLeft: '15px',
                            height: '9rem',
                            overflow: "hidden",

                            marginTop: "7px"
                        }}>
                            <DatePicker
                                setDataArticle={setDataArticle}
                                isPodcastAndTv={true}
                                dataArticle={dataArticle}
                            />

                            <div className='mt-3 justify-between flex'>
                                {isCreate && (
                                    <ButtonsCreate />
                                )}
                                {isEdit && (
                                    <ButtonsEdit />
                                )}

                            </div>

                        </Paper>
                        {open && (
                            <ModalMedia
                                open={open}
                                handleClose={closeMediaPicker}
                                handleSave={closeMediaPicker}
                                setDataArticle={setDataArticle}
                                dataArticle={dataArticle}
                                infoCreate={infoCreate}
                                setInfoCreate={setInfoCreate}

                            />
                        )}
                        {openCroppImage && (
                            <AddImageModal
                                open={openCroppImage}
                                handleCloseCroppImage={handleCloseCroppImage}
                                setInfoCreate={setInfoCreate}
                                infoCreate={infoCreate}
                                dataArticle={dataArticle}
                                setDataArticle={setDataArticle}
                            />
                        )
                        }
                    </div>
                </Grid>
            </Grid>

            <ConfirmDeleteModal
                open={openModal}
                onClose={handleCloseModal}
                onConfirm={handleDelete}
            />

            <CustomModalSuccess
                open={openModalSuccess}
                onClose={handleCloseModalSuccess}
                title={"Succès"}
                description={"L'événement a été enregistré avec succès."}

            />

            {/* Cancel confirm */}
            <AlertDialog
                open={CancelConfirmPublication}
                handleClose={() => setCancelConfirmPublication(false)}
                handleAgree={handleCancel}
                title={"Attention"}
                label={"Voulez vous vraiment annuler ? Toutes vos modifications seront perdues"}
                buttonAccept={"Oui"}
                buttonCancel={"Non"}
            />

            {error ? (
                <Alert sx={{ marginBottom: 1, marginTop: 3, position: "absolute", top: 27, right: 750 }} severity="error">

                    Tous les champs sont obligatoires
                </Alert>
            ) : null}
            {success ? (
                <Alert sx={{ marginBottom: 1, marginTop: 3, position: "absolute", top: 27, right: 750 }} severity="success">
                    Article créé avec succès
                </Alert>
            ) : null}

        </Menu>
    )
}

export default EditCreateEvent;