import instance from "../utils/axios";

class radioProgram {
    constructor() {
        this.instance = instance;
    }
    getRadioPrograms = async () => {
        return await instance.get("radio-program/all", {
            withCredentials: true,
        });
    };
    getAllRadioShows = async () => {
        return await instance.get("radio-show/all", {
            withCredentials: true,
        });
    }
    createRadioProgram = async (data) => {
        return await instance.post(`radio-program/create`, data, {
            withCredentials: true,
        });
    }
    updateRadioProgram = async (data) => {
        return await instance.put(`radio-program/update`, data, {
            withCredentials: true,
        });
    }
    deleteRadioProgram = async (id) => {
        return await instance.delete("radio-program/delete", {
            data: { id },
            withCredentials: true,
        });
    }

}

var radioProgramService = new radioProgram();
export default radioProgramService;
