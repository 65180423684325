import React from 'react';
import { Select, MenuItem, FormControlLabel, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from "@mui/material";


const SelectComponent = ({ options, label, propertyName, isAuteur = false, marginLeft = 0, mLeftSelect = 2, dataArticle, setDataArticle, isTvShow = false }) => {


    // Responsive 

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    //  
    const handleChange = (event) => {
        const newValue = event.target.value;
        setDataArticle({ ...dataArticle, [propertyName]: [newValue] });
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const caculateWidth = Math.max(370 - (1920 - windowWidth) * 0.3, 0);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const optionsItems = options.map((option, index) => (
        <MenuItem key={index} value={option.id}>
            {isAuteur && option?.firstname ? (option.lastname ? `${option.firstname} ${option.lastname}` : option.firstname) : (isTvShow ? option?.title : option?.name)}
        </MenuItem>
    ));

    return (
        <FormControlLabel
            sx={{ marginRight: isSmallScreen ? 20 : 50, marginLeft: marginLeft, color: 'black', marginTop: 2, width: isSmallScreen ? "250px" : "auto" }}
            control={
                <Select

                    sx={{ width: isSmallScreen ? "190px" : `${caculateWidth}px`, maxWidth: '100%', height: 32 }}
                    value={dataArticle[propertyName]?.length > 0 ? dataArticle[propertyName][0] : isTvShow ? dataArticle[propertyName] : ""}
                    onChange={handleChange}
                >
                    {optionsItems}
                </Select>
            }
            label={<Typography style={{ fontWeight: 'bold', fontSize: '16px', width: "70px" }}>{label}</Typography>}
            labelPlacement="start"
        />
    );
};

export default SelectComponent;
