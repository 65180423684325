import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CustomModalSuccess = ({ open, onClose, title, description }) => {
  useEffect(() => {
    let timeoutId;

    if (open) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [open, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center', // Centrar el contenido
        }}
      >
        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 40, mb: 2 }} />
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} >
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {description}
        </Typography>
      </Box>
    </Modal>
  );
};

export default CustomModalSuccess;
