import Menu from "../../components/layout/Menu";
import { useState, useEffect } from 'react'
import { Grid, Container, CircularProgress } from '@mui/material'
import { StripedDataGrid } from '../../constants/tableStyle'
import Action from '../../components/Action'
import { frFR, GridToolbar } from '@mui/x-data-grid'
import regiePubService from "../../services/regiePub.service";
import ConvertDateToMilliseconds from "../../common/DateConvert";


const breadcrumbArray = [
    { name: "Listing des campagnes", to: "/regie-pub" },

];
const RegiePub = () => {
    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [loader, setLoader] = useState(false);

    useEffect(() => {

        setLoader(true);
        regiePubService.getAllPub().then((response) => {
            setRows(response.data);
            setLoader(false);

        });
    }, []);

    const columnsRegiePub = [
        {
            field: "actions",
            headerName: "#",
            width: 130,
            renderCell: (params) => {
                return (
                    <Action
                        isAgenda
                        // handleOpenConfirmNotification={handleOpenConfirmNotification}
                        // notificationActivate={true}
                        editActivate={true}
                        setRows={setRows}
                        rows={rows}
                        editLink={`/regie-pub/edit/${params.id}`}
                        idArticle={params.row.id}
                        notifications={params.row.notification}
                        label="Êtes-vous sûr de vouloir supprimer cet article ?"
                    />
                );
            },
        },
        {
            field: "advertiser",
            headerName: "Annonceurs",
            width: 380,
            hideable: false
        },
        {
            field: "title",
            headerName: "Nom de la campagne",
            width: 380,
            hideable: false
        },
        {
            field: "Durée/Date",
            headerName: "Durée/Date",
            minWidth: 200,
            renderCell: (params) => {
                return params.row.date_start.slice(0, 10) + " - " + params.row.date_end.slice(0, 10);
            },
        },
        {
            headerName: "Status",
            width: 150,
            renderCell: (params) => {
                const dateNow = Math.round(new Date().getTime() / 1000);
                const date_start = ConvertDateToMilliseconds(params.row.date_start);
                const date_end = ConvertDateToMilliseconds(params.row.date_end);
                const is_active = params.row.is_active;
                const getStatus = () => {
                    if (dateNow < date_start && is_active === true) {
                        return "A venir"
                    } else if (dateNow > date_end && is_active === true) {
                        return "Terminé"
                    } else if (is_active === false) {
                        return "Brouillon"
                    }
                    else if (dateNow < date_end && is_active === true) {
                        return "En ligne"
                    }
                }
                return getStatus();
            },
        },
        {
            field: "delete",
            headerName: "",
            width: 70,
            renderCell: (params) => {
                return (
                    <Action
                        trashIcon={true}
                        deleteAction={() => handleDeletePub(params.id)}

                    />
                );
            },
        },

    ];
    const handleDeletePub = (id) => {

        regiePubService.deletePub(id).then((response) => {
            setRows(rows.filter((row) => row.id !== id));
        });

    }

    // const AddBanner = () => {


    return (
        <Menu category="Régie Pub" breadCrumb={breadcrumbArray} link="/regie-pub/create" buttonText="Créer une campagne" >
            {loader ? (
                <Container className="d-flex justify-content-center">
                    <CircularProgress className="mx-5" color="inherit" />
                </Container>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StripedDataGrid
                            style={{ maxWidth: "100%" }}
                            rows={rows}
                            columns={columnsRegiePub}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            className="mx-3"
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Grid>
                </Grid>

            )}

        </Menu>
    );
}

export default RegiePub;