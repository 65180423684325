import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import AddVideoUrlToArticle from './YoutubeUrlArticle';
import VideoViewer from './VideoViewer';
import { Button, Typography } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import GoogleIcon from '@mui/icons-material/Google';
import YoutubeMediaUrlUploader from './YoutubeUrl';
import Paper from "@mui/material/Paper";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MissedVideoCallOutlinedIcon from '@mui/icons-material/MissedVideoCallOutlined';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useTheme, useMediaQuery } from "@mui/material";



const ModalAddVideoYoutubeArticle = ({ open, onClose, onConfirm, dataArticle, setDataArticle, setListVideoMediaInArticle, listVideoMediaInArticle }) => {
    const [isVideoInArticle, setIsVideoInArticle] = useState(false);
    const [methodSelected, setMethodSelected] = useState('');
    const [disabledBack, setDisabledBack] = useState(false);
    useEffect(() => {
        setMethodSelected('');
    }, [open])


    useEffect(() => {
        if (listVideoMediaInArticle && listVideoMediaInArticle.length > 0) {
            setIsVideoInArticle(true);
        }
    }
        , [isVideoInArticle])

    // Responsive 

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // 

    return (
        <Modal
            open={open}
            // onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>

                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isSmallScreen ? 380 : 800,
                        height: 650,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center',
                        borderRadius: 4,
                        overflowX: isSmallScreen ? "hidden" : "none",
                        overflowY: isSmallScreen ? "scroll" : "none",
                    }}
                >
                    {methodSelected !== "" && (
                        <Button
                            sx={{ ml: 2, mt: 2, position: "absolute", left: 10 }}
                            variant="contained"
                            color="primary"
                            onClick={() => setMethodSelected("")}
                            disabled={disabledBack}
                        >
                            <ArrowCircleLeftIcon />Retour
                        </Button>
                    )}


                    {isVideoInArticle ? (
                        <VideoViewer dataArticle={dataArticle} video={listVideoMediaInArticle} setListVideoMediaInArticle={setListVideoMediaInArticle} setDataArticle={setDataArticle} onClose={onClose} setIsVideoInArticle={setIsVideoInArticle} />

                    ) : (
                        <div>
                            {methodSelected === "" ? (

                                <>
                                    <MissedVideoCallOutlinedIcon sx={{ fontSize: isSmallScreen ? 100 : 140, position: "absolute", top: 0, left: isSmallScreen ? 150 : 350 }} />
                                    <Box
                                        sx={{
                                            marginTop: isSmallScreen ? "60px" : "130px"
                                        }}
                                    >
                                        <div className='mt-12'>
                                            <Typography variant="h5">
                                                Selectioner un moyen pour ajouter une video
                                            </Typography>
                                            <Button
                                                sx={{ ml: isSmallScreen ? 0 : 10, mt: 6 }}
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => setMethodSelected("url")}
                                            >
                                                Ajouter une video via URL<InsertLinkIcon sx={{ ml: 1 }} />
                                            </Button>
                                            <Button
                                                sx={{ ml: isSmallScreen ? 0 : 5, mt: 6 }}
                                                variant="contained"
                                                color="error"
                                                onClick={() => setMethodSelected("youtube")}
                                            >
                                                Ajouter une video via YOUTUBE < GoogleIcon sx={{ ml: 1 }} />
                                            </Button>
                                            <Typography variant="body2" sx={{ mt: 2 }}>
                                                <b>Conseil :</b> Choisissez l'option qui convient le mieux à votre méthode préférée d'ajout de vidéos.
                                            </Typography>
                                            <Typography variant="body2">
                                                Si la vidéo est sur YouTube, utilisez <span className='font-bold'>"Ajouter la video via URL"</span> pour plus de simplicité.
                                            </Typography>
                                        </div>
                                    </Box>

                                    <Button
                                        sx={{ ml: isSmallScreen ? 0 : 70, mt: isSmallScreen ? 6 : 16 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={onClose}
                                    >
                                        Fermer la fenêtre<CancelOutlinedIcon />
                                    </Button>
                                </>

                            ) : (
                                <>
                                    {methodSelected === "youtube" && (

                                        <AddVideoUrlToArticle setDisabledBack={setDisabledBack} setMethodSelected={setMethodSelected} onClose={onClose} setDataArticle={setDataArticle} dataArticle={dataArticle} setListVideoMediaInArticle={setListVideoMediaInArticle} setIsVideoInArticle={setIsVideoInArticle} />
                                    )}
                                    {methodSelected === "url" && (
                                        <YoutubeMediaUrlUploader
                                            setDataArticle={setDataArticle}
                                            dataArticle={dataArticle}
                                            setListVideoMediaInArticle={setListVideoMediaInArticle}
                                            setMethodSelected={setMethodSelected}
                                            setIsVideoInArticle={setIsVideoInArticle}
                                            onClose={onClose}

                                        />


                                    )}

                                </>
                            )
                            }

                        </div>

                    )}


                </Box>
            </>
        </Modal>
    );
};

export default ModalAddVideoYoutubeArticle;
