import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../App.css";
import { useTranslation } from "react-i18next";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { UserContext } from "../context/UserContext";
import logoLogin from "../assets/Logo MARITIMA-horizontal-Blanc.png";
import logoBackground from "../assets/M-MARITIMA.svg";
import { IMAGE_URL } from "../constants/url";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [filename, setFilename] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const jwtFromLocalStorage = localStorage.getItem("jwt");

  const initialValues = {
    account: "",
    password: "",
  };

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const validationSchema = Yup.object({
    account: Yup.string()
      .email(t("login_error.invalid_email"))
      .required(t("login_error.required_email")),
    password: Yup.string().required(t("login_error.required_password")),
  });

  useEffect(() => {
    //   AuthService.getCurrentUser()
    //     .then((res) => {
    //       if (res.status === 200) {
    //         setCurrentUser(true);
    //         navigate("/dashboard");
    //       } else {
    //         setCurrentUser(false);
    //         navigate("/login");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });``
    if (jwtFromLocalStorage) {
      navigate("/dashboard");
    }
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    AuthService.login(values)
      .then(
        (res) => {
          if (res.status === 200 && res.data) {
            localStorage.setItem("jwt", res.data.jwt ? res.data.jwt : "");
            // localStorage.setItem("author", res.data.is_author ? res.data.is_author : false);
            localStorage.setItem("account", res.data.account ? res.data.account : "");
            localStorage.setItem("name", res.data.name ? res.data.name : "");
            localStorage.setItem("pictureUrl", res.data.picture_url ? `${IMAGE_URL + res.data.picture_url}` : false);
            setCurrentUser(true);
            navigate("/dashboard");
          } else {
            const resMessage = "Une erreur est survenue";
            setLoading(false);
            setSubmitting(false);
            setMessage(resMessage);
          }

        },
        (error) => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setSubmitting(false);
          setMessage(resMessage);
        }
      )
      .catch((error) => console.log(error));
  };

  const handleFileUpload = async (e) => {
    if (!e.target.files) {
      return;
    }
    console.log("la");
    const apiKey = "AIzaSyDoqL0QvSAoAsndpIUpg3Uxj1wOzotl1fc";
    const accessToken =
      "ya29.a0AWY7Ckk_VS8U4W3tQvFsULye4mb-5EPxZvEKZUMZmtfKlaJeTX1__3dmoK2Wcrh4B_I8cPQ4LcGxr_6qGkGJXcAZFSkN6STBwttJV6FFrU7aJPdfCyOIkU99VUsAbYvedz_hXWUCc5habenmsVWANBw5uYOpaCgYKAckSARESFQG1tDrpL-HE3S7NNifwR41YkdlA3g0163";

    const client = window.google.accounts.oauth2.initCodeClient({
      client_id:
        "801719748890-djr2o0qd5gtiocuu822pq0oaimdfn4mj.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/calendar.readonly",
      ux_mode: "popup",
      callback: (response) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "http://localhost:4000/api/googleSignIn", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.setRequestHeader("X-Requested-With", "XmlHttpRequest");
        xhr.onload = function () {
          console.log("Auth code response: " + xhr.responseText);
        };
        xhr.send("code=" + response.code);
      },
    });

    console.log("after client");
    client.requestCode();
    // Paramètres de la requête
    const file = e.target.files[0];

    const metadata = {
      snippet: {
        title: "Test video upload",
        description: "Description of uploaded video.",
        tags: ["tag1", "tag2"],
        categoryId: "22",
      },
      status: {
        privacyStatus: "private",
      },
    };
    const url = "https://www.googleapis.com/upload/youtube/v3/videos?part=snippet,status";
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/octet-stream",
      "X-Upload-Content-Length": file.size,
      "X-Upload-Content-Type": file.type,
    };
    const config = {
      headers,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(percentCompleted);
      },
    };
    const response = await axios.post(url, file, config);
    console.log(response.data);
    /*const headers = {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        };

        // Envoyer la requête POST pour créer la vidéo
        axios.post(`https://www.googleapis.com/upload/youtube/v3/videos?part=snippet&key=${apiKey}`, metadata, { headers })
            .then((response) => {
                console.log('dans le post')
                console.log(response)
                const videoUrl = `https://www.googleapis.com/upload/youtube/v3/videos/${response.data.id}?uploadType=resumable`;

                // Envoyer la requête PUT pour uploader la vidéo
                axios.put(videoUrl, file, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'video/*'
                    }
                })
                    .then(() => {
                        console.log('La vidéo a été uploadée avec succès.');
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });*/
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://apis.google.com/js/api.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#16324D",
        minWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        padding: 0,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img src={logoBackground} alt="logo" className="logo-home-left" />
      <img
        src={logoBackground}
        alt="logo"
        className="logo-home-right"
        style={{
          position: "absolute",
          top: "-300px",
          right: "-200px",
          height: "700px",
        }}
      />
      <div>
        <div>
          <img src={logoLogin} alt={'Logo Maritima'} style={{ width: "100%", maxWidth: 200, paddingTop: 30, paddingBottom: 30, marginLeft: 50, marginRight: 50 }} />
          {/* <img src={logoLogin} alt="logo" style={{ width: "215px", height: "156px" }} /> */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="form-group" style={{ marginTop: 20 }}>
                <Field
                  name="account"
                  className="form-control custom-input"
                  placeholder={t("email")}
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #FFF",
                    backgroundColor: "#16324D",
                    color: "rgba(255, 255, 255, 0.50)",
                    fontWeight: "bold",
                  }}
                />
                <ErrorMessage name="account" component="div" className="error" />
                <Field
                  type="password"
                  name="password"
                  className="form-control custom-input"
                  placeholder={t("password")}
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #FFF",
                    backgroundColor: "#16324D",
                    color: "rgba(255, 255, 255, 0.50)",
                    fontWeight: "bold",
                    marginTop: 20,
                  }}
                />
                <ErrorMessage name="password" component="div" className="error" />

                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    display: "flex",
                    padding: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    alignSelf: "stretch",
                    width: "100%",
                    borderRadius: "5px",
                    background: "#FFF",
                    border: "none",
                  }}
                >
                  {t("submit")}
                </button>
              </Form>
            )}
          </Formik>
          {/* <Button
            component="label"
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            sx={{ marginRight: "1rem" }}
          >
            Envoyer video youtube
            <input
              type="file"
              accept=".mp4"
              hidden
              onChange={handleFileUpload}
            />
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
