import React, { useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import { formStyle } from "../../constants/formStyle";
import { toast } from "react-toastify";
import NotificationServices from "../../services/notification.service";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import Menu from "../../components/layout/Menu";

const Notification = () => {
  const [formValues, setFormValues] = useState({
    title: "",
    body: "",
  });
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSave = (event) => {
    event.preventDefault();
    setIsLoading(true);
    toast.loading("Campagne en cour d'execution!", {
      position: "top-right",
      theme: "dark",
    });
    // Send the updated formValues to the backend
    console.log(formValues);
    NotificationServices.sendNotification(formValues).then(
      (response) => {
        toast.dismiss();
        if (response.data?.success === true) {
          toast.success("Campagne envoyé avec succès!", {
            position: "top-right",
            theme: "dark",
          });
        } else {
          toast.error("Erreur lors de la campagne de notification !", {
            position: "top-right",
            theme: "dark",
          });
        }
        setIsLoading(false);
      },
      (error) => {
        toast.dismiss();
        console.debug(error);
        toast.error("Erreur lors de la campagne de notification !", {
          position: "top-right",
          theme: "dark",
        });
        setIsLoading(false);
      }
    )
    .catch((error) => console.log(error));
  };
  useEffect(() => {
    if (formValues.title.trim() !== "" && formValues.body.trim() !== "") {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [formValues]);

  return (
    <Menu category="Notification" label="Campagne de notification">
      <Box
        className={"container"}
        component="form"
        onSubmit={handleSave}
        noValidate
        autoComplete="off"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={formStyle}>
          <TextField
            style={{ backgroundColor: "#FCFCFC" }}
            required
            id="outlined-multiline-static"
            label={t("title")}
            name="title"
            multiline
            rows={4}
            placeholder={t("notificationTitle")}
            onChange={handleInputChange}
          />
          <TextField
            style={{ backgroundColor: "#FCFCFC" }}
            id="outlined-multiline-static"
            label={t("message")}
            name="body"
            multiline
            rows={4}
            placeholder={t("notificationBody")}
            onChange={handleInputChange}
            required
          />
          {!isLoading ? (
            <Button
              type={"submit"}
              variant="contained"
              disabled={!isFormDirty}
              color="primary"
              sx={{
                width: 200,
                marginTop: 5,
              }}
            >
              {t("send")}
            </Button>
          ) : (
            <LoadingButton
              loading
              variant="contained"
              sx={{
                width: 200,
                marginTop: 5,
              }}
            >
              {t("send")}
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Menu>
  );
};

export default Notification;
