import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Menu from "../../components/layout/Menu";
import articlesService from "../../services/articles.service";
import { Container, Grid, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Editor } from "@tinymce/tinymce-react";
import Button from "@mui/material/Button";
import CollectionsIcon from '@mui/icons-material/Collections';
import Paper from "@mui/material/Paper";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import instance from "../../utils/axios";
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { DatePicker } from "./components/DatePicker";
import { ModalMedia } from "./components/ModalMedia";
import dayjs from "dayjs";
import CustomInlineComponent from "./components/CustomInlineComponent";
import CoverImageSelector from "./components/ConverImageSelector";
import SelectComponent from "./components/SelectComponent";
import SelectMultipleComponent from "./components/MultiselectComponent";
import BriefForm from "./components/BriefCreator";
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import CustomModalSuccess from "./components/CustomModalSuccess";
import FreeSoloCreateOptionDialog from "../medias/components/AutoCompleteCreateTag";
import { AddImageModal } from "./components/AddImageModal";
import verifyIfDateIsString from "../../utils/verifyIfDateIsString";
import NumberInput from './components/NumberInput';
import AlertDialog from "../../components/Alert";
import ModalAddVideoYoutubeArticle from "./youtubeComponents/ModalAddVideoYoutbe";
import AddVideoButton from "../../components/buttonAddVideo/AddVideoButton";
import PodcastSelect from "./podcastComponents/PodcastSelect";
import podcastService from '../../services/podcast.services';
import { PublishOutlined } from "@mui/icons-material";
import AdminService from "../../services/admin.service";
import tinymce from 'tinymce';
import mediaService from "../../services/media.service";
import { IMAGE_URL_WYSIWYG } from "../../constants/url";
import { useTheme, useMediaQuery } from "@mui/material";
import compressImageBlob from "../../utils/compressBlobImage";
import { toast } from "react-toastify";


const ArticleDetail = () => {
    const { id } = useParams(); // Récupère l'ID de l'URL
    const getEmailFromLocalStorage = localStorage.getItem("account")
    const [userInfo, setUserInfo] = useState(null);
    const [podcasts, setPodcasts] = useState([]);
    const [article, setArticle] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [initialValue, setInitialValue] = useState([]);
    const [tagsCreate, setTagCreate] = useState([]);
    const [tagsId, setTagsId] = useState([]);
    const [value, setValue] = useState([]);
    const [openCroppImage, setOpenCroppImage] = useState(false);
    const [CancelConfirmPublication, setCancelConfirmPublication] = useState(false);
    const [publicationFailed, setPublicationFailed] = useState(false);
    const [openConfirmPublication, setOpenConfirmPublication] = useState(false);
    const handleCloseConfirmPublication = () => {

        setOpenConfirmPublication(false)
    };

    //YOUTUBE MODAL 
    const [openModalYoutube, setOpenModalYoutube] = useState(false);
    const handleOpenModalYoutube = () => setOpenModalYoutube(true);
    const handleCloseModalYoutube = () => setOpenModalYoutube(false);
    const [listVideoMediaInArticle, setListVideoMediaInArticle] = useState([]);
    const checkVideoInArticle = listVideoMediaInArticle && listVideoMediaInArticle.length > 0;
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const handleOpenModalSuccess = () => {
        setTimeout(() => {

            navigate("/articles")

        }, 1500);
        setOpenModalSuccess(true)
    }

        ;
    const handleCloseModalSuccess = () => setOpenModalSuccess(false);

    // Responsive 

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const [briefs, setBriefs] = useState([]);
    // const userInfo = JSON.parse(getUserFromLocalStorage)

    const [infoCreate, setInfoCreate] = useState({
        authors: [],
        city: [],
        tags: [],
        media: [],
        themes: [],
    });

    const [dataArticle, setDataArticle] = useState({
        title: "",
        description: "",
        body: "",
        publication_date: Math.round(new Date().getTime() / 1000).toString(),
        is_validate: false,
        is_active: false,
        in_spotlight: false,
        special_edition: false,
        podcast_id: "",
        city_id: "",
        author_id: [],
        theme_id: [],
        tag_id: [],
        media_id: [],
        briefs: [],
        notification: false,
        numberOfViews: 0,
        time_to_read: 0,
        validate_date: null,
        mediaItems: [],
    });
    const dataFormError = {
        title: "",
        description: "",
        body: "",
        city_id: "",
        author_id: "",
        theme_id: "",
        tag_id: "",
        media_id: "",
        time_to_read: "",
    };
    useEffect(() => {

        validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataArticle]);

    const [formErrors, setFormErrors] = useState(dataFormError);

    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const caculateWidth = Math.max(370 - (1920 - windowWidth) * 0.3, 0);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const findUserByEmail = (array, email) => {
        setUserInfo(array.find(item => item.account === email));
    };


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 38,
        height: 20,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#03A9F4',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 17,
            height: 16,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#39393D' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const handleEditorChange = (content, editor) => {
        setDataArticle({ ...dataArticle, body: content })
    };

    const handleClickOpen = () => {
        navigate('/medias')
    };

    useEffect(() => {

        AdminService.allAdmin().then((res) => {
            //find my user by email and get id
            findUserByEmail(res.data, getEmailFromLocalStorage);
        }
        )
        //get info create article

        instance.get("articles/info-create")
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.tags && response.data.themes) {
                    setTagCreate(response.data.tags);
                    setInfoCreate({
                        authors: response.data.authors,
                        city: response.data.city,
                        tags: response.data.tags,
                        media: response.data.media,
                        themes: response.data.themes,
                    });


                } else {
                    setError('Erreur récupération info auteur, theme et tag')
                }
            })
            .catch(() => {
                setError('Erreur récupération info auteur, theme et tag')
            })
        setLoading(true);

        podcastService.getAllPodcasts()
            .then((res) => {
                if (res.status === 200) {
                    setPodcasts(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        articlesService.getArticle(id)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setArticle(res.data);
                    setBriefs(res.data?.briefs ? res.data?.briefs : []);
                    setTagsId(res.data.tags.length > 0 ? res.data.tags.map((tag) => tag.id) : []);
                    setValue(res.data.tags.length > 0 ? res.data.tags.map((tag) => tag) : []);
                    setListVideoMediaInArticle(
                        res.data.medias.filter((media) => media.type === "video")

                    );


                    setDataArticle({
                        id: res.data.id,
                        publication_date: verifyIfDateIsString(res.data.publication_date),
                        body: res.data.body,
                        podcast_id: res.data?.podcast_id ?? "",
                        is_validate: res.data.is_validate,
                        is_active: res.data.is_active,
                        in_spotlight: res.data.in_spotlight,
                        special_edition: res.data.special_edition,
                        title: res.data.title,
                        description: res.data.description,
                        author_id: res.data.authors && res.data.authors.length > 0 ? [res.data.authors[0].id] : [],
                        city_id: [res.data.city?.id],
                        theme_id: res.data.themes.length > 0 ? res.data.themes.map((theme) => theme.id) : [],
                        tag_id: res.data.tags.length > 0 ? res.data.tags.map((tag) => tag.id) : [],
                        media_id: res.data.medias.length > 0 ? res.data.medias.map((media) => media.id) : [],
                        briefs: res.data?.briefs ? res.data?.briefs : [],
                        numberOfViews: res.data.numberOfViews,
                        time_to_read: res.data?.time_to_read,
                        validate_date: res.data.validate_date,
                        mediaItems: res.data.medias.length > 0 ? res.data.medias : [],

                    });

                    setInitialValue(res.data.body);

                }
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
            })
            ;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isValidated = (isValidated, validateDate, is_active) => {
        if (isValidated === false && is_active === false) {
            return "Brouillon"
        } else if (is_active === true && isValidated === true) {
            return "Publié"
        } else if (isValidated === true && validateDate === null && is_active === false) {
            return "A valider"
        }
        else {
            return "Desactive"
        }
    }
    const [open, setOpen] = useState(false);

    const openMediaPicker = () => {
        setOpen(true);
    };

    const closeMediaPicker = () => {
        setOpen(false);

    };
    const handleOpenCroppImage = () => {
        setOpenCroppImage(true)
    };
    const handleCloseCroppImage = ({ openMediapicker = false }) => {
        if (openMediapicker) {
            setOpenCroppImage(false)
            openMediaPicker();
        } else {
            setOpenCroppImage(false)
        }
    };

    const handleChangeGeneric = (event, propertyName) => {
        const newValue = event.target.value;
        setDataArticle({ ...dataArticle, [propertyName]: newValue });
    };
    const handleChangeSwitch = (event, propertyName) => {
        const newValue = event.target.checked;


        if (propertyName === "special_edition") {
            setDataArticle({ ...dataArticle, briefs: [], [propertyName]: newValue });
            setBriefs([]);
        } else {
            setDataArticle({ ...dataArticle, [propertyName]: newValue });
        }
    };

    const handleDeleteArticle = () => {
        articlesService.deleteArticle(id)
            .then((res) => {
                if (res.status === 200) {
                    navigate('/articles')
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const validateForm = () => {
        let isValid = true;
        const newFormErrors = {};
        if (dataArticle.title === "") {
            newFormErrors.title = "Le titre est requis.";
            isValid = false;
        }

        if (dataArticle.description === "") {
            newFormErrors.description = "La description est requise.";
            isValid = false;
        }

        if (dataArticle.body === "") {
            newFormErrors.body = "Le contenu de l'article est requis.";
            isValid = false;
        }

        if (dataArticle.city_id === "") {
            newFormErrors.city_id = "La ville est requise.";
            isValid = false;
        }

        if (dataArticle.author_id.length === 0) {
            newFormErrors.author_id = "L'auteur est requis.";
            isValid = false;
        }

        if (dataArticle.theme_id.length === 0) {
            newFormErrors.theme_id = "Le thème est requis.";
            isValid = false;
        }

        if (dataArticle.tag_id.length === 0) {
            newFormErrors.tag_id = "Le tag est requis.";
            isValid = false;
        }

        if (dataArticle.media_id.length === 0) {
            newFormErrors.media_id = "Le media est requis.";
            isValid = false;
        }

        if (dataArticle.time_to_read <= 0) {
            newFormErrors.time_to_read = "Le temps de lecture doit être supérieur à zéro.";
            isValid = false;
        }

        setFormErrors(newFormErrors);
        // setError(!isValid);
        return isValid;
    };


    const handleSaveArticleToValidate = ({ validate = false, toValidate = false }) => {
        let isValidate = false;
        let isActive = false;
        if (validate) {
            isValidate = true;
            isActive = true;
        }
        if (toValidate) {
            isValidate = true;
            isActive = false;
        }

        const validForm = validateForm();
        if (validForm) {
            articlesService.updateArticle({
                id: dataArticle.id,
                is_validate: isValidate,
                is_active: isActive,
            })
                .then((res) => {
                    if (res.status === 200) {
                        handleOpenModalSuccess();
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }


    const handleSaveArticle = (saveAndClose = false) => {
        const validForm = validateForm();
        if (validForm) {
            articlesService.updateArticle(
                {
                    ...dataArticle,
                    tag_id: value.map((tag) => tag.id),
                    publication_date: typeof dataArticle.publication_date === "string"
                        ? dayjs(dataArticle.publication_date).unix() * 1000
                        : dataArticle.publication_date,
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        handleOpenModalSuccess();
                        //timer to close 
                        setTimeout(() => {

                            navigate("/articles")

                        }, 1500);
                    }

                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }
    const handlePiblishArticle = () => {
        const validForm = validateForm();
        if (validForm) {
            articlesService.updateArticle({
                ...dataArticle,
                tag_id: value.map((tag) => tag.id),
                id: dataArticle.id,
                is_validate: true,
                is_active: true
            })
                .then((res) => {
                    if (res.status === 200) {
                        navigate('/articles')
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }
    const handleUnpublishArtivle = () => {
        const validForm = validateForm();
        if (validForm) {
            articlesService.updateArticle({
                id: dataArticle.id,
                is_validate: true,
                is_active: false
            })
                .then((res) => {
                    if (res.status === 200) {
                        navigate('/articles')
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }
    const handleSelectTags = (newValue) => {
        let arrayId = [];
        if (newValue && Array.isArray(newValue) && newValue.length > 0) {
            for (const newValueElement of newValue) {
                if (newValueElement) {
                    arrayId.push(newValueElement)
                }
            }
            setTagsId(arrayId)
            setDataArticle({ ...dataArticle, tag_id: arrayId });
        } else {
            setTagsId([]);
            setDataArticle({ ...dataArticle, tag_id: [] });
        }
    }
    const loadData = () => {
        instance.get("articles/info-create")
            .then((res) => {
                if (res && res.status === 200 && res.data && res.data.tags && res.data.themes) {
                    setTagCreate(res.data.tags);

                } else {
                    setError('Erreur récupération info auteur, theme et tag')
                }
            })
            .catch(() => {
                setError('Erreur récupération info auteur, theme et tag')
            })
    }

    //publication sans validations  

    const disableButton = () => {
        if (userInfo?.is_proofreader === true) {
            return false
        } else {
            if (dataArticle.validate_date) {
                return false
            } else {
                return true
            }
        }

    }
    const texteButtonPublish = () => {
        if (userInfo?.is_proofreader === true) {
            return "Publier"
        } else {
            return disableButton() ? "En attente de validation " : "Publier"
        }
    }

    const handleOpenConfirmPublication = () => {
        //check if dataArticles all its not empty
        const isValid = validateForm();

        if (
            isValid
        ) {
            setError(false)
            setPublicationFailed(false);
            setOpenConfirmPublication(true);
        } else {

            setPublicationFailed(true);
            setError(true)
        }

    }
    // Utilise l'ID pour afficher les détails de l'article
    const breadcrumbArray = [
        { name: "Articles", to: "/articles" },
        { name: "Edition article", to: `/articles/${id}` },
    ];
    return (
        <Menu category="Éditer article" breadCrumb={breadcrumbArray} link="/articles/create" buttonText="Créer un article">

            {loading ? (
                <Container className="d-flex justify-content-center">
                    <CircularProgress className="mx-5" color="inherit" />
                </Container>
            ) : (
                error ? (
                    <h5 className={"mx-5"} style={{ fontWeight: "bold", color: 'red' }}>Une erreur est survenue</h5>
                ) : (
                    <Grid container spacing={5} className={isSmallScreen ? "m-0 pl-3" : "m-4"} sx={{ display: isSmallScreen ? "block" : "flex", width: "100%" }}>
                        <Grid sx={{ flexDirection: "column" }} container xs={isSmallScreen ? 7.9 : 7.8}>
                            <TextField
                                className={"mb-3"}
                                id="title"
                                label="Titre"
                                variant="outlined"
                                error={!!formErrors.title}
                                helperText={formErrors.title}
                                value={dataArticle.title}
                                onChange={(e) => {
                                    setDataArticle({ ...dataArticle, title: e.target.value })
                                }
                                }
                            />
                            <TextField
                                className={"mb-3"}
                                id="description"
                                label="Texte descriptif / Chapeau"
                                multiline
                                value={dataArticle.description}
                                error={!!formErrors.description}
                                helperText={formErrors.description}
                                onChange={(e) => {
                                    setDataArticle({ ...dataArticle, description: e.target.value })
                                }
                                }
                                rows={4}
                            />
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <p>Media</p>
                                </div>
                                <Button startIcon={<CollectionsIcon />} variant="outlined" onClick={handleClickOpen}>
                                    Bibliothèque

                                </Button>
                            </Box>
                            {!!formErrors.body && <span style={{ color: "#d32f2f" }}>{formErrors.body}</span>}
                            <Editor
                                tinymceScriptSrc="./tinymce/tinymce.min.js"
                                initialValue={initialValue}
                                onEditorChange={handleEditorChange}
                                // onInit={(evt, editor) => (editorRef.current = editor)}
                                init={{
                                    font_formats:
                                        "Raleway=raleway;",
                                    height: 500,
                                    menubar: true,
                                    language: "fr_FR",
                                    promotion: false,
                                    plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "preview",
                                        "help",
                                        "wordcount",
                                    ],
                                    file_picker_callback: (cb, value, meta) => {
                                        const input = document.createElement('input');
                                        input.setAttribute('type', 'file');
                                        input.setAttribute('accept', 'image/*');
                                        input.addEventListener('change', (e) => {
                                            const file = e.target.files[0];
                                            const maxSizeAfterCompression = 5 * 1024 * 1024; // 5 MB en bytes
                                            compressImageBlob(file).then((file) => {

                                                if (file.size > maxSizeAfterCompression) {
                                                    toast.error("Votre image est trop lourde meme après compression", {
                                                        position: "top-right",
                                                        theme: "dark",
                                                    });
                                                    return;
                                                }

                                                mediaService.uploadMedia(file)
                                                    .then((response) => {
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const id = 'blobid' + (new Date()).getTime();
                                                            const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                                            const base64 = reader.result.split(',')[1];
                                                            const blobInfo = blobCache.create(id, file, base64);
                                                            blobCache.add(blobInfo);
                                                            cb(IMAGE_URL_WYSIWYG + response.data.url, { title: file.name });
                                                        });
                                                        reader.readAsDataURL(file);
                                                    });
                                            }
                                            );
                                        });
                                        input.click();
                                    },
                                    toolbar:
                                        "undo redo | bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags",

                                    content_style: "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap'); body { font-family: Raleway; font-variant-numeric: lining-nums; }; ",
                                }}
                            />
                            {dataArticle.special_edition && (
                                <BriefForm
                                    briefs={briefs}
                                    setBriefs={setBriefs}
                                    setDataArticle={setDataArticle}
                                    dataArticle={dataArticle}
                                    handleChangeSwitch={handleChangeSwitch} />
                            )}
                        </Grid>
                        <Grid container xs={8.8} md={4}>
                            <div style={
                                {
                                    marginLeft: isSmallScreen ? "" : "25px",
                                    width: "88%",
                                }
                            } >
                                <CustomInlineComponent
                                    textLeft={"Statut"}
                                    textRight={isValidated(article.is_validate, article.validate_date, article.is_active)}
                                />
                                <CustomInlineComponent
                                    textLeft={"Nombre de vues"}
                                    textRight={dataArticle.numberOfViews}

                                />
                                <AddVideoButton handleOpenVideo={handleOpenModalYoutube} text={checkVideoInArticle ? "Modifier la video" : "Ajouter une video"} />
                                {!!formErrors.media_id && <span style={{ color: "#d32f2f" }}>{formErrors.media_id}</span>}
                                <CoverImageSelector
                                    dataArticle={dataArticle}
                                    setDataArticle={setDataArticle}
                                    infoCreate={infoCreate}
                                    setInfoCreate={setInfoCreate}
                                    openMediaPicker={openMediaPicker}
                                    setOpen={setOpen}
                                    handleOpenCroppImage={handleOpenCroppImage}

                                />
                                <div className='mt-10'>
                                    <Paper variant="outlined" style={{
                                        boxShadow: "none",
                                        padding: '8px',
                                        paddingLeft: '15px',
                                        height: '21rem',
                                        overflow: "auto",
                                        overflowY: "scroll",

                                    }}>
                                        <Box sx={{ marginTop: 1 }}>
                                            <FormGroup>

                                                <FormControlLabel setDataArticle={setDataArticle} control={<IOSSwitch checked={dataArticle.special_edition} onChange={((e) => handleChangeSwitch(e, "special_edition"))} sx={{ marginRight: 5, marginLeft: 1 }} />} label="Edition spéciale" />
                                                <FormControlLabel control={<IOSSwitch checked={dataArticle.in_spotlight} onChange={((e) => handleChangeSwitch(e, "in_spotlight"))} sx={{ marginRight: 5, marginLeft: 1 }} />} label="A la Une" />
                                                <NumberInput label="Temps de lecture" value={dataArticle.time_to_read} onChange={setDataArticle} dataArticle={dataArticle} />
                                                {!!formErrors.time_to_read && <span style={{ color: "#d32f2f" }}>{formErrors.time_to_read}</span>}

                                                <SelectComponent
                                                    dataArticle={dataArticle}
                                                    setDataArticle={setDataArticle} propertyName={"author_id"}
                                                    isAuteur={true} options={infoCreate.authors}
                                                    label="Auteur" />
                                                {!!formErrors.author_id && <span style={{ color: "#d32f2f" }}>{formErrors.author_id}</span>}
                                                <SelectMultipleComponent
                                                    dataArticle={dataArticle}
                                                    setDataArticle={setDataArticle}
                                                    propertyName="theme_id"
                                                    options={infoCreate.themes}
                                                    label="Thème"
                                                />
                                                {!!formErrors.theme_id && <span style={{ color: "#d32f2f" }}>{formErrors.theme_id}</span>}
                                                <SelectComponent dataArticle={dataArticle} setDataArticle={setDataArticle} propertyName={"city_id"} mLeftSelect={4} options={infoCreate.city} label="Ville" />
                                                {!!formErrors.city_id && <span style={{ color: "#d32f2f" }}>{formErrors.city_id}</span>}
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 4, paddingLeft: 0, paddingBottom: 0, paddingTop: 12 }}>
                                                    <label style={{ minWidth: "70px", fontWeight: "bold" }} htmlFor="number-input-height">Tags</label>
                                                    <div style={{ width: isSmallScreen ? "180px" : `${caculateWidth}px`, maxWidth: '100%' }}>
                                                        <FreeSoloCreateOptionDialog
                                                            listTag={tagsCreate}
                                                            getInputValue={handleSelectTags}
                                                            reloadPage={loadData}
                                                            value={value}
                                                            setValue={setValue}
                                                            setDataArticle={setDataArticle}
                                                            setTagCreate={setTagCreate}
                                                            dataArticle={dataArticle}
                                                        />
                                                    </div>
                                                </div>
                                                {!!formErrors.tag_id && <span style={{ color: "#d32f2f" }}>{formErrors.tag_id}</span>}

                                            </FormGroup>
                                        </Box>
                                    </Paper>
                                </div>
                                <Paper variant="outlined" style={{
                                    boxShadow: "none",
                                    padding: '8px',
                                    paddingLeft: '15px',
                                    // textAlign: 'center',
                                    height: '6rem',
                                    overflow: "hidden",
                                    width: "100%",

                                    marginTop: "7px"
                                }}>
                                    <InputLabel sx={
                                        {
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            marginBottom: "6px"

                                        }
                                    } >
                                        Lié un podcast à l’article
                                    </InputLabel>
                                    <PodcastSelect
                                        podcasts={podcasts}
                                        selectedPodcastId={dataArticle.podcast_id}
                                        handleChangeGeneric={handleChangeGeneric}
                                    />
                                </Paper>
                                <Paper variant="outlined" style={{
                                    boxShadow: "none",
                                    padding: '8px',
                                    paddingLeft: '15px',
                                    height: isSmallScreen ? "17rem" : '12.5rem',
                                    overflow: "hidden",
                                    marginBottom: isSmallScreen ? "25px" : "5px",
                                    marginTop: "7px"
                                }}>
                                    <DatePicker
                                        setDataArticle={setDataArticle}

                                        dataArticle={dataArticle}
                                    />

                                    <div className='mt-3 justify-between'>

                                        <div className='flex justify-between'>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        marginLeft: "5px",
                                                        marginBottom: "10px",
                                                        backgroundColor: "#212529",
                                                        color: "white",
                                                        paddingBottom: "6px",
                                                    }}
                                                    onClick={handleSaveArticle}
                                                >

                                                    <span className="normal-case">Enregistrer</span>

                                                </Button>
                                            </div>

                                            <div>
                                                {/* agregar tipo de rol  */}
                                                {dataArticle.is_validate === false && dataArticle.is_active === false && userInfo?.is_proofreader === false && (
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"

                                                            sx={{
                                                                marginLeft: "5px",
                                                                marginRight: "8px",
                                                                marginBottom: "10px",
                                                                color: "white",
                                                                paddingBottom: "6px",
                                                                paddingRight: "22px",
                                                                paddingLeft: "22px"
                                                            }}
                                                            onClick={() => handleSaveArticleToValidate({ toValidate: true })}
                                                        >

                                                            <span className="normal-case">A Valider</span>

                                                        </Button>
                                                    </>
                                                )
                                                }
                                                {/* force Publier */}

                                                {dataArticle.is_validate === true && dataArticle.is_active === false && dataArticle.validate_date === null && userInfo?.is_proofreader === true && (
                                                    <Button
                                                        variant="contained"
                                                        color='secondary'
                                                        sx={{
                                                            marginLeft: "5px",
                                                            marginBottom: "10px",
                                                            color: "white",
                                                            paddingBottom: "6px",
                                                            paddingRight: "26px",
                                                            paddingLeft: "29px"
                                                        }}
                                                        onClick={() => handleSaveArticleToValidate({ validate: true })}
                                                    >

                                                        <span className="normal-case">Valider</span>

                                                    </Button>
                                                )
                                                }
                                                {userInfo?.is_proofreader === true && dataArticle.is_validate === false && dataArticle.is_active === false && (

                                                    <Button
                                                        variant="contained"
                                                        color='success'
                                                        sx={{
                                                            marginLeft: "12px",
                                                            marginBottom: "10px",
                                                            color: "white",
                                                            paddingBottom: "6px",
                                                        }}
                                                        onClick={handlePiblishArticle}
                                                    >

                                                        <span className="normal-case">Publier</span> <PublishOutlined />

                                                    </Button>
                                                )}

                                                {/* tourver un solution pursavoir si un personne sans le rol proreader */}
                                                {dataArticle.is_validate && (
                                                    dataArticle.is_validate === true && dataArticle.is_active === false ? (
                                                        <Button
                                                            variant="contained"
                                                            color='success'
                                                            sx={{
                                                                marginLeft: "5px",
                                                                marginBottom: "10px",
                                                                color: "white",
                                                                paddingBottom: "6px",
                                                            }}
                                                            disabled={disableButton()}
                                                            onClick={handlePiblishArticle}
                                                        >

                                                            <span className="normal-case">{texteButtonPublish()}</span> <PublishOutlined />

                                                        </Button>

                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            color='secondary'
                                                            sx={{
                                                                marginLeft: "5px",
                                                                marginBottom: "10px",
                                                                color: "white",
                                                                paddingBottom: "6px",
                                                            }}
                                                            onClick={handleUnpublishArtivle}
                                                        >

                                                            <span className="normal-case">Retirer</span>

                                                        </Button>

                                                    )
                                                )}
                                            </div>

                                        </div>

                                        <div className='flex justify-content-end mt-2' >
                                            {dataArticle.validate_date === null && dataArticle.is_active === false && userInfo?.is_proofreader === false && (
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        marginLeft: "5px",
                                                        marginBottom: "10px",
                                                        backgroundColor: "#ee8b00",
                                                        color: "white",
                                                        paddingBottom: "6px",
                                                        marginRight: "40px"
                                                    }}
                                                    onClick={handleOpenConfirmPublication}
                                                >

                                                    <span className="normal-case">Publier sans Valider</span> <PublishOutlined />

                                                </Button>
                                            )}

                                            <Button
                                                variant="contained"
                                                color='primary'
                                                sx={{
                                                    marginLeft: "5px",
                                                    marginBottom: "10px",
                                                    marginRight: "8px",
                                                    color: "white",
                                                    paddingBottom: "6px",
                                                    paddingRight: "26px",
                                                    paddingLeft: "26px"
                                                }}
                                                onClick={() => setCancelConfirmPublication(true)}
                                            >

                                                <span className="normal-case">Annuler</span>

                                            </Button>

                                            <Button
                                                variant="contained"
                                                color='error'
                                                sx={{
                                                    marginLeft: "5px",
                                                    marginBottom: "10px",

                                                    color: "white",
                                                    paddingBottom: "6px",
                                                }}
                                                onClick={handleOpenModal}
                                            >

                                                <span className="normal-case">Supprimer</span>

                                            </Button>
                                        </div>


                                    </div>

                                </Paper>
                                <AlertDialog
                                    open={openConfirmPublication}
                                    handleClose={handleCloseConfirmPublication}
                                    handleAgree={() => handlePiblishArticle()}
                                    title={"Attention"}
                                    label={"Etes vous sur de vouloir publier sans validation ? "}
                                    buttonAccept={"Publier"}
                                    buttonCancel={"Annuler"}
                                />
                                {open && (
                                    <ModalMedia
                                        open={open}
                                        handleClose={closeMediaPicker}
                                        handleSave={closeMediaPicker}
                                        setDataArticle={setDataArticle}
                                        dataArticle={dataArticle}
                                        infoCreate={infoCreate}
                                        setInfoCreate={setInfoCreate}

                                    />
                                )}
                                {openCroppImage && (
                                    <AddImageModal
                                        open={openCroppImage}
                                        handleCloseCroppImage={handleCloseCroppImage}
                                        setInfoCreate={setInfoCreate}
                                        infoCreate={infoCreate}
                                        dataArticle={dataArticle}
                                        setDataArticle={setDataArticle}
                                    />
                                )
                                }
                                <ModalAddVideoYoutubeArticle
                                    open={openModalYoutube}
                                    onClose={handleCloseModalYoutube}
                                    onConfirm={handleCloseModalYoutube}
                                    dataArticle={dataArticle}
                                    setDataArticle={setDataArticle}
                                    listVideoMediaInArticle={listVideoMediaInArticle}
                                    setListVideoMediaInArticle={setListVideoMediaInArticle}
                                />
                            </div>
                        </Grid>
                    </Grid>
                )
            )}
            <ConfirmDeleteModal
                open={openModal}
                onClose={handleCloseModal}
                onConfirm={handleDeleteArticle}
            />
            <CustomModalSuccess
                open={openModalSuccess}
                onClose={handleCloseModalSuccess}
                title={"Succès"}
                description={"L'article a été enregistré avec succès."}

            />
            {/* Cancel confirm */}
            <AlertDialog
                open={CancelConfirmPublication}
                handleClose={() => setCancelConfirmPublication(false)}
                handleAgree={() => navigate("/articles")}
                title={"Attention"}
                label={"Voulez vous vraiment annuler ? Toutes vos modifications seront perdues"}
                buttonAccept={"Oui"}
                buttonCancel={"Non"}
            />


        </Menu>
    );
};

export default ArticleDetail;