import instance from "../utils/axios";

const register = (username, email, password) => {
  return instance.post("signup", {
    username,
    email,
    password,
  });

  // return axios.post(API_URL + "signup", {
  //     username,
  //     email,
  //     password,
  // });
};

const login = (values) => {
  return instance.post(
    "/back_office/auth/login",
    {
      account: values?.account,
      password: values?.password,
    },
    { withCredentials: true }
  );
};



const getCurrentUser = async () => {
  return instance
    .get("admin/info")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const AuthService = {
  register,
  login,
  getCurrentUser,
};

export default AuthService;
