import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const PodcastSelect = ({ podcasts, selectedPodcastId, handleChangeGeneric }) => {

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="podcast-select-label">Sélectionnez un podcast</InputLabel>
                <Select
                    labelId="podcast-select-label"
                    id="podcast-select"
                    value={selectedPodcastId}
                    label="Sélectionnez un podcast"
                    onChange={(e) => handleChangeGeneric(e, "podcast_id")}
                >
                    <MenuItem value="">
                        <em>Sélectionnez un podcast...</em>
                    </MenuItem>
                    {podcasts?.map((podcast) => (
                        <MenuItem key={podcast.id} value={podcast.id}>
                            {podcast.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default PodcastSelect;
