import React, { useState, useEffect } from 'react';
import { Button, Modal, TextField, Stack, Select, MenuItem, FormControl, InputLabel, Box, Typography } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import tvService from '../../../services/tv.services';


const CreateShowModalTv = ({ isOpen, onClose, openSuccessModal, closeSuccessModal, setRadioProgramsFromServer, modifiedData }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        days: '',
        start: '',
        end: '',
    });
    useEffect(() => {
        setFormData({
            title: '',
            description: '',
            days: '',
            start: '',
            end: '',
        });
        setFormErrors({
            title: '',
            description: '',
            days: '',
            start: '',
            end: '',
        });
    }
        , [isOpen]);


    const [formErrors, setFormErrors] = useState({
        title: '',
        description: '',
        days: '',
        start: '',
        end: '',
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        // Clear the error for the current field when there's a change
        setFormErrors({
            ...formErrors,
            [e.target.name]: '',
        });
    };

    const validateForm = () => {
        let isValid = true;
        const newFormErrors = {};

        // Validate each field
        if (!formData.title) {
            newFormErrors.title = "Le titre est requis.";
            isValid = false;
        }

        // if (!formData.description) {
        //     newFormErrors.description = "La description est requise.";
        //     isValid = false;
        // }

        if (!formData.days) {
            newFormErrors.days = "Le jour est requis.";
            isValid = false;
        }
        if (!formData.start) {
            newFormErrors.start = "L'heure de début est requise.";
            isValid = false;
        } else if (!formData.end) {
            newFormErrors.end = "L'heure de fin est requise.";
            isValid = false;
        } else {
            const startTime = new Date(`2024-01-01 ${formData.start}`);
            const endTime = new Date(`2024-01-01 ${formData.end}`);

            if (startTime >= endTime) {
                newFormErrors.start = "L'heure de début doit être antérieure à l'heure de fin.";
                isValid = false;
            }
        }

        setFormErrors(newFormErrors);
        return isValid;
    };

    const handleCreate = () => {
        if (validateForm()) {
            setIsLoading(true);
            tvService.createTvProgram([{
                title: formData.title,
                description: formData.description === "" ? " " : formData.description,
                days: formData.days,
                date_start: formData.start,
                date_end: formData.end
            }]).then((response) => {
                openSuccessModal();
                tvService.getAllTvProgram().then((res) => {
                    setRadioProgramsFromServer(modifiedData(res.data))
                })
                setTimeout(() => {
                    setIsLoading(false);
                    closeSuccessModal();
                }, 2000);
            }
            );

            onClose();
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: 6,
                    width: 600,
                    height: 700,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center',
                }}
            >
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, boxShadow: 24, p: 4 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                        Créer un programme
                    </Typography>
                    <Stack spacing={2}>
                        <TextField label="Titre" name="title" value={formData.title} onChange={handleInputChange} fullWidth error={!!formErrors.title} helperText={formErrors.title} />
                        <TextField label="Description" name="description" value={formData.description} onChange={handleInputChange} fullWidth error={!!formErrors.description} helperText={formErrors.description} />
                        <FormControl fullWidth error={!!formErrors.days}>
                            <InputLabel>Jour</InputLabel>
                            <Select name="days" value={formData.days} onChange={handleInputChange}>
                                <MenuItem value="monday">Lundi</MenuItem>
                                <MenuItem value="tuesday">Mardi</MenuItem>
                                <MenuItem value="wednesday">Mercredi</MenuItem>
                                <MenuItem value="thursday">Jeudi</MenuItem>
                                <MenuItem value="friday">Vendredi</MenuItem>
                                <MenuItem value="saturday">Samedi</MenuItem>
                                <MenuItem value="sunday">Dimanche</MenuItem>
                            </Select>
                            {formErrors.days && <Typography variant="caption" color="error">{formErrors.days}</Typography>}

                        </FormControl>
                        <TextField label="Heure de début" type="time" name="start" value={formData.start} onChange={handleInputChange} fullWidth error={!!formErrors.start} helperText={formErrors.start} />
                        <TextField label="Heure de fin" type="time" name="end" value={formData.end} onChange={handleInputChange} fullWidth error={!!formErrors.end} helperText={formErrors.end} />
                        <Button disabled={isLoading} variant="contained" onClick={handleCreate}>{isLoading ? <CircularProgress className="mx-5" color="inherit" /> : "Créer"}</Button>
                    </Stack>

                </div>
            </Box>
        </Modal>
    );
};

export default CreateShowModalTv;
