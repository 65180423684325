import React, { useEffect, useState } from 'react'
import Menu from "../../components/layout/Menu";
import CustomListPodcastTV from "../../components/ListPodcastTV/ListPodCastAndTv";
import podcastService from "../../services/podcast.services";
import {toast} from "react-toastify";
import mediaService from "../../services/media.service";
import Loader from "../../components/Loader";
import {IMAGE_URL} from "../../constants/url";

const PodcastList = () => {

    const [podcastShow, setPodcastShow] = useState([]);
    const [loading, setLoading] = useState(false);
    const [infoCreate, setInfoCreate] = useState({
        media: [],
    });

    const data = podcastShow.map((item) => {
        return {
            id: item.id,
            name: item.name,
            link: `/podcast/${item.id}`,
            image: `${IMAGE_URL}${item.media?.url}?w=248&fit=crop&auto=format`,
            imageId: item.media?.id,
        };
    }
    );

    const breadcrumbArray = [
        { name: "Tableau de bord", to: "/dashboard" },
        { name: "Podcast", to: "/podcast" },
    ];

    const editPodcast = (body) => {
        setLoading(true);
        console.log('edit podcast on list', body)
        podcastService.editPodcastShow(body)
            .then((response) => {
                setLoading(false);
                if (response.status === 200 && response.data && response.data.length > 0) {
                    setPodcastShow(response.data);
                    toast.success("Podcast modifié avec succès", {
                        position: "top-right",
                        theme: "dark",
                    })
                } else {
                    toast.error("Erreur lors de la modification du podcast", {
                        position: "top-right",
                        theme: "dark",
                    })
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("Erreur lors de la modification du podcast", {
                    position: "top-right",
                    theme: "dark",
                })
            })
    }

    useEffect(() => {
        setLoading(true);
        podcastService.getPodcastShows()
            .then((response) => {
                setLoading(false);
                setPodcastShow(response.data);
            })
            .catch((error) => {
                setLoading(false);
                toast.error('Erreur lors de la récupération des données', {
                    position: "top-right",
                    theme: "dark",
                })
            })

        mediaService.onlyImage()
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setInfoCreate({
                        ...infoCreate,
                        media: response.data,
                    });
                } else {
                    toast.error("Error lors du chargement des images", {
                        position: "top-right",
                        theme: "dark"
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error("Error lors du chargement des images", {
                    position: "top-right",
                    theme: "dark"
                });
            })
    }, []);


    return (
        <Menu category="Podcast" breadCrumb={breadcrumbArray} >

            {loading ? (
                <Loader />
                ) : (
                <CustomListPodcastTV data={data} isPodcast={true} infoCreate={infoCreate} setInfoCreate={setInfoCreate} editPodcast={editPodcast} setLoading={setLoading} />
            )}

        </Menu>


    )
}

export default PodcastList
