import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

const AudioUploadInput = ({ onFileChange, setAudioDuration, remplaceAudio, setAudioUrl, audioUrl }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        // Vérifiez si le fichier a un type MIME valide
        if (file && isValidAudioFile(file)) {
            setSelectedFile(file);
            onFileChange(file);
            getAudioDuration(file);
            if (remplaceAudio) {
                setAudioUrl(null);
            }
        } else {
            // Restablecer el estado si el archivo no es válido
            setSelectedFile(null);
            onFileChange(null);
            if (remplaceAudio) {
                setAudioUrl(audioUrl)
            }
            alert('Veuillez sélectionner un fichier audio valide.');
        }
    };

    const isValidAudioFile = (file) => {
        // Types MIME de fichiers audio autorisés
        const allowedAudioTypes = ['audio/mpeg', 'audio/mp3', 'audio/mp4'];

        // Tipos MIME de archivos de video
        const videoTypes = ['video/mp4', 'video/quicktime', /* ... autres types de vidéo ... */];

        // Récupère le type MIME du fichier sélectionné
        const fileType = file.type;

        // Vérifiez si le type MIME est dans la liste des fichiers audio autorisés et non dans la liste des fichiers vidéo
        return allowedAudioTypes.includes(fileType) && !videoTypes.includes(fileType);
    };
    const getAudioDuration = (file) => {
        const audioElement = new Audio();
        audioElement.src = URL.createObjectURL(file);

        audioElement.addEventListener('loadedmetadata', () => {
            setAudioDuration(audioElement.duration);
        });
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: '#16324D', marginRight: '5px' }}>
                    Audio podcast :
                </Typography>

                <Button
                    sx={{ borderRadius: 1, boxShadow: 0, textTransform: 'none' }}
                    color="secondary"
                    size="medium"
                    variant="contained"
                    component="label"
                    htmlFor="audioUpload"
                >
                    <input
                        type="file"
                        id="audioUpload"
                        accept="audio/*"
                        onChange={handleFileChange}
                        className="sr-only"
                    />
                    {remplaceAudio ? "Remplacer l'audio" : "Ajouter un audio"}

                </Button>

            </Box>
            <span className="ml-3">
                {selectedFile && (
                    <>
                        <span className="font-bold">Fichier sélectionné : </span>
                        {selectedFile.name}
                    </>
                )}
            </span>
        </>
    );
};

export default AudioUploadInput;
